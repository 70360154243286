import axios from 'axios';
import { REQUEST } from './documentation';
import { ldpCredentialsRequest } from './serverEngine';

export type TSetResource = {title: string, content: string};
export type TEditResource = {resource: string, title: string, content: string};

export type TPutResourceOutput = {
    success: boolean,
    error: string
}

export type TPostResourceOutput = {
    success: boolean,
    error: string
}

export type TPostResourceInput = {
    newName?: string,
    data?: string
}

export type TResourceListOuput = {
    name: string[],
    ext: string[]
}

export type TremoveGeneralResourceOutput = {
    success: boolean,
    error: string
}

export type TPutResourceInput = {data: string};

export async function fetchGeneralResourceList(): Promise<string[]> {
    const files = ((await axios.get(`/api/resources/general`, {
        headers: {
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': '0'
        }
    })).data) as TResourceListOuput;
    return files.name;
}

export async function fetchGeneralResource(resourceName: string): Promise<string> {
    const file = await axios.get(`/api/resources/general/${resourceName}`, {
        headers: {
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': '0'
        }
    });
    return file.data as string;
}

export async function saveGeneralResource(resourceName: string, payload: TPutResourceInput): Promise<TPutResourceOutput> {
    return (await ldpCredentialsRequest(`/api/resources/general/${resourceName}`, REQUEST.PUT, payload)) as TPutResourceOutput;
}

export async function editGeneralResource(resourceName: string, payload: TPostResourceInput): Promise<TPostResourceOutput> {
    return (await ldpCredentialsRequest(`/api/resources/general/${resourceName}`, REQUEST.POST, payload)) as TPostResourceOutput;
}

export async function removeGeneralResource(resourceName: string): Promise<TremoveGeneralResourceOutput> {
    return (await ldpCredentialsRequest(`/api/resources/general/${resourceName}`, REQUEST.DELETE)) as TremoveGeneralResourceOutput;
}

export async function fetchLeagueDescription(): Promise<{short: string, long: string}> {
    const long = axios.get(`/api/resources/home/league_descr_long`);
    const short = axios.get(`/api/resources/home/league_descr_short`);
    const [longResp, shortResp] = await Promise.all([long, short]);
    return {long: longResp.data as string, short: shortResp.data as string};
}

export async function fetchJoinTeamMessage(): Promise<string> {
    const resp = await axios.get(`/api/resources/home/league_join`);
    return resp.data as string;
}

export async function editLeagueDescrLong(payload: TPutResourceInput): Promise<TPutResourceOutput> {
    return (await ldpCredentialsRequest(`/api/resources/home/league_descr_long`, REQUEST.PUT, payload)) as TPutResourceOutput;
}

export async function editLeagueDescrShort(payload: TPutResourceInput): Promise<TPutResourceOutput> {
    return (await ldpCredentialsRequest(`/api/resources/home/league_descr_short`, REQUEST.PUT, payload)) as TPutResourceOutput;
}

export async function fetchHomeImage1() {
    const resp = await axios.get(`/images/home/home1.jpg`, {responseType: 'blob'});

    return resp.data as Blob;
}

export async function fetchLogoText() {
    const resp = await axios.get(`/images/home/logo_text.png`, {responseType: 'blob'});
    return resp.data as Blob;
}

export async function fetchLogoTextNarrow() {
    const resp = await axios.get(`/images/home/logo_text_narrow.png`, {responseType: 'blob'});
    return resp.data as Blob;
}