
import { defineComponent } from 'vue';
import CheckboxFilter from './CheckboxFilter.vue';
import { store } from '@/store/store';
import { MUTATION } from '@/store/mutations';
import { decodeTeamDivision, divisionLongDisplayName } from '@/utils';

export default defineComponent({
    name: 'TheDivisionFilter',
    components: {
        CheckboxFilter
    },
    props: {
        collapsed: {
            type: Boolean,
            required: false,
            default: false
        },
        filterSetName: {
            type: String,
            required: true
        }
    },
    computed: {
        sortedCategories(): string[] {
            return store.getters.sortedCategories.map(d => {
                const {division, pool} = decodeTeamDivision(d);
                return divisionLongDisplayName(division, pool);
            });
        },
        filterValues(): string[] {
            return store.getters.filterSet(this.filterSetName).divisionsFilter;
        }
    },
    methods: {
        clearFilter() {
            store.commit(MUTATION.SET_DIVISION_FILTERS, {filterSet: this.filterSetName, divisions:[]});
            store.commit(MUTATION.SET_SELECTED_MATCH_IDX, -1);
        },
        updateFilter(divisions: string[]) {
            store.commit(MUTATION.SET_DIVISION_FILTERS, {filterSet: this.filterSetName, divisions});
            store.commit(MUTATION.SET_SELECTED_MATCH_IDX, -1);
        },
        divisionInfo(teamName: string): string {
            return store.getters.teamInfo(teamName)?.division || '';
        }
    }
});
