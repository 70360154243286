import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'Home',
        component: () => import('@/views/Home.vue')
    },
    {
        path: '/myaccount/preferences',
        name: 'Preferences',
        component: () => import('@/views/Home.vue')
    },
    {
        path: '/myaccount/login',
        name: 'Login',
        component: () => import('@/views/Home.vue')
    },
    {
        path: '/admin/:tabView',
        name: 'Admin',
        component: () => import('@/views/Admin.vue'),
        props: true
    },
    {
        path: '/admin',
        name: 'AdminDefault',
        component: () => import('@/views/Admin.vue')
    },
    {
        path: '/schedule',
        name: 'Schedule',
        component: () => import('@/views/Schedule.vue')
    },
    {
        path: '/series',
        name: 'Series',
        component: () => import('@/views/Series.vue')
    },
    {
        path: '/ranking',
        name: 'Ranking',
        component: () => import('@/views/Ranking.vue')
    },
    {
        path: '/contact-us',
        name: 'ContactUs',
        component: () => import('@/views/ContactUs.vue')
    },
    {
        path: '/sponsors',
        name: 'Sponsors',
        component: () => import('@/views/Sponsors.vue')
    },
    {
        path: '/series/:match_id',
        name: 'SeriesMatch',
        component: () => import('@/views/Series.vue')
    },
    {
        path: '/teams',
        name: 'Teams',
        component: () => import('@/views/Teams.vue')
    },
    {
        path: '/teams/:teamName',
        name: 'SpecificTeam',
        component: () => import('@/views/Team.vue')
    },
    {
        path: '/fields',
        name: 'Fields',
        component: () => import('@/views/Fields.vue')
    },
    {
        path: '/api',
        name: 'Documentation',
        component: () => import('@/views/Documentation.vue')
    },
    {
        path: '/freeagents',
        name: 'FreeAgents',
        component: () => import('@/views/FreeAgents.vue')
    },
    {
        path: '/resources/:resourceName',
        name: 'Resource',
        component: () => import('@/views/Resource.vue'),
        props: true
    },
    {
        path: '/timeslots',
        name: 'Timeslots',
        component: () => import('@/views/Timeslots.vue')
    },
    {
        path: "/:catchAll(.*)",
        name: 'Home',
        component: () => import('@/views/Home.vue')
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router;
