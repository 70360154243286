
import { defineComponent } from 'vue';
import Popup from '@/components/lib/Popup.vue';
import TeamLabel from '@/components/TeamLabel.vue';
import { store } from '@/store/store';
import { MUTATION } from '@/store/mutations';
import { TeamInterface } from '@/services/teams';
import { POPUPS, POPUP_TYPES } from '@/constants';
import { ACTION } from '@/store/actions';
import { POPUP_DIRECTION } from '@/store/state';
import CSS from 'csstype';

export default defineComponent({
    name: 'TheTeamSelectionDropdown',
    components: { Popup, TeamLabel },
    data() {
        return {
            dropdownType: POPUP_TYPES.DROP_DOWN
        }
    },
    computed: {
        teamsIFavorite(): TeamInterface[] {
            const list = store.getters.teamsIFavorite;
            list.shift();
            return list;
        },
        teamsICaptain(): TeamInterface[] {
            const list = store.getters.teamsICaptain;
            list.shift();
            return list;
        },
        teams(): TeamInterface[] {
            switch(this.id) {
                case POPUPS.TEAM_FAVORITES_DROPDOWN: return this.teamsIFavorite;
                case POPUPS.TEAM_CAPTAIN_DROPDOWN: return this.teamsICaptain;
                case POPUPS.TEAM_SELECTION_DROPDOWN: return store.state.teams;
                default: return []
            }
        },
        target(): HTMLElement | null {
            return store.getters.popupTarget(this.id);
        },
        isVisible(): boolean {
            return store.getters.isVisiblePopup(this.id);
        },
        id(): POPUPS {
            return store.getters.teamSelectionDropdownID;
        },
        dropdownPosition(): CSS.Properties {
           if (!this.target) return {} as CSS.Properties;

            const pos = this.target.getBoundingClientRect();
            return {
                top: pos.y + this.target.clientHeight + 'px',
                left: pos.x + 'px',
                width: pos.width + 'px'
            } as CSS.Properties;
        }
    },
    methods: {
        select(idx: number) {
            switch(this.id){
                case POPUPS.TEAM_FAVORITES_DROPDOWN: {
                    store.dispatch(ACTION.MAIN_FAVORITE_TEAM_SELECTED,
                        store.getters.teamsIFavorite[idx + 1]
                    );
                    break;
                }
                case POPUPS.TEAM_CAPTAIN_DROPDOWN: {
                    store.dispatch(ACTION.MAIN_CAPTAIN_TEAM_SELECTED,
                        store.getters.teamsICaptain[idx + 1]
                    );
                    break;
                }
            }
            this.close();
        },
        close() {
            store.commit(MUTATION.HIDE_POPUP, this.id);
        },
        showFavorite(target: HTMLElement) {
            store.commit(MUTATION.SHOW_POPUP_ONLY, {
                id: this.id, location: target, direction: POPUP_DIRECTION.DOWN
            });
        },
        showCaptain(target: HTMLElement) {
            store.commit(MUTATION.SHOW_POPUP_ONLY, {
                id: this.id, location: target, direction: POPUP_DIRECTION.DOWN
            });
        }
    }
});
