import axios from "axios"

export type TAppPjc = {
    app:{
        fields: {
            externalLink: {
                url: string,
                displayName?: string
            }
        },
        timeslots: {
            maxReservationRequests: number
        },
        facebookURL: string,
        instagramURL: string,
        freeagentsURL: string
    }
}

export type TAppUic = {
    app: {
        pages: {
            home: {
                joinTeamBtn: {
                    visible: boolean
                },
                signUpBtn: {
                    visible: boolean
                }
            },
            teams: {
                joinYear: {
                    visible: boolean
                }
            }
        }
    }
}

export type TGetAppCfgOutput = {
    cfg: {
        pjc: TAppPjc,
        uic: TAppUic
    }
}

export async function fetchAppConfig(): Promise<TGetAppCfgOutput> {
    const resp = await axios.get(`/config`);
    return resp.data as TGetAppCfgOutput;
}
