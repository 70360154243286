
import { defineComponent }  from 'vue';

export const enum DLG_BTN_TYPE {
    PRIMARY,
    SECONDARY,
    CLOSE
}

export declare type dialogBtnProp = {
    label: string,
    type: DLG_BTN_TYPE,
    clickCB: () => any
}

export default defineComponent({
    name: 'Dialog',
    data(){
        return {

        }
    },
    props: {
        headerTitle: {
            type: String,
            required: false,
            default: ''
        },
        showHeader: {
            type: Boolean,
            required: false,
            default: true
        }
    }
});
