import axios from 'axios';
import { USER_ROLES_VALUES } from '@/constants';
import { REQUEST } from './documentation';
import { ldpCredentialsRequest } from './serverEngine';

export interface UserInterface {
    _id: string;
    name: string;
    email: string;
    role: USER_ROLES_VALUES,
    teams: string[]
}

export type EditUserInput = Omit <UserInterface, 'name' | 'email'>;

export type AddUserInput = Omit <UserInterface, '_id'>;
export type UserOutput = {
    success: boolean;
    users: UserInterface[];
};
export type UserAddOutput = UserOutput & {
    error: string
}

export type TRequestAccountInput = {
    name: string,
    username: string;
    password: string;
    teams: string[];
    role: USER_ROLES_VALUES;
};

export type TRequestAccountOutput = {
    success: boolean,
    error?: string,
};

export async function addUser(req: AddUserInput): Promise<UserAddOutput> {
    return (await ldpCredentialsRequest(`/api/users/add`, REQUEST.POST, req)) as UserAddOutput;
}

export async function fetchAccount(req: TRequestAccountInput): Promise<TRequestAccountOutput> {
    const resp = await axios.post(`/api/users/register`, req);
    return resp.data as TRequestAccountOutput;
}

export async function approveAccountRequest(requestID: string) {
    return (await ldpCredentialsRequest(`/api/users/approverequest/${requestID}`, REQUEST.POST)) as UserOutput;
}

export async function denyAccountRequest(requestID: string) {
    return (await ldpCredentialsRequest(`/api/users/denyrequest/${requestID}`, REQUEST.POST)) as UserOutput;
}

export async function editUser(req: EditUserInput): Promise<UserOutput> {
    return (await ldpCredentialsRequest(`/api/users/edit/${req._id}`, REQUEST.PUT, req)) as UserOutput;
}

export async function removeUser(userID: string): Promise<UserOutput> {
    return (await ldpCredentialsRequest(`/api/users/remove/${userID}`, REQUEST.DELETE)) as UserOutput;
}

export async function fetchUsers(): Promise<UserInterface[]> {
    return (await ldpCredentialsRequest(`/api/users/`, REQUEST.GET)) as UserInterface[];
}

export async function fetchPendingUsers(): Promise<UserInterface[]> {
    return (await ldpCredentialsRequest(`/api/users?type=pending`, REQUEST.GET)) as UserInterface[];
}

export default {};
