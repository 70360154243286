import axios from 'axios';

export type TApiType = {
    type: string;
    required: boolean;
};

export const enum REQUEST {
    GET = 'GET',
    POST = 'POST',
    DELETE = 'DELETE',
    PUT = 'PUT'
}

export const enum BASETYPES {
    NUMBER = 'Number',
    BOOLEAN = 'Boolean',
    STRING = 'String',
    ANY = 'Any',
    ARRAY = 'Array',
    OBJECT = 'Object'
}

export const BASETYPES_ARR = [
    BASETYPES.STRING,
    BASETYPES.ANY,
    BASETYPES.NUMBER,
    BASETYPES.BOOLEAN,
    BASETYPES.ARRAY,
    BASETYPES.OBJECT
]

export interface DocumentationInterface {
    [route: string]: {
        input?: TApiType;
        output: TApiType;
        requestType: REQUEST;
        description: string;
    };
}

export async function fetchDocumentation(): Promise<DocumentationInterface> {
    const resp = await axios.get(`/api`);
    return resp.data as DocumentationInterface;
}

export default {};
