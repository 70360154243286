import { createApp } from 'vue';
import App from './App.vue';
import router from './router/router';
import { store, provisionStore } from './store/store';

import '@/styles/ldp-icons.css';
import '@/styles/fontello/css/animation.css';
import { MUTATION } from './store/mutations';
import { STATE } from './constants';
import { CustomWindow } from './utils';

createApp(App)
    .use(store)
    .use(provisionStore)
    .use(router)
    .mount('#app');

if ((process.env?.NODE_ENV ?? '') === "development") (window as any).$store = store;

// eslint-disable-next-line
(window as CustomWindow).gm_authFailure = function() {
    console.warn('gm_authFailure: Google Maps invalidKeys');
    store.commit(MUTATION.GOOGLE_MAPS_REQUEST_STATUS, {
        status: STATE.ERR, msg: 'Google Maps ne peut etre utilisé.'
    });
}
