import { store } from '@/store/store';
import axios from 'axios';
import { REQUEST } from './documentation';
import { FreeAgentInterface } from './freeagents';
import { MatchInterface } from './matches';
import { SeriesInterface } from './series';
import { ldpCredentialsRequest } from './serverEngine';
import { savePreferences, TRequestLoginOutput } from './session';
import { FilteredTimeslotData } from './timeslots';
import { UserInterface } from './users';

export type PlayerInterface = {
    _id: string,
    name: string,
    gender: string,
    number: number, // -1 if no number assigned
    team: string
}

export interface TeamInterface { // matches TeamData on the server
    _id: string;
    join: number;
    name: string;
    division: string;
    pool: string;
    color: string;
    picture?: string; // url of the team picture,
    logo?: string;
}

export type TFilteredTeamsByDivision = {
    [divisionName: string]: TeamInterface[];
}

export type TFilteredPlayersByTeam = {
    [teamName: string]: PlayerInterface[];
}

export type TAddToFavoritesOutput =  {
    success: boolean;
    error: string;
};

export type AddPlayerInput = Omit <PlayerInterface, '_id'>;
export type TPlayerOutput = {
    success: boolean;
    data: PlayerInterface[];
}

export async function fetchTeams(season?: string): Promise<TeamInterface[]> {
    let route = '/api/teams';
    if (season) {
        route += `?season=${encodeURI(season)}`;
    }
    const resp = await axios.get(route);
    return resp.data as TeamInterface[];
}

export async function fetchTeam(name: string, season?: string): Promise<TeamInterface> {
    let route = `/api/teams/${name}`;
    if (season) {
        route += `?season=${encodeURI(season)}`;
    }
    const resp = await axios.get(route);
    return resp.data as TeamInterface;
}

export async function fetchPlayers(season?: string): Promise<PlayerInterface[]> {
    let route = `/api/players`;
    if (season) {
        route += `?season=${encodeURI(season)}`;
    }
    const resp = await axios.get(route);
    return resp.data.players as PlayerInterface[];
}  

export async function addToTeams(team: TeamInterface): Promise<TRequestLoginOutput> {
    let teams = store.state.sessionInfo.perm.teams.slice();
    teams.push(team.name);
    teams = [... new Set(teams)]
    return await savePreferences({teams: teams});
}

export type TEditTeamOutput = {
    success: boolean;
    error: string;
    players?: PlayerInterface[];
    users?: UserInterface[];
    pendingUsers?: UserInterface[];
    freeAgents?: FreeAgentInterface[];
    series?: SeriesInterface[];
    schedule?: MatchInterface[];
    timeslots?: FilteredTimeslotData;
}


export type TRemoveTeamOutput = {
    success: boolean;
    error: string;
    players?: PlayerInterface[];
    users?: UserInterface[];
    pendingUsers?: UserInterface[];
    freeAgents?: FreeAgentInterface[];
    series?: SeriesInterface[];
    schedule?: MatchInterface[];
    timeslots?: FilteredTimeslotData;
}

export type TEditTeamInput = {
    team: TeamInterface,
    images: {
        logo: File | null,
        picture: File | null
    }
}

export type TAddTeamInput = {
    team: Omit<TeamInterface, '_id'>,
    images: {
        logo: File | null,
        picture: File | null
    }
}

export type TAddTeamOutput = {
    success: boolean;
    error: string;
    team?: TeamInterface;
}

export async function addTeam(payload: TAddTeamInput): Promise<TAddTeamOutput> {
    const formData = new FormData();
    if(payload.images.logo) {
        formData.append("files", payload.images.logo);
        formData.append("logo-filename", payload.images.logo.name);
    }
    if(payload.images.picture) {
        formData.append("files", payload.images.picture);
        formData.append("picture-filename", payload.images.picture.name);
    }
    (Object.keys(payload.team)).forEach(k => {
        formData.append(k, (payload.team as any)[k]);
    });
    return (await ldpCredentialsRequest(`/api/teams/add`, REQUEST.PUT, formData)) as TAddTeamOutput;
}

export async function removeTeam(id: string): Promise<TRemoveTeamOutput> {
    return (await ldpCredentialsRequest(`/api/teams/remove/${id}`, REQUEST.DELETE)) as TRemoveTeamOutput;
} 

export async function editTeam(payload: TEditTeamInput): Promise<TEditTeamOutput> {
    const formData = new FormData();
    if(payload.images.logo) {
        formData.append("files", payload.images.logo);
        formData.append("logo-filename", payload.images.logo.name);
    }
    if(payload.images.picture) {
        formData.append("files", payload.images.picture);
        formData.append("picture-filename", payload.images.picture.name);
    }
    (Object.keys(payload.team)).forEach(k => {
        formData.append(k, (payload.team as any)[k]);
    });
    return (await ldpCredentialsRequest(`/api/teams/edit/${payload.team._id}`, REQUEST.POST, formData)) as TEditTeamOutput;
} 

export async function reorderTeamsList(teams: TeamInterface[]): Promise<TRequestLoginOutput> {
    return await savePreferences({teams: teams.map(t => t.name)});
}

export async function removeFromFavorites(team: TeamInterface): Promise<TRequestLoginOutput> {
    const teams = store.state.sessionInfo.perm.teams.slice();
    const idx = teams.indexOf(team.name);
    if(idx >= 0) teams.splice(idx, 1);
    return await savePreferences({teams: teams});
}

export async function addPlayer(req: AddPlayerInput): Promise<TPlayerOutput> {
    return (await ldpCredentialsRequest(`/api/players/add`, REQUEST.POST, req)) as TPlayerOutput;
}

export async function removePlayer(playerID: string): Promise<TPlayerOutput> {
    return (await ldpCredentialsRequest(`/api/players/remove/${playerID}`, REQUEST.DELETE)) as TPlayerOutput;
}

export default {};
