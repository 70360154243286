import axios from 'axios';

export type TRequestMessageInput = {
    name: string,
    email: string;
    message: string;
};

export type TRequestMessageOutput= {
    success: boolean;
    data: TRequestMessageInput;
}

export async function sendContactUsEmail(req: TRequestMessageInput): Promise<TRequestMessageOutput> {
    const resp = await axios.post(`/api/contactus`, req);
    return resp.data as TRequestMessageOutput;
}

export default {};