
import { defineComponent } from 'vue';
import Dialog from '@/components/lib/Dialog.vue';
import { ColorPicker } from 'vue-color-kit';
import { store } from '@/store/store';
import { MUTATION } from '@/store/mutations';

export default defineComponent({
    name: 'TheColorPicker',
    components: { Dialog, ColorPicker },
    props: {
        color: {
            type: String,
            required: true
        }
    },
    computed: {
        colorPickerVisible(): boolean {
            return store.state.colorPickerVisible;
        }
    },
    methods: {
        closeDialog() {
            store.commit(MUTATION.SHOW_COLOR_PICKER, false);
        },
        emitColorChange(ev: {hex: string}) {
            this.$emit('color-change', ev.hex);
        }
    }
});
