
import { defineComponent } from 'vue';
import Popup from '@/components/lib/Popup.vue';
import { store } from '@/store/store';
import { MUTATION } from '@/store/mutations';
import { POPUPS, POPUP_TYPES } from '@/constants';
import CSS from 'csstype';

export default defineComponent({
    name: 'TheTeamPoolDropdown',
    components: { Popup },
    data() {
        return {
            id: POPUPS.TEAM_POOL_DROPDOWN,
            dropdownType: POPUP_TYPES.DROP_DOWN
        }
    },
    computed: {
        encodedDivs(): string[] {
            return store.getters.sortedSeasonCategories;
        },
        pools(): string[] {
            return store.getters.poolsInDivision(this.division);
        },
        division(): string {
            return store.getters.poolDropdownDivision;
        },
        target(): HTMLElement | null {
            return store.getters.popupTarget(this.id);
        },
        isVisible(): boolean {
            return store.getters.isVisiblePopup(this.id);
        },
        dropdownPosition(): CSS.Properties {
            if(!this.target) return {} as CSS.Properties;

            const pos = this.target.getBoundingClientRect();
            return {
                top: pos.y + this.target.clientHeight + 'px',
                left: pos.x + 'px',
                width: pos.width + 'px'
            } as CSS.Properties;
        }
    },
    methods: {
        select(idx: number) {
            store.commit(MUTATION.UPDATE_ADD_TEAM_DIALOG, {
                ...store.state.addTeamDialogState.team,
                pool: this.pools[idx],
            });
            this.close();
        },
        close() {
            store.commit(MUTATION.HIDE_POPUP, this.id);
        }
    }
});
