export const enum STATE {
    ERR,
    STALE,
    LOADING,
    DONE,
    NONE
}

export const CURRENT_SEASON = 'current';

export const LDP_LOCAL_SESSION = 'ldp_session';

export const USER_ROLE_DEV = 'dev'; // not in USER_ROLES on purpose, so that it's hidden

export const USER_ROLES = {
    ADMIN: 'admin', CAPTAIN: 'captain', BASIC: 'basic'
}
export const USER_ROLES_PRIO: {[key: string]: number} = {
    [USER_ROLE_DEV]: 0, [USER_ROLES.ADMIN]: 1, [USER_ROLES.CAPTAIN]: 2, [USER_ROLES.BASIC]: 3
}

export type USER_ROLES_KEYS = keyof typeof USER_ROLES;
export type USER_ROLES_VALUES = typeof USER_ROLES[USER_ROLES_KEYS];

export const FIELD_TYPE = {
    OUTDOOR: 'outdoor', INDOOR: 'indoor'
}
type FIELD_TYPE_KEYS = keyof typeof FIELD_TYPE;
export type FIELD_TYPE_VALUES = typeof FIELD_TYPE[FIELD_TYPE_KEYS];

export const LOCAL_NOTIFICATION_PFX = "__local_";

export const EMPTY_IMAGE_SVG = `data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'></svg>`;

export const NO_POOLS = 'N/A';

export const enum POPUPS {
    NOTIFICATION,
    TEAM_SELECTION_DROPDOWN,
    TEAM_CAPTAIN_DROPDOWN,
    TEAM_FAVORITES_DROPDOWN,
    TEAM_DIVISION_DROPDOWN,
    TEAM_POOL_DROPDOWN,
    SPONSOR_TYPE_DROPDOWN
}

export const enum POPUP_TYPES {
    BUBBLE_DOWN, DROP_DOWN
}

export const enum CHECKBOX_TYPE {
    CHECKBOX, RADIO
}

export const RULES_COLLAPSE_TRIGGER_PREFIX = 'rules-';

export const GENDER_TYPE = {
    MAN: 'H', WOMAN: 'F'
}

export const MAX_CHAR_FREEAGENT_ADDITIONAL_INFO_INPUT = 500;

export const MAX_CHAR_CONTACTUS_MESSAGE = 2000;

export const enum NEXT_MODULE_DIR {
    UP, DOWN
}