import { TAppPjc, TAppUic } from "./services/config";

export function defaultAppPjc(): TAppPjc {
    return ({
        app: {
            fields: {
                externalLink: {
                    url: ''
                }
            },
            timeslots: {
                maxReservationRequests: 1
            },
            facebookURL: "",
            instagramURL: "",
            freeagentsURL: ""
        }
   });
}

export function defaultAppUic(): TAppUic {
    return ({
        app: {
            pages: {
                home: {
                    joinTeamBtn: {
                        visible: true
                    },
                    signUpBtn: {
                        visible: true
                    }
                },
                teams: {
                    joinYear: {
                        visible: true
                    }
                }
            }
        }
    });
}