
import { defineComponent } from 'vue';
import TeamLabel from '@/components/TeamLabel.vue';
import { TeamInterface } from '@/services/teams';
import { closeAllDialogsAndPopups, anyModalDialogVisible, MUTATION } from '@/store/mutations';
import { store } from '@/store/store';
import { toTitleCase } from '@/utils';
import { CURRENT_SEASON, POPUPS, USER_ROLES } from '@/constants';
import { TNotification, POPUP_DIRECTION } from '@/store/state';
import { ACTION } from '@/store/actions';
export default defineComponent({
    name: 'TheNavbar',
    components: { TeamLabel },
    computed: {
        userMenuVisible(): boolean {
            return store.state.userMenuVisible;
        },
        notificationsVisible(): boolean {
            return store.state.notificationsVisible;
        },
        isLoggedIn(): boolean {
            return store.getters.isLoggedIn;
        },
        role(): string {
            return toTitleCase(store.state.sessionInfo.perm.role);
        },
        user(): string {
            return store.state.sessionInfo.name;
        },
        teamsIFavorite(): TeamInterface[] {
            return store.getters.teamsIFavorite;
        },
        teamsICaptain(): TeamInterface[] {
            return store.getters.teamsICaptain;
        },
        anyModalDialogVisible(): boolean {
            return anyModalDialogVisible(store);
        },
        mainMenuVisible(): boolean {
            return store.state.mainMenuVisible;
        },
        userRoleImg(): string {
            switch(store.state.sessionInfo.perm.role) {
                case USER_ROLES.BASIC: return '/images/players/man/basketball-player.svg';
                case USER_ROLES.CAPTAIN: return '/images/players/man/king.png';
                case USER_ROLES.ADMIN: return '/images/players/man/dj.png';
                default: return "/images/players/missing_player_pic.png";
            }
        },
        hasNotifications(): boolean {
            return this.notifications.length > 0;
        },
        notifications(): TNotification[]{
            return store.state.sessionInfo.notifications
        },
        archiveView(): boolean {
            return store.getters.isArchiveMode;
        },
        seasonName(): string[] {
            return store.state.currentSeasonView.split(" - ");
        },
        emptySchedule(): boolean {
            return store.getters.emptySchedule;
        },
        rankingProvisional(): boolean{
            return Object.values(store.getters.seasonRankingProvisional).some(provisional => provisional);
        }
    },
    methods: {
        toggleLoginDialog(show: boolean, username?: string) {
            this.closeAllDialogsAndPopups();
            store.commit(MUTATION.SHOW_LOGIN_DIALOG, {
                show,
                username: username ?? ''
            });
        },
        toggleMainMenu(show?: boolean) {
            if(show === undefined) show = !this.mainMenuVisible;

            this.closeAllDialogsAndPopups();
            store.commit(MUTATION.SHOW_MAIN_MENU, show);
        },
        toggleUserMenu(show?: boolean) {
            if(show === undefined) show = !this.userMenuVisible;

            this.closeAllDialogsAndPopups();
            store.commit(MUTATION.SHOW_USER_MENU, show);
        },
        toggleNotifications(show?: boolean) {
            if(show === undefined) show = !this.notificationsVisible;
            this.closeAllDialogsAndPopups();
            store.commit(MUTATION.SHOW_NOTIFICATIONS, show);
        },
        showTeamFavoritesDropdown() {
            const input = this.$refs['favorite-dropdown'] as HTMLElement;
            if (!input) return;

            store.commit(MUTATION.SHOW_POPUP_ONLY, {
                id: POPUPS.TEAM_FAVORITES_DROPDOWN, location: input, direction: POPUP_DIRECTION.DOWN
            })
        },
        showTeamCaptainDropdown() {
            const input = this.$refs['captain-dropdown'] as HTMLElement;
            if (!input) return;

            store.commit(MUTATION.SHOW_POPUP_ONLY, {
                id: POPUPS.TEAM_CAPTAIN_DROPDOWN, location: input, direction: POPUP_DIRECTION.DOWN
            })
        },
        closeAllDialogsAndPopups() {
            closeAllDialogsAndPopups(store);
        },
        clearArchiveView() {
            store.dispatch(ACTION.SET_SEASON_VIEW, CURRENT_SEASON);
        }
    }
});
