import { STATE, USER_ROLES, POPUPS, USER_ROLES_VALUES, CURRENT_SEASON } from '@/constants';
import { ArchiveInterface } from '@/services/archives';
import { BackupInterface } from '@/services/backups';
import { TAppPjc, TAppUic } from '@/services/config';
import { FieldInterface } from '@/services/fields';
import { FreeAgentInterface } from '@/services/freeagents';
import { LogInterface } from '@/services/logs';
import { MatchInterface, TImportScheduleReport } from '@/services/matches';
import { ScheduleInterface, SeriesInterface } from '@/services/series';
import { FilteredTimeslotData, TimeslotInterface } from '@/services/timeslots';
import { TRankingOutput } from '@/services/stats';
import { PlayerInterface, TeamInterface } from '@/services/teams';
import { UserInterface } from '@/services/users';
import { SponsorInterface } from '@/services/sponsors';
import { defaultAppPjc, defaultAppUic } from '@/utils.cfg';
import { emptyMatchDef, emptySponsorDef, emptyTeamDef, emptyTimeslotDef } from '@/utils';

export const STORE_VERSION = '2022-05-15';
export const RELEASE_VERSION_KEY = 'wpi_app_version';

export function emptyFilterDef(): TFilterDef {
    return {
        fieldNamesFilter: [],
        cancelledFilter: false,
        divisionsFilter: [],
        teamNamesFilter: [],
        dateRangeFilter: [],
        roundFilter: null
    }
}

export type TFilterDef = {
    teamNamesFilter: string[],
    fieldNamesFilter: string[],
    divisionsFilter: string[],
    dateRangeFilter: number[],
    cancelledFilter: boolean,
    roundFilter: null | number[]
}

export type TFilterSets = {[filterSetName: string]: TFilterDef}

export type TActionState = {
    msg: string;
    status: STATE;
};

export type TUserAccess = {
    role: USER_ROLES_VALUES
    teams: string[]
};

export type TNotification = {
    _id: string,
    description: string
}

export type TSessionInfo = {
    name: string;
    perm: TUserAccess;
    loggedIn: boolean;
    email: string,
    notifications: TNotification[]
    _id: string
};

export type TWindowSize = {
    height: number;
    width: number;
};

export type TSignupDialogState = {
    show: boolean,
    name: string,
    username: string,
    teams: string[],
    teamCaptain: boolean
}

export type TLoginDialogState = {
    show: boolean,
    username: string,
}

export type TAddSponsorDialogState = {
    show: boolean,
    sponsor: SponsorInterface
}

export type TAddTeamDialogState = {
    show: boolean,
    team: TeamInterface
}

export type TTimeslotConfirmDialogState = {
    show: boolean,
    match: ScheduleInterface,
    timeslot: TimeslotInterface
}

export enum POPUP_DIRECTION {
    DOWN, UP
}

export type TPopupState = {
   id: POPUPS,
   location: HTMLElement | null,
   direction: POPUP_DIRECTION
}

export type TPoolDropwdownState = TPopupState & {
   division: string
}



/**
 * ClientSessionState is the minimal session data required to regenerate a
 * full session
 */
export interface ClientSessionState {
    storeVersion: string;
    releaseVersion: string;
    myTeams: string[]
}

/**
 * ClientUIState corresponds to the full UI state that EXACTLY describes the
 * actual UI state, as seen in the browser at any point in time
 */
export interface ClientUIState extends ClientSessionState {
    sessionInfo: TSessionInfo;
    uic: TAppUic,
    pjc: TAppPjc,
    assetsStatus: TActionState;
    loginRequestStatus: TActionState;
    signupRequestStatus: TActionState;
    passwordResetStatus: TActionState;
    savePreferencesStatus: TActionState;
    addSponsorDialogState: TAddSponsorDialogState;
    addTeamDialogState: TAddTeamDialogState;
    loginDialogState: TLoginDialogState;
    signupDialogState: TSignupDialogState;
    timeslotConfirmDialogState: TTimeslotConfirmDialogState;
    preferencesDialogVisible: boolean;
    scheduleFiltersDialogVisible: boolean;
    joinTeamDialogVisible: boolean;
    colorPickerVisible: boolean;
    mainMenuVisible: boolean;
    mainMenuInfoVisible: boolean;
    mainMenuArchiveVisible: boolean;
    userMenuVisible: boolean;
    notificationsVisible: boolean
    schedule: MatchInterface[];
    scheduleRequestStatus: TActionState;
    series: {[division: string]: SeriesInterface[]};
    seriesRequestStatus: TActionState;
    teams: TeamInterface[];
    teamsRequestStatus: TActionState;
    sponsors: SponsorInterface[];
    sponsorsRequestStatus: TActionState;
    addSponsorRequestStatus: TActionState;
    editSponsorRequestStatus: {[id: string]: TActionState};
    removeSponsorRequestStatus: {[id: string]: TActionState};
    seriesRanking: TRankingOutput;
    seasonRanking: TRankingOutput;
    fields: FieldInterface[];
    fieldsRequestStatus: TActionState;
    seasonRankingRequestStatus: TActionState;
    seriesRankingRequestStatus: TActionState;
    playersRequestStatus: TActionState;
    addMatchRequestStatus: TActionState;
    addMatchReport: TImportScheduleReport;
    removeMatchRequestStatus: {[id: string]: TActionState};
    editMatchRequestStatus: {[id: string]: TActionState};
    editSeriesRequestStatus: {[id: string]: TActionState};
    editTeamRequestStatus: {[id: string]: TActionState};
    addTeamRequestStatus: TActionState;
    removeTeamRequestStatus: {[id: string]: TActionState};
    addFieldRequestStatus: TActionState;
    removeFieldRequestStatus: {[id: string]: TActionState};
    addFavoriteTeamRequestStatus: TActionState;
    filterSets: TFilterSets;
    activeFilterSet: string,
    selectedMatchIx: number;
    popups: TPopupState[];
    googleMapsRequestStatus: TActionState;
    addPlayerStatus: TActionState;
    removePlayerStatus: TActionState;
    players: PlayerInterface[];
    freeAgents: FreeAgentInterface[];
    addMeEmailStatus: TActionState;
    emailRequestStatus: TActionState;
    windowSize: TWindowSize;
    resourceList: string[],
    resourceListRequestStatus: TActionState,
    resourceRequestStatus: {[resourceName: string]: TActionState},
    leagueDescription: {short: string, long: string},
    leagueDescriptionRequestStatus: TActionState,
    joinTeamMessage: string,
    joinTeamMessageRequestStatus: TActionState,
    users: UserInterface[],
    userRequestStatus: TActionState,
    pendingUsers: UserInterface[],
    pendingUserRequestStatus: TActionState,
    databaseLogs: LogInterface[],
    databaseLogRequestStatus: TActionState,
    backups: BackupInterface[],
    backupRequestStatus: TActionState,
    addBackupRequestStatus: TActionState,
    getBackupRequestStatus: TActionState,
    removeBackupRequestStatus: TActionState,
    restoreBackupRequestStatus: TActionState,

    archives: ArchiveInterface[],
    archiveRequestStatus: TActionState,
    addArchiveRequestStatus: TActionState,
    currentSeasonView: string,

    facebookLink: string,
    instagramLink: string,
    agentLibreLink: string,

    timeslots: FilteredTimeslotData,
    addTimeslotRequestStatus: TActionState,
    removeTimeslotRequestStatus: {[id: string]: TActionState},
    timeslotsRequestStatus: TActionState,
    timeslotReservationRequestStatus: {[id: string]: TActionState}
}

/**
 * defaultSessionState populates all the properties of the full UI state
 * with default valid values. This is a clean session, as started from scratch
 */
export function defaultSessionState(): ClientUIState {
    return {
        storeVersion: STORE_VERSION,
        uic: defaultAppUic(),
        pjc: defaultAppPjc(),
        assetsStatus: {msg: '', status: STATE.STALE},
        releaseVersion: localStorage.getItem(RELEASE_VERSION_KEY) || '0',
        myTeams: [],
        sessionInfo: {
            perm: {
                role: USER_ROLES.BASIC,
                teams: [],
            },
            name: '',
            loggedIn: false,
            email: '',
            notifications: [],
            _id: ''
        },
        loginRequestStatus: {
            msg: '',
            status: STATE.DONE
        },
        signupRequestStatus: {
            msg: '',
            status: STATE.DONE
        },
        passwordResetStatus: {
            msg: '',
            status: STATE.DONE
        },
        savePreferencesStatus: {
            msg: '',
            status: STATE.DONE
        },
        addSponsorDialogState: {
            show: false,
            sponsor: emptySponsorDef()
        },
        addTeamDialogState: {
            show: false,
            team: emptyTeamDef()
        },
        loginDialogState: {
            show: false,
            username: ''
        },
        signupDialogState: {
            show: false,
            username: '',
            teams: [],
            teamCaptain: false,
            name: ''
        },
        timeslotConfirmDialogState: {
            show: false,
            match: emptyMatchDef(),
            timeslot: emptyTimeslotDef()
        },
        preferencesDialogVisible: false,
        scheduleFiltersDialogVisible: false,
        joinTeamDialogVisible: false,
        colorPickerVisible: false,
        mainMenuVisible: false,
        mainMenuInfoVisible: false,
        mainMenuArchiveVisible: false,
        userMenuVisible: false,
        notificationsVisible: false,
        addMatchRequestStatus: {
            msg: '',
            status: STATE.DONE
        },
        addMatchReport: {
            matchConflicts: [],
            missingTeams: [],
            missingFields: [],
            badMatchData: []
        },
        removeMatchRequestStatus: {},
        editMatchRequestStatus: {},
        editSeriesRequestStatus: {},
        editTeamRequestStatus: {},
        removeTeamRequestStatus: {},
        addTeamRequestStatus: {
            msg: '',
            status: STATE.DONE
        },
        addFieldRequestStatus: {
            msg: '',
            status: STATE.DONE
        },
        removeFieldRequestStatus: {},
        addFavoriteTeamRequestStatus: {
            msg: '',
            status: STATE.DONE
        },
        teams: [],
        teamsRequestStatus: {
            msg: '',
            status: STATE.STALE
        },
        sponsors: [],
        sponsorsRequestStatus: {
            msg: '',
            status: STATE.STALE
        },
        editSponsorRequestStatus: {},
        removeSponsorRequestStatus: {},
        addSponsorRequestStatus: {
            msg: '',
            status: STATE.DONE
        },
        fields: [],
        fieldsRequestStatus: {
            msg: '',
            status: STATE.STALE
        },
        seriesRankingRequestStatus: {
            msg: '',
            status: STATE.STALE
        },
        seasonRankingRequestStatus: {
            msg: '',
            status: STATE.STALE
        },
        playersRequestStatus: {
            msg: '',
            status: STATE.STALE
        },
        windowSize: {
            height: window.innerHeight,
            width: window.innerWidth
        },

        // TEAMS VIEW

        seriesRanking: {},
        seasonRanking: {},

        // SCHEDULE VIEW

        schedule: [],
        scheduleRequestStatus: {
            msg: '',
            status: STATE.STALE
        },
        series: {},
        seriesRequestStatus: {
            msg: '',
            status: STATE.STALE
        },
        filterSets: {
            '__none__': emptyFilterDef()
        },
        selectedMatchIx: -1,
        activeFilterSet: '__none__',

        popups: [],

        googleMapsRequestStatus: {
            status: STATE.NONE, msg: ''
        },

        // TEAM VIEW

        addPlayerStatus: {
            msg: '',
            status: STATE.DONE
        },

        removePlayerStatus: {
            msg: '',
            status: STATE.DONE 
        },

        players: [],
        
        // FREE AGENTS VIEW

        freeAgents: [],
        addMeEmailStatus: {
            msg: '',
            status: STATE.DONE
        },

        // HOME VIEW
        emailRequestStatus: {
            msg: '',
            status: STATE.DONE
        },

        resourceList: [],
        resourceListRequestStatus: {
            msg: '',
            status: STATE.STALE
        },
        resourceRequestStatus: {},

        leagueDescription: {short: '', long: ''},
        leagueDescriptionRequestStatus: {
            msg: '',
            status: STATE.STALE
        },
        joinTeamMessage: '',
        joinTeamMessageRequestStatus: {
            msg: '',
            status: STATE.STALE
        },

        users: [],
        userRequestStatus: {
            msg: '',
            status: STATE.STALE
        },

        pendingUsers: [],
        pendingUserRequestStatus: {
            msg: '',
            status: STATE.STALE
        },

        databaseLogs: [],
        databaseLogRequestStatus: {
            msg: '',
            status: STATE.STALE
        },

        archives: [],
        archiveRequestStatus: {
            msg: '',
            status: STATE.STALE
        },
        addArchiveRequestStatus: {
            msg: '',
            status: STATE.DONE
        },
        currentSeasonView: CURRENT_SEASON,

        backups: [],
        backupRequestStatus: {
            msg: '',
            status: STATE.STALE
        },
        addBackupRequestStatus: {
            msg: '',
            status: STATE.DONE
        },
        getBackupRequestStatus: {
            msg: '',
            status: STATE.STALE
        },
        removeBackupRequestStatus: {
            msg: '',
            status: STATE.DONE
        },
        restoreBackupRequestStatus: {
            msg: '',
            status: STATE.DONE
        },

        facebookLink: process.env.VUE_APP_FACEBOOK_LINK ?? '',
        instagramLink: process.env.VUE_APP_INSTAGRAM_LINK ?? '',
        agentLibreLink: process.env.VUE_APP_AGENT_LIBRE_LINK ?? '',

        // TIMESLOT VIEW
        timeslots: {
            available: [],
            pending: [],
            confirmed: [],
            booked: []
        },
        timeslotsRequestStatus: {
            msg: '',
            status: STATE.STALE
        },
        addTimeslotRequestStatus: {
            msg: '',
            status: STATE.DONE
        },
        removeTimeslotRequestStatus: {},
        timeslotReservationRequestStatus: {},
    };
}

export const state: ClientUIState = defaultSessionState();
