
import { defineComponent } from 'vue';
import Popup from '@/components/lib/Popup.vue';
import { store } from '@/store/store';
import { MUTATION } from '@/store/mutations';
import { POPUPS, POPUP_TYPES } from '@/constants';
import { decodeTeamDivision } from '@/utils';
import CSS from 'csstype';

export default defineComponent({
    name: 'TheTeamDivisionDropdown',
    components: { Popup },
    data() {
        return {
            id: POPUPS.TEAM_DIVISION_DROPDOWN,
            dropdownType: POPUP_TYPES.DROP_DOWN
        }
    },
    computed: {
        encodedDivs(): string[] {
            return store.getters.sortedSeasonCategories;
        },
        divisions(): string[] {
            const uniqDiv: {[div: string]: boolean} = {};
            const divs = [] as string[];
            this.encodedDivs.forEach(d => {
                const {division} = decodeTeamDivision(d);
                if(uniqDiv[division])  return;
                uniqDiv[division] = true;
                divs.push(division);
            });
            return divs;
        },
        target(): HTMLElement | null {
            return store.getters.popupTarget(this.id);
        },
        isVisible(): boolean {
            return store.getters.isVisiblePopup(this.id);
        },
        dropdownPosition(): CSS.Properties {
            if(!this.target) return {} as CSS.Properties;

            const pos = this.target.getBoundingClientRect();
            return {
                top: pos.y + this.target.clientHeight + 'px',
                left: pos.x + 'px',
                width: pos.width + 'px'
            } as CSS.Properties;
        }
    },
    methods: {
        select(idx: number) {
            store.commit(MUTATION.UPDATE_ADD_TEAM_DIALOG, {
                ...store.state.addTeamDialogState.team,
                division: this.divisions[idx]
            });
            this.close();
        },
        close() {
            store.commit(MUTATION.HIDE_POPUP, this.id);
        }
    }
});
