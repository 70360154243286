
import { defineComponent } from 'vue';
import Dialog from '@/components/lib/Dialog.vue';
import { store } from '@/store/store';
import { MUTATION } from '@/store/mutations';
import { ACTION } from '@/store/actions';

export default defineComponent({
    name: 'TheLoginDialog',
    components: {
        Dialog
    },
    computed: {
        joinTeamDialogVisible(): boolean {
            return store.state.joinTeamDialogVisible;
        },
        msg(): string {
            return store.state.joinTeamMessage;
        }
    },
    methods: {
        closeDialog() {
            store.commit(MUTATION.SHOW_JOIN_TEAM_DIALOG, false);
        }
    },
    mounted() {
        store.dispatch(ACTION.FETCH_JOIN_TEAM_MESSAGE, undefined);
    }
});
