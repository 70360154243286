import axios from 'axios';
import { REQUEST } from './documentation';
import { ldpCredentialsRequest } from './serverEngine';

export type ArchiveListOutput = {
    archives: ArchiveInterface[]
};

export type TAddArchiveOutput = {
    success: boolean,
    error: string,
    seriesUpdated: number,
    matchUpdated: number,
    timeslotUpdated: number
} & ArchiveListOutput
export interface ArchiveInterface {
    name: string,
    startDate: string,
    stopDate: string
}

export async function fetchArchives(): Promise<ArchiveInterface[]> {
    const resp = (await axios.get(`/api/seasons`)).data as ArchiveListOutput;
    return resp.archives;
}

export async function createArchive(): Promise<TAddArchiveOutput> {
    const resp = (await ldpCredentialsRequest(`/api/seasons/add`, REQUEST.PUT)) as TAddArchiveOutput;
    return resp;
}

export async function editArchiveName(newArchiveInterface: ArchiveInterface): Promise<ArchiveInterface[]> {
    const resp = (await ldpCredentialsRequest(`/api/seasons/edit-name`, REQUEST.POST, newArchiveInterface)) as ArchiveListOutput;
    console.log('resp:',resp)
    return resp.archives;
}

export default {};