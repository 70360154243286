import axios from 'axios';

export interface TeamStats {
    team: string;
    games: number;
    win: number;
    lost: number;
    nul: number;
    points: number;
    pf: number;
    pa: number;
    diff: number;
    awaitingApproval: boolean;
}

export function defaultStats(team: string): TeamStats{
    return {
        team,
        games: 0,
        win: 0,
        lost: 0,
        nul: 0,
        points: 0,
        pf: 0,
        pa: 0,
        diff: 0,
        awaitingApproval: false
    };
}

export type TRankingOutput = {[divisionName: string]: TeamStats[]};

export async function fetchSeasonRankings(season?: string): Promise<TRankingOutput> {
    let route = `/api/ranking/season`;
    if (season) {
        route += `?season=${encodeURI(season)}`;
    }
    const resp = await axios.get(route);
    return resp.data as TRankingOutput;
}

export async function fetchTeamStats(teamName: string, season?: string): Promise<TeamStats> {
    let route = `/api/stats/${encodeURI(teamName)}`;
    if (season) {
        route += `?season=${encodeURI(season)}`;
    }
    const resp = await axios.get(route);
    return resp.data as TeamStats;
}

export async function fetchSeriesRankings(season?: string): Promise<TRankingOutput> {
    let route = `/api/ranking/series`;
    if (season) {
        route += `?season=${encodeURI(season)}`;
    }
    const resp = await axios.get(route);
    return resp.data as TRankingOutput;
}

export default {};
