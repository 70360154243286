import axios from "axios";
import { REQUEST } from "./documentation";
import { ldpCredentialsRequest } from "./serverEngine";

export const SPONSOR_TYPES = {
    PLATINUM: 'Platinum',
    GOLD: 'Gold',
    SILVER: 'Silver',
    BRONZE: 'Bronze',
    NONE: ''
}

type SPONSOR_TYPES_KEYS = keyof typeof SPONSOR_TYPES;
export type SPONSOR_TYPES_VALUES = typeof SPONSOR_TYPES[SPONSOR_TYPES_KEYS];

export interface SponsorInterface {
    _id: string;
    name: string;
    description: string;
    telephone: string;
    websiteUrl: string;
    contactEmail: string;
    sponsorType: SPONSOR_TYPES_VALUES;
    affiliateTeams: string[];
    color?: string;
    picture?: string;
    logo?: string;
}

export type TSponsorsListOutput = {
    sponsors: SponsorInterface[]
}

export type TSponsorOutput = {
    sponsor: SponsorInterface
}

export type TEditSponsorOutput = {
    success: boolean;
    error: string;
    sponsor: SponsorInterface
}


export type TRemoveSponsorOutput = {
    success: boolean;
    error: string;
}

export type TEditSponsorInput = {
    sponsor: SponsorInterface,
    images: {
        logo: File | null,
        picture: File | null
    }
}

export type TAddSponsorInput = {
    sponsor: Omit<SponsorInterface, '_id'>,
    images: {
        logo: File | null,
        picture: File | null
    }
}

export type TAddSponsorOutput = {
    success: boolean;
    error: string;
    sponsor?: SponsorInterface;
}

export async function addSponsor(payload: TAddSponsorInput): Promise<TAddSponsorOutput> {
    const formData = new FormData();
    if(payload.images.logo) {
        formData.append("files", payload.images.logo);
        formData.append("logo-filename", payload.images.logo.name);
    }
    if(payload.images.picture) {
        formData.append("files", payload.images.picture);
        formData.append("picture-filename", payload.images.picture.name);
    }
    (Object.keys(payload.sponsor)).forEach(k => {
        formData.append(k, (payload.sponsor as any)[k]);
    });
    return (await ldpCredentialsRequest(`/api/sponsors/add`, REQUEST.PUT, formData)) as TAddSponsorOutput;
}

export async function removeSponsor(id: string): Promise<TRemoveSponsorOutput> {
    return (await ldpCredentialsRequest(`/api/sponsors/remove/${id}`, REQUEST.DELETE)) as TRemoveSponsorOutput;
}

export async function editSponsor(payload: TEditSponsorInput): Promise<TEditSponsorOutput> {
    const formData = new FormData();
    if(payload.images.logo) {
        formData.append("files", payload.images.logo);
        formData.append("logo-filename", payload.images.logo.name);
    }
    if(payload.images.picture) {
        formData.append("files", payload.images.picture);
        formData.append("picture-filename", payload.images.picture.name);
    }
    (Object.keys(payload.sponsor)).forEach(k => {
        formData.append(k, (payload.sponsor as any)[k]);
    });
    return (await ldpCredentialsRequest(`/api/sponsors/edit/${payload.sponsor._id}`, REQUEST.POST, formData)) as TEditSponsorOutput;
}

export async function fetchSponsors(): Promise<TSponsorsListOutput> {
    const route = `/api/sponsors`;
    const resp = await axios.get(route);
    return resp.data as TSponsorsListOutput;
}

export default {}
