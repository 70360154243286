import { LOCAL_NOTIFICATION_PFX, POPUPS } from '@/constants';
import { ArchiveInterface } from '@/services/archives';
import { BackupInterface } from '@/services/backups';
import { TAppPjc, TAppUic } from '@/services/config';
import { FieldInterface } from '@/services/fields';
import { FreeAgentInterface } from '@/services/freeagents';
import { LogInterface } from '@/services/logs';
import { MatchInterface, TImportScheduleReport } from '@/services/matches';
import { SeriesInterface } from '@/services/series';
import { FilteredTimeslotData } from '@/services/timeslots';
import { TRankingOutput } from '@/services/stats';
import { PlayerInterface, TeamInterface } from '@/services/teams';
import { UserInterface } from '@/services/users';
import { SponsorInterface } from '@/services/sponsors';
import { emptyMatchDef, emptySponsorDef, emptyTeamDef, emptyTimeslotDef } from '@/utils';
import { MutationTree } from 'vuex';
import {
    ClientUIState, defaultSessionState,
    TActionState, TAddSponsorDialogState, TAddTeamDialogState, TLoginDialogState,
    TPopupState, TSessionInfo, TSignupDialogState, TTimeslotConfirmDialogState,
    TWindowSize
} from './state';
import { TStoreContext } from './store';
import { emptyFilterDef } from './state';

export enum MUTATION {
    RESET_STORE                                 = 'resetStore',
    SET_APP_UIC                                 = 'setAppUic',
    SET_APP_PJC                                 = 'setAppPjc',
    SET_ASSET_STATUS                            = 'setAssetsStatus',
    SET_LOGIN_STATUS                            = 'setLoginStatus',
    SET_SIGNUP_STATUS                           = 'setSignupStatus',
    SAVE_PREFERENCES_STATUS                     = 'savePreferencesStatus',
    SET_PASSWORD_RESET_STATUS                   = 'setPasswordResetStatus',
    SET_SESSION_INFO                            = 'setSessionInfo',
    SET_SCHEDULE                                = 'setSchedule',
    SET_SERIES_MATCHUP                          = 'setSeriesMatchup',
    SET_SERIES_REQUEST_STATUS                   = 'setSeriesRequestStatus',
    SET_SCHEDULE_REQUEST_STATUS                 = 'setScheduleRequestStatus',
    SET_STATE                                   = 'setState',

    SET_SPONSORS_REQUEST_STATUS                 = 'setSponsorsRequestStatus',
    SET_SPONSORS                                = 'setSponsors',
    EDIT_SPONSOR_REQUEST_STATUS                 = 'editSponsorRequestStatus',
    SET_SPONSOR                                 = 'setSponsor',
    ADD_SPONSOR_REQUEST_STATUS                  = 'addSponsorRequestStatus',
    ADD_SPONSOR                                 = 'addSponsor',
    REMOVE_SPONSOR_REQUEST_STATUS               = 'removeSponsorRequestStatus',
    REMOVE_SPONSOR                              = 'removeSponsor',

    SET_TEAMS                                   = 'setTeams',
    EDIT_TEAM_REQUEST_STATUS                    = 'editTeamRequestStatus',
    SET_TEAM                                    = 'setTeam',
    ADD_TEAM_REQUEST_STATUS                     = 'addTeamRequestStatus',
    ADD_TEAM                                    = 'addTeam',
    REMOVE_TEAM_REQUEST_STATUS                  = 'removeTeamRequestStatus',
    REMOVE_TEAM                                 = 'removeTeam',
    SET_FAVORITE_TEAMS                          = 'setFavoriteTeams',
    SET_CAPTAIN_TEAMS                           = 'setCaptainTeams',
    SET_TEAMS_REQUEST_STATUS                    = 'setTeamsStatus',

    SET_SEASON_RANKINGS                         = 'setSeasonRankings',
    SET_SERIES_RANKINGS                         = 'setSeriesRankings',
    SET_SEASON_RANKINGS_REQUEST_STATUS          = 'setSeasonRankingRequestStatus',
    SET_SERIES_RANKINGS_REQUEST_STATUS          = 'setSeriesRankingRequestStatus',
    SET_FIELDS                                  = 'setFields',
    SET_FIELDS_REQUEST_STATUS                   = 'setFieldsRequestStatus',
    SET_PLAYERS_REQUEST_STATUS                  = 'setPlayersRequestStatus',
    SET_DATE_RANGE_FILTERS                      = 'setDateRangeFilters',
    SET_TEAM_NAMES_FILTERS                      = 'setTeamNamesFilters',
    SET_FIELD_NAMES_FILTERS                     = 'setFieldNamesFilters',
    SET_DIVISION_FILTERS                        = 'setDivisionFilters',
    SET_ROUND_FILTER                            = 'setShowRoundFilter',
    SET_CANCELLED_FILTER                        = 'setCancelledFilter',
    RESET_SCHEDULE_FILTERS                      = 'resetScheduleFilters',
    SET_SELECTED_MATCH_IDX                      = 'setSelectedMatchIdx',
    ADD_TO_FAVORITE_TEAM                        = 'addToFavoriteTeam',
    REMOVE_FROM_FAVORITE_TEAM                   = 'removeFromFavoriteTeam',

    SET_ADD_MATCH_STATUS                        = 'setAddMatchStatus',
    SET_ADD_MATCH_REPORT                        = 'setAddMatchReport',
    SET_REMOVE_MATCH_STATUS                     = 'setRemoveMatchStatus',
    SET_EDIT_MATCH_STATUS                       = 'setEditMatchStatus',
    SET_EDIT_SERIES_STATUS                      = 'setEditSeriesStatus',
    SET_ADD_FIELD_STATUS                        = 'setAddFieldStatus',
    SET_REMOVE_FIELD_STATUS                     = 'setRemoveFieldStatus',
    SET_ADD_TO_FAVORITE_TEAM_STATUS             = 'setAddToFavoriteTeamStatus',

    SET_ADD_PLAYER_STATUS                       = 'setAddPlayerStatus',
    SET_REMOVE_PLAYER_STATUS                    = 'setRemovePlayerStatus',
    SET_PLAYERS                                 = 'setPlayers',

    SET_FREE_AGENTS                             = 'setFreeAgents',
    SET_ADD_ME_EMAIL_STATUS                     = 'setAddMeEmailStatus',
    SET_EMAIL_REQUEST_STATUS                    = 'setEmailRequestStatus',

    SHOW_ADD_SPONSOR_DIALOG                     = 'showAddSponsorDialog',
    UPDATE_ADD_SPONSOR_DIALOG                   = 'updateAddSponsorDialog',

    SHOW_ADD_TEAM_DIALOG                        = 'showAddTeamDialog',
    UPDATE_ADD_TEAM_DIALOG                      = 'updateAddTeamDialog',
    SET_TIMESLOTS                               = 'setTimeslots',
    SET_TIMESLOTS_REQUEST_STATUS                = 'setTimeslotsRequestStatus',
    SET_ADD_TIMESLOT_STATUS                     = 'setAddTimeslotStatus',
    SET_REMOVE_TIMESLOT_STATUS                  = 'setRemoveTimeslotStatus',
    SET_TIMESLOT_RESERVATION_REQUEST_STATUS     = 'setTimeslotReservationRequestStatus',

    SHOW_LOGIN_DIALOG                           = 'showLoginDialog',
    SHOW_SIGNUP_DIALOG                          = 'showSignupDialog',
    SHOW_PREFERENCES_DIALOG                     = 'showPreferencesDialog',
    SHOW_JOIN_TEAM_DIALOG                       = 'showJoinTeamDialog',
    SHOW_COLOR_PICKER                           = 'showColorPicker',
    SHOW_TIMESLOT_CONFIRM_DIALOG                = 'showTimeslotConfirmDialog',
    SHOW_MAIN_MENU                              = 'showMainMenu',
    SHOW_MAIN_MENU_INFO                         = 'showMainMenuInfo',
    SHOW_MAIN_MENU_ARCHIVE                      = 'showMainMenuArchive',
    SHOW_USER_MENU                              = 'showUserMenu',
    SHOW_NOTIFICATIONS                          = 'showNotifications',
    SHOW_SCHEDULE_FILTERS                       = 'showScheduleFilters',
    SET_ACTIVE_FILTER_SET                       = 'setActiveFilterSet',
    SHOW_POPUP                                  = 'add_Popup',
    HIDE_POPUP                                  = 'remove_Popup',
    SHOW_POPUP_ONLY                             = 'clearAdd_Popup',
    CLEAR_POPUPS                                = 'clear_Popups',
    WINDOW_SIZE                                 = 'windowSize',

    DELETE_NOTIFICATION                         = 'deleteNotification',

    GOOGLE_MAPS_REQUEST_STATUS                  = 'googleMapsRequestStatus',
    GOOGLE_MAPS_EDIT_REQUEST_STATUS             = 'googleMapsEditRequestStatus',

    SET_RESOURCE_LIST                           = 'setResourceList',
    SET_RESOURCE_LIST_REQUEST_STATUS            = 'setResourceListRequestStatus',
    SET_RESOURCE_REQUEST_STATUS                 = 'setResourceRequestStatus',
    SET_JOIN_TEAM_MESSAGE_REQUEST_STATUS        = 'setJoinTeamRequestStatus',
    SET_LEAGUE_DESCRIPTION_REQUEST_STATUS       = 'setLeagueDescriptionRequestStatus',
    SET_LEAGUE_DESCRIPTION                      = 'setLeagueDescription',
    SET_JOIN_TEAM_MESSAGE                       = 'setJoinTeamMessage',

    SET_BACKUPS                                 = 'setBackupList',
    SET_BACKUP_REQUEST_STATUS                   = 'setBackupListRequestStatus',
    SET_ADD_BACKUP_REQUEST_STATUS               = 'setAddBackupRequestStatus',
    GET_BACKUP_REQUEST_STATUS                   = 'getBackupRequestStatus',
    SET_REMOVE_BACKUP_REQUEST_STATUS            = 'setRemoveBackupRequestStatus',
    SET_RESTORE_BACKUP_REQUEST_STATUS           = 'setRestoreBackupRequestStatus',

    SET_ARCHIVES                                = 'setArchiveList',
    SET_ARCHIVE_REQUEST_STATUS                  = 'setArchiveListRequestStatus',
    SET_ADD_ARCHIVE_REQUEST_STATUS              = 'setAddArchiveRequestStatus',
    SET_SEASON_VIEW                             = 'setSeasonView',

    SET_USERS                                   = 'setUserList',
    SET_USER_REQUEST_STATUS                     = 'setUserRequestStatus',
    SET_PENDING_USERS                           = 'setPendingUsers',
    SET_PENDING_USER_REQUEST_STATUS             = 'setPendingUserRequestStatus',
    SET_DBLOGS                                  = 'setDBLogsList',
    SET_DBLOG_REQUEST_STATUS                    = 'setDBLogRequestStatus',
    ADD_TMP_NOTIFICATION                        = 'addTempNotification',
    SET_TIMESLOT_EMAIL_REQUEST_STATUS           = 'setTimeSlotEmailRequestStatus'
}

export type TMutations = {
    [MUTATION.RESET_STORE]                              (state: ClientUIState)                                          : void;
    [MUTATION.SET_APP_PJC]                              (state: ClientUIState, pjc: TAppPjc)                            : void;
    [MUTATION.SET_APP_UIC]                              (state: ClientUIState, pjc: TAppUic)                            : void;
    [MUTATION.SET_ASSET_STATUS]                         (state: ClientUIState, status: TActionState)                    : void;
    [MUTATION.SET_LOGIN_STATUS]                         (state: ClientUIState, status: TActionState)                    : void;
    [MUTATION.SET_SIGNUP_STATUS]                        (state: ClientUIState, status: TActionState)                    : void;
    [MUTATION.SAVE_PREFERENCES_STATUS]                  (state: ClientUIState, status: TActionState)                    : void;
    [MUTATION.SET_PASSWORD_RESET_STATUS]                (state: ClientUIState, status: TActionState)                    : void;
    [MUTATION.SET_SESSION_INFO]                         (state: ClientUIState, sessInfo: TSessionInfo)                  : void;
    [MUTATION.SET_STATE]                                (state: ClientUIState, newState: ClientUIState)                 : void;
    [MUTATION.SET_ADD_TO_FAVORITE_TEAM_STATUS]          (state: ClientUIState, status: TActionState)                    : void;
    [MUTATION.ADD_TO_FAVORITE_TEAM]                     (state: ClientUIState, teams: TeamInterface[])                  : void;
    [MUTATION.REMOVE_FROM_FAVORITE_TEAM]                (state: ClientUIState, teams: TeamInterface[])                  : void;
    [MUTATION.SET_ADD_MATCH_STATUS]                     (state: ClientUIState, status: TActionState)                    : void;
    [MUTATION.SET_ADD_MATCH_REPORT]                     (state: ClientUIState, report: TImportScheduleReport)           : void;
    [MUTATION.SET_REMOVE_MATCH_STATUS]                  (state: ClientUIState, status: TActionState & { id: string })   : void;
    [MUTATION.SET_EDIT_MATCH_STATUS]                    (state: ClientUIState, status: TActionState & { id: string })   : void;
    [MUTATION.SET_EDIT_SERIES_STATUS]                   (state: ClientUIState, status: TActionState & { id: string })   : void;
    [MUTATION.EDIT_TEAM_REQUEST_STATUS]                 (state: ClientUIState, status: TActionState & { id: string })   : void;
    [MUTATION.REMOVE_TEAM_REQUEST_STATUS]               (state: ClientUIState, status: TActionState & { id: string })   : void;
    [MUTATION.ADD_TEAM_REQUEST_STATUS]                  (state: ClientUIState, status: TActionState)                    : void;
    [MUTATION.SET_TEAMS_REQUEST_STATUS]                 (state: ClientUIState, status: TActionState)                    : void;

    [MUTATION.SET_SPONSORS_REQUEST_STATUS]              (state: ClientUIState, status: TActionState)                    : void;
    [MUTATION.SET_SPONSORS]                             (state: ClientUIState, sponsors: SponsorInterface[])            : void;
    [MUTATION.EDIT_SPONSOR_REQUEST_STATUS]              (state: ClientUIState, status: TActionState & { id: string })   : void;
    [MUTATION.SET_SPONSOR]                              (state: ClientUIState, sponsor: SponsorInterface)               : void;
    [MUTATION.REMOVE_SPONSOR_REQUEST_STATUS]            (state: ClientUIState, status: TActionState & { id: string })   : void;
    [MUTATION.REMOVE_SPONSOR]                           (state: ClientUIState, id: string)                              : void;
    [MUTATION.ADD_SPONSOR_REQUEST_STATUS]               (state: ClientUIState, status: TActionState)                    : void;
    [MUTATION.ADD_SPONSOR]                              (state: ClientUIState, sponsor: SponsorInterface)               : void;

    [MUTATION.SET_ADD_FIELD_STATUS]                     (state: ClientUIState, status: TActionState)                    : void;
    [MUTATION.SET_REMOVE_FIELD_STATUS]                  (state: ClientUIState, status: TActionState & { id: string })   : void;
    [MUTATION.SET_PLAYERS_REQUEST_STATUS]               (state: ClientUIState, status: TActionState)                    : void;
    [MUTATION.SET_REMOVE_PLAYER_STATUS]                 (state: ClientUIState, status: TActionState)                    : void;
    [MUTATION.SET_ADD_TIMESLOT_STATUS]                  (state: ClientUIState, status: TActionState)                    : void;
    [MUTATION.SET_REMOVE_TIMESLOT_STATUS]               (state: ClientUIState, status: TActionState & { id: string })   : void;
    [MUTATION.SET_SCHEDULE]                             (state: ClientUIState, schedule: MatchInterface[])              : void;
    [MUTATION.SET_SERIES_MATCHUP]                       (state: ClientUIState, series: {[key: string]: SeriesInterface[]}): void;
    [MUTATION.SET_SCHEDULE_REQUEST_STATUS]              (state: ClientUIState, status: TActionState)                    : void;
    [MUTATION.SET_SERIES_REQUEST_STATUS]                (state: ClientUIState, status: TActionState)                    : void;
    [MUTATION.SET_TIMESLOTS_REQUEST_STATUS]             (state: ClientUIState, status: TActionState)                    : void;
    [MUTATION.SET_TIMESLOT_RESERVATION_REQUEST_STATUS]  (state: ClientUIState, status: TActionState & { id: string })   : void;
    [MUTATION.SET_TEAMS]                                (state: ClientUIState, teams: TeamInterface[])                  : void;
    [MUTATION.SET_TEAM]                                 (state: ClientUIState, team: TeamInterface)                     : void;
    [MUTATION.ADD_TEAM]                                 (state: ClientUIState, team: TeamInterface)                     : void;
    [MUTATION.REMOVE_TEAM]                              (state: ClientUIState, id: string)                              : void;
    [MUTATION.SET_FAVORITE_TEAMS]                       (state: ClientUIState, teams: TeamInterface[])                  : void;
    [MUTATION.SET_CAPTAIN_TEAMS]                        (state: ClientUIState, teams: TeamInterface[])                  : void;

    [MUTATION.SET_FIELDS]                               (state: ClientUIState, fields: FieldInterface[])                : void;
    [MUTATION.SET_FIELDS_REQUEST_STATUS]                (state: ClientUIState, status: TActionState)                    : void;

    [MUTATION.SET_SEASON_RANKINGS_REQUEST_STATUS]       (state: ClientUIState, status: TActionState)                    : void;
    [MUTATION.SET_SERIES_RANKINGS_REQUEST_STATUS]       (state: ClientUIState, status: TActionState)                    : void;
    
    [MUTATION.SET_SEASON_RANKINGS]                      (state: ClientUIState, ranking: TRankingOutput)                 : void;
    [MUTATION.SET_SERIES_RANKINGS]                      (state: ClientUIState, ranking: TRankingOutput)                 : void;

    [MUTATION.SHOW_SCHEDULE_FILTERS]                    (state: ClientUIState, show: boolean)                           : void;
    [MUTATION.SET_ACTIVE_FILTER_SET]                    (state: ClientUIState, activeFilterSetName: string)             : void;
    [MUTATION.SET_TEAM_NAMES_FILTERS]                   (state: ClientUIState, payload: {filterSet: string, teamNames: string[]})    : void;
    [MUTATION.SET_DATE_RANGE_FILTERS]                   (state: ClientUIState, payload: {filterSet: string, dateRange: number[]})    : void;
    [MUTATION.SET_FIELD_NAMES_FILTERS]                  (state: ClientUIState, payload: {filterSet: string, fieldNames: string[]})   : void;
    [MUTATION.SET_DIVISION_FILTERS]                     (state: ClientUIState, payload: {filterSet: string, divisions: string[]})    : void;
    [MUTATION.SET_CANCELLED_FILTER]                     (state: ClientUIState, payload: {filterSet: string, cancelled: boolean})     : void;
    [MUTATION.SET_ROUND_FILTER]                         (state: ClientUIState, payload: {filterSet: string, rounds: number[] | null}): void;
    [MUTATION.RESET_SCHEDULE_FILTERS]                   (state: ClientUIState, filterSet: string)                       : void;
    [MUTATION.SET_SELECTED_MATCH_IDX]                   (state: ClientUIState, matchIndex: number)                      : void;
    [MUTATION.SHOW_ADD_SPONSOR_DIALOG]                  (state: ClientUIState, payload: TAddSponsorDialogState)         : void;
    [MUTATION.UPDATE_ADD_SPONSOR_DIALOG]                (state: ClientUIState, payload: SponsorInterface)               : void;
    [MUTATION.SHOW_ADD_TEAM_DIALOG]                     (state: ClientUIState, payload: TAddTeamDialogState)            : void;
    [MUTATION.UPDATE_ADD_TEAM_DIALOG]                   (state: ClientUIState, payload: TeamInterface)                  : void;
    [MUTATION.SHOW_LOGIN_DIALOG]                        (state: ClientUIState, payload: TLoginDialogState)              : void;
    [MUTATION.SHOW_SIGNUP_DIALOG]                       (state: ClientUIState, payload: TSignupDialogState)             : void;
    [MUTATION.SHOW_PREFERENCES_DIALOG]                  (state: ClientUIState, show: boolean)                           : void;
    [MUTATION.SHOW_JOIN_TEAM_DIALOG]                    (state: ClientUIState, show: boolean)                           : void;
    [MUTATION.SHOW_COLOR_PICKER]                        (state: ClientUIState, show: boolean)                           : void;
    [MUTATION.SHOW_TIMESLOT_CONFIRM_DIALOG]             (state: ClientUIState, payload: TTimeslotConfirmDialogState)    : void;
    [MUTATION.SHOW_MAIN_MENU]                           (state: ClientUIState, show: boolean)                           : void;
    [MUTATION.SHOW_MAIN_MENU_INFO]                      (state: ClientUIState, show: boolean)                           : void;
    [MUTATION.SHOW_MAIN_MENU_ARCHIVE]                   (state: ClientUIState, show: boolean)                           : void;
    [MUTATION.SHOW_USER_MENU]                           (state: ClientUIState, show: boolean)                           : void;
    [MUTATION.SHOW_NOTIFICATIONS]                       (state: ClientUIState, show: boolean)                           : void;
    [MUTATION.SHOW_POPUP]                               (state: ClientUIState, payload: TPopupState)                    : void;
    [MUTATION.HIDE_POPUP]                               (state: ClientUIState, id: POPUPS)                              : void;
    [MUTATION.SHOW_POPUP_ONLY]                          (state: ClientUIState, payload: TPopupState)                    : void;
    [MUTATION.CLEAR_POPUPS]                             (state: ClientUIState)                                          : void;
    [MUTATION.WINDOW_SIZE]                              (state: ClientUIState, newSize: TWindowSize)                    : void;
    [MUTATION.DELETE_NOTIFICATION]                      (state: ClientUIState, notificationID: string)                  : void;
    [MUTATION.GOOGLE_MAPS_REQUEST_STATUS]               (state: ClientUIState, status: TActionState)                    : void;

    [MUTATION.SET_ADD_PLAYER_STATUS]                    (state: ClientUIState, status: TActionState)                    : void;
    [MUTATION.SET_PLAYERS]                              (state: ClientUIState, players: PlayerInterface[])              : void;
    [MUTATION.SET_FREE_AGENTS]                          (state: ClientUIState, players: FreeAgentInterface[])           : void;
    [MUTATION.SET_TIMESLOTS]                            (state: ClientUIState, timeslots: FilteredTimeslotData)         : void;
    [MUTATION.SET_ADD_ME_EMAIL_STATUS]                  (state: ClientUIState, status: TActionState)                    : void;
    [MUTATION.SET_EMAIL_REQUEST_STATUS]                 (state: ClientUIState, status: TActionState)                    : void;

    [MUTATION.SET_RESOURCE_LIST]                        (state: ClientUIState, list: string[])                          : void;
    [MUTATION.SET_RESOURCE_LIST_REQUEST_STATUS]         (state: ClientUIState, status: TActionState)                    : void;
    [MUTATION.SET_RESOURCE_REQUEST_STATUS]              (state: ClientUIState, payload: {resource: string, status: TActionState}): void;

    [MUTATION.SET_LEAGUE_DESCRIPTION]                   (state: ClientUIState, description: {short: string, long:string}): void;
    [MUTATION.SET_JOIN_TEAM_MESSAGE]                    (state: ClientUIState, message: string)                         : void;
    [MUTATION.SET_LEAGUE_DESCRIPTION_REQUEST_STATUS]    (state: ClientUIState, status: TActionState)                    : void;
    [MUTATION.SET_JOIN_TEAM_MESSAGE_REQUEST_STATUS]     (state: ClientUIState, status: TActionState)                    : void;

    [MUTATION.SET_BACKUPS]                              (state: ClientUIState, backups: BackupInterface[])              : void;
    [MUTATION.SET_BACKUP_REQUEST_STATUS]                (state: ClientUIState, status: TActionState)                    : void;
    [MUTATION.SET_ADD_BACKUP_REQUEST_STATUS]            (state: ClientUIState, status: TActionState)                    : void;
    [MUTATION.GET_BACKUP_REQUEST_STATUS]                (state: ClientUIState, status: TActionState)                    : void;
    [MUTATION.SET_REMOVE_BACKUP_REQUEST_STATUS]         (state: ClientUIState, status: TActionState)                    : void;
    [MUTATION.SET_RESTORE_BACKUP_REQUEST_STATUS]        (state: ClientUIState, status: TActionState)                    : void;
    
    [MUTATION.SET_ARCHIVES]                             (state: ClientUIState, archives: ArchiveInterface[])            : void;
    [MUTATION.SET_ARCHIVE_REQUEST_STATUS]               (state: ClientUIState, status: TActionState)                    : void;
    [MUTATION.SET_ADD_ARCHIVE_REQUEST_STATUS]           (state: ClientUIState, status: TActionState)                    : void;
    [MUTATION.SET_SEASON_VIEW]                          (state: ClientUIState, seasonName: string)                      : void;

    [MUTATION.SET_USERS]                                (state: ClientUIState, users: UserInterface[])                  : void;
    [MUTATION.SET_USER_REQUEST_STATUS]                  (state: ClientUIState, status: TActionState)                    : void;
    [MUTATION.SET_PENDING_USERS]                        (state: ClientUIState, sers: UserInterface[])                   : void;
    [MUTATION.SET_PENDING_USER_REQUEST_STATUS]          (state: ClientUIState, status: TActionState)                    : void;
    [MUTATION.SET_DBLOGS]                               (state: ClientUIState, logs: LogInterface[])                    : void;
    [MUTATION.SET_DBLOG_REQUEST_STATUS]                 (state: ClientUIState, status: TActionState)                    : void;

    [MUTATION.ADD_TMP_NOTIFICATION]                     (state: ClientUIState, content: string)                         : void;
    [MUTATION.SET_TIMESLOT_EMAIL_REQUEST_STATUS]        (state: ClientUIState, status: TActionState & {id: string})     : void;
};

export const mutations: MutationTree<ClientUIState> & TMutations = {
    // eslint-disable-next-line
    [MUTATION.RESET_STORE](state) {
        state = defaultSessionState();
    },
    [MUTATION.SET_APP_UIC](state, uic) {
        state.uic = {...uic};
    },
    [MUTATION.SET_APP_PJC](state, pjc) {
        state.pjc = {...pjc};
    },
    [MUTATION.SET_ASSET_STATUS](state, status) {
        state.assetsStatus = status;
    },
    [MUTATION.SET_LOGIN_STATUS](state, status) {
        state.loginRequestStatus = status;
    },
    [MUTATION.SET_SIGNUP_STATUS](state, status) {
        state.signupRequestStatus = status;
    },
    [MUTATION.SAVE_PREFERENCES_STATUS](state, status) {
        state.savePreferencesStatus = status;
    },
    [MUTATION.SET_PASSWORD_RESET_STATUS](state, status) {
        state.passwordResetStatus = status;
    },
    [MUTATION.SET_SESSION_INFO](state, sessInfo) {
        state.sessionInfo = { ...sessInfo };
    },
    [MUTATION.SET_STATE](state, newState) {
        Object.keys(state).forEach(k => {
           // eslint-disable-next-line
            (state as any)[k] = (newState as any)[k];
        });
    },
    [MUTATION.ADD_TO_FAVORITE_TEAM](state, teams) {
        let myTeamsCopy = state.myTeams.slice();
        myTeamsCopy.push(...teams.map(t => t.name));
        myTeamsCopy = [... new Set(myTeamsCopy)]; // ensure uniqueness
        state.myTeams = myTeamsCopy;
    },
    [MUTATION.REMOVE_FROM_FAVORITE_TEAM](state, teams) {
        const myTeamsCopy = state.myTeams.slice();
        teams.forEach(t => {
            const idx = myTeamsCopy.indexOf(t.name);
            if(idx > -1) myTeamsCopy.splice(idx, 1);
        });
        state.myTeams = myTeamsCopy;
    },
    [MUTATION.SET_ADD_TO_FAVORITE_TEAM_STATUS](state, status) {
        state.addFavoriteTeamRequestStatus = status;
    },
    [MUTATION.SET_ADD_MATCH_STATUS](state, status) {
        state.addMatchRequestStatus = status;
    },
    [MUTATION.SET_ADD_MATCH_REPORT](state, report) {
        state.addMatchReport = {...report};
    },
    [MUTATION.SET_REMOVE_MATCH_STATUS](state, reqStatus) {
        const {status, msg, id} = reqStatus;
        state.removeMatchRequestStatus[id] = {status, msg};
    },
    [MUTATION.SET_ADD_TIMESLOT_STATUS](state, status) {
        state.addTimeslotRequestStatus = status;
    },
    [MUTATION.SET_REMOVE_TIMESLOT_STATUS](state, reqStatus) {
        const {status, msg, id} = reqStatus;
        state.removeTimeslotRequestStatus[id] = {status, msg};
    },
    [MUTATION.SET_TIMESLOTS](state, timeslots) {
        state.timeslots = timeslots;
    },
    [MUTATION.SET_TIMESLOT_RESERVATION_REQUEST_STATUS](state, reqStatus) {
        const {status, msg, id} = reqStatus;
        state.timeslotReservationRequestStatus[id] = {status, msg};
    },
    [MUTATION.SET_EDIT_MATCH_STATUS](state, reqStatus) {
        const {status, msg, id} = reqStatus;
        state.editMatchRequestStatus[id] = {status, msg};
    },
    [MUTATION.SET_EDIT_SERIES_STATUS](state, reqStatus) {
        const {status, msg, id} = reqStatus;
        state.editSeriesRequestStatus[id] = {status, msg};
    },
    [MUTATION.SET_SPONSORS_REQUEST_STATUS](state, status) {
        state.sponsorsRequestStatus = status;
    },
    [MUTATION.EDIT_SPONSOR_REQUEST_STATUS](state, reqStatus) {
        const {status, msg, id} = reqStatus;
        state.editSponsorRequestStatus[id] = {status, msg};
    },
    [MUTATION.REMOVE_SPONSOR_REQUEST_STATUS](state, reqStatus) {
        const {status, msg, id} = reqStatus;
        state.removeSponsorRequestStatus[id] = {status, msg};
    },
    [MUTATION.ADD_SPONSOR_REQUEST_STATUS](state, reqStatus) {
        state.addSponsorRequestStatus = {...reqStatus};
    },
    [MUTATION.SET_SPONSOR](state, sponsor) {
        const sponsorsCpy = state.sponsors.slice();
        const editSponsorIx = sponsorsCpy.findIndex(t => t._id === sponsor._id);
        sponsorsCpy.splice(editSponsorIx, 1, {...sponsor});
        state.sponsors = sponsorsCpy;
    },
    [MUTATION.ADD_SPONSOR](state, sponsor) {
        const sponsorsCpy = state.sponsors.slice();
        sponsorsCpy.push(sponsor);
        state.sponsors = sponsorsCpy;
    },
    [MUTATION.REMOVE_SPONSOR](state, id) {
        const sponsorsCpy = state.sponsors.slice();
        const editSponsorIx = sponsorsCpy.findIndex(t => t._id === id);
        sponsorsCpy.splice(editSponsorIx, 1);
        state.sponsors = sponsorsCpy;
    },
    
    [MUTATION.SET_TEAMS_REQUEST_STATUS](state, status) {
        state.teamsRequestStatus = status;
    },
    [MUTATION.EDIT_TEAM_REQUEST_STATUS](state, reqStatus) {
        const {status, msg, id} = reqStatus;
        state.editTeamRequestStatus[id] = {status, msg};
    },
    [MUTATION.REMOVE_TEAM_REQUEST_STATUS](state, reqStatus) {
        const {status, msg, id} = reqStatus;
        state.removeTeamRequestStatus[id] = {status, msg};
    },
    [MUTATION.ADD_TEAM_REQUEST_STATUS](state, reqStatus) {
        state.addTeamRequestStatus = {...reqStatus};
    },
    [MUTATION.SET_TEAM](state, team) {
        const teamsCpy = state.teams.slice();
        const editTeamIx = teamsCpy.findIndex(t => t._id === team._id);
        teamsCpy.splice(editTeamIx, 1, {...team});
        state.teams = teamsCpy;
    },
    [MUTATION.ADD_TEAM](state, team) {
        const teamsCpy = state.teams.slice();
        teamsCpy.push(team);
        state.teams = teamsCpy;
    },
    [MUTATION.REMOVE_TEAM](state, id) {
        const teamsCpy = state.teams.slice();
        const editTeamIx = teamsCpy.findIndex(t => t._id === id);
        teamsCpy.splice(editTeamIx, 1);
        state.teams = teamsCpy;
    },
    [MUTATION.SET_ADD_FIELD_STATUS](state, status) {
        state.addFieldRequestStatus = status;
    },
    [MUTATION.SET_ADD_PLAYER_STATUS](state, status) {
        state.addPlayerStatus = status;
    },
    [MUTATION.SET_PLAYERS](state, players) {
        state.players = players.slice();
    },
    [MUTATION.SET_FREE_AGENTS](state, freeAgents) {
        state.freeAgents = freeAgents.slice();
    },
    [MUTATION.SET_REMOVE_FIELD_STATUS](state, reqStatus) {
        const {status, msg, id} = reqStatus;
        state.removeFieldRequestStatus[id] = {status, msg};
    },
    [MUTATION.SET_REMOVE_PLAYER_STATUS](state, status) {
        state.removePlayerStatus = status;
    },
    [MUTATION.SET_ADD_ME_EMAIL_STATUS](state, status) {
        state.addMeEmailStatus = status;
    },
    [MUTATION.SET_EMAIL_REQUEST_STATUS](state, status) {
        state.emailRequestStatus = status;
    },
    [MUTATION.SET_SCHEDULE](state, schedule) {
        state.schedule = schedule.slice();
    },
    [MUTATION.SET_SERIES_MATCHUP](state, series) {
        state.series = {...series};
    },
    [MUTATION.SET_SCHEDULE_REQUEST_STATUS](state, status) {
        state.scheduleRequestStatus = {...status};
    },
    [MUTATION.SET_SERIES_REQUEST_STATUS](state, status) {
        state.seriesRequestStatus = {...status};
    },
    [MUTATION.SET_TIMESLOTS_REQUEST_STATUS](state, status) {
        state.timeslotsRequestStatus = {...status};
    },
    [MUTATION.SET_TIMESLOT_EMAIL_REQUEST_STATUS](state, reqStatus) {
        const {status, msg, id} = reqStatus;
        state.timeslotReservationRequestStatus[id] = {status, msg};
    },
    [MUTATION.SET_SPONSORS](state, sponsors) {
        state.sponsors = sponsors.slice();
    },
    [MUTATION.SET_TEAMS](state, teams) {
        state.teams = teams.slice();
    },
    [MUTATION.SET_CAPTAIN_TEAMS](state, teams) {
        state.sessionInfo.perm.teams = teams.map(t => t.name);
    },
    [MUTATION.SET_FAVORITE_TEAMS](state, teams) {
        state.myTeams = teams.map(t => t.name);
    },
    [MUTATION.SET_FIELDS](state, fields) {
        state.fields = fields.slice();
    },
    [MUTATION.SET_FIELDS_REQUEST_STATUS](state, status) {
        state.fieldsRequestStatus = {...status};
    },
    [MUTATION.SET_SEASON_RANKINGS_REQUEST_STATUS](state, status) {
        state.seasonRankingRequestStatus = {...status};
    },
    [MUTATION.SET_SERIES_RANKINGS_REQUEST_STATUS](state, status) {
        state.seriesRankingRequestStatus = {...status};
    },
    [MUTATION.SET_PLAYERS_REQUEST_STATUS](state, status) {
        state.playersRequestStatus = {...status};
    },
    [MUTATION.SET_SEASON_RANKINGS](state, ranking) {
        state.seasonRanking = { ...ranking };
    },
    [MUTATION.SET_SERIES_RANKINGS](state, ranking) {
        state.seriesRanking = { ...ranking };
    },
    [MUTATION.SET_TEAM_NAMES_FILTERS](state, payload) {
        if(!state.filterSets[payload.filterSet]) state.filterSets[payload.filterSet] = emptyFilterDef();
        state.filterSets[payload.filterSet].teamNamesFilter = payload.teamNames.slice();
    },
    [MUTATION.SET_FIELD_NAMES_FILTERS](state, payload) {
        if(!state.filterSets[payload.filterSet]) state.filterSets[payload.filterSet] = emptyFilterDef();
        state.filterSets[payload.filterSet].fieldNamesFilter = payload.fieldNames.slice();
    },
    [MUTATION.SET_DIVISION_FILTERS](state, payload){
        if(!state.filterSets[payload.filterSet]) state.filterSets[payload.filterSet] = emptyFilterDef();
        state.filterSets[payload.filterSet].divisionsFilter = payload.divisions.slice();
    },
    [MUTATION.SET_CANCELLED_FILTER](state, payload){
        if(!state.filterSets[payload.filterSet]) state.filterSets[payload.filterSet] = emptyFilterDef();
        state.filterSets[payload.filterSet].cancelledFilter = payload.cancelled;
    },
    [MUTATION.SET_ROUND_FILTER](state, payload){
        if(!state.filterSets[payload.filterSet]) state.filterSets[payload.filterSet] = emptyFilterDef();
        state.filterSets[payload.filterSet].roundFilter = payload.rounds? payload.rounds.slice(): null;
    },
    [MUTATION.RESET_SCHEDULE_FILTERS](state, filterSetName){
        state.filterSets[filterSetName] = emptyFilterDef();
    },
    [MUTATION.SET_DATE_RANGE_FILTERS](state, payload) {
        state.filterSets[payload.filterSet].dateRangeFilter = payload.dateRange.slice();
    },
    [MUTATION.SET_SELECTED_MATCH_IDX](state, matchIndex) {
        state.selectedMatchIx = matchIndex;
    },

    [MUTATION.SHOW_ADD_SPONSOR_DIALOG](state, payload) {
        state.addSponsorDialogState = {...payload};
    },
    [MUTATION.UPDATE_ADD_SPONSOR_DIALOG](state, payload) {
        state.addSponsorDialogState.sponsor = {...payload};
    },

    [MUTATION.SHOW_ADD_TEAM_DIALOG](state, payload) {
        state.addTeamDialogState = {...payload};
    },
    [MUTATION.UPDATE_ADD_TEAM_DIALOG](state, payload) {
        state.addTeamDialogState.team = {...payload};
    },

    [MUTATION.SHOW_LOGIN_DIALOG](state, payload) {
        state.loginDialogState = {...payload};
    },
    [MUTATION.SHOW_SIGNUP_DIALOG](state, payload) {
        state.signupDialogState = {...payload};
    },
    [MUTATION.SHOW_PREFERENCES_DIALOG](state, show) {
        state.preferencesDialogVisible = show;
    },
    [MUTATION.SHOW_JOIN_TEAM_DIALOG](state, show) {
        state.joinTeamDialogVisible = show;
    },
    [MUTATION.SHOW_COLOR_PICKER](state, show){
        state.colorPickerVisible = show;
    },
    [MUTATION.SHOW_TIMESLOT_CONFIRM_DIALOG](state, payload) {
        state.timeslotConfirmDialogState = {...payload};
    },
    [MUTATION.SHOW_MAIN_MENU](state, show){
        state.mainMenuVisible = show;
    },
    [MUTATION.SHOW_MAIN_MENU_INFO](state, show){
        state.mainMenuInfoVisible = show;
    },
    [MUTATION.SHOW_MAIN_MENU_ARCHIVE](state, show){
        state.mainMenuArchiveVisible = show;
    },
    [MUTATION.SHOW_USER_MENU](state, show){
        state.userMenuVisible = show;
    },
    [MUTATION.SHOW_NOTIFICATIONS](state, show){
        state.notificationsVisible = show;
    },
    [MUTATION.SHOW_SCHEDULE_FILTERS](state, show){
        state.scheduleFiltersDialogVisible = show;
    },
    [MUTATION.SET_ACTIVE_FILTER_SET](state, activeFilterSetName){
        state.activeFilterSet = activeFilterSetName;
    },
    [MUTATION.SHOW_POPUP](state, payload){
        const lPopups = state.popups.slice();
        lPopups.push(payload);
        state.popups = lPopups;
    },
    [MUTATION.HIDE_POPUP](state, popup){
        state.popups = state.popups.filter(p => p.id !== popup);
    },
    [MUTATION.SHOW_POPUP_ONLY](state, payload){
        state.popups = [payload];
    },
    [MUTATION.CLEAR_POPUPS](state){
        state.popups = [];
    },
    [MUTATION.WINDOW_SIZE](state, newSize){
        state.windowSize = {...newSize};
    },
    [MUTATION.DELETE_NOTIFICATION](state, notificationID) {
        const newNotifications = state.sessionInfo.notifications.slice();
        const idx = newNotifications.findIndex(not => {
            return not._id === notificationID
        })
        if (idx >= 0) {
            newNotifications.splice(idx, 1);
        }
        state.sessionInfo.notifications = newNotifications;
    },
    [MUTATION.GOOGLE_MAPS_REQUEST_STATUS](state, status){
        state.googleMapsRequestStatus = status;
    },

    [MUTATION.SET_RESOURCE_LIST] (state, list) {
        state.resourceList = list.slice();
    },
    [MUTATION.SET_RESOURCE_LIST_REQUEST_STATUS] (state, status) {
        state.resourceListRequestStatus = status
    },
    [MUTATION.SET_RESOURCE_REQUEST_STATUS] (state, payload) {
        state.resourceRequestStatus[payload.resource] = payload.status;
    },

    [MUTATION.SET_LEAGUE_DESCRIPTION] (state, descr) {
        state.leagueDescription = {...descr};
    },
    [MUTATION.SET_LEAGUE_DESCRIPTION_REQUEST_STATUS] (state, status) {
        state.leagueDescriptionRequestStatus = status
    },
    [MUTATION.SET_JOIN_TEAM_MESSAGE] (state, msg) {
        state.joinTeamMessage = msg;
    },
    [MUTATION.SET_JOIN_TEAM_MESSAGE_REQUEST_STATUS] (state, status) {
        state.joinTeamMessageRequestStatus = status
    },

    [MUTATION.SET_ARCHIVES] (state, archives) {
        state.archives = archives.slice();
    },
    [MUTATION.SET_ARCHIVE_REQUEST_STATUS] (state, status) {
        state.archiveRequestStatus = status
    },
    [MUTATION.SET_ADD_ARCHIVE_REQUEST_STATUS] (state, status) {
        state.addArchiveRequestStatus = status
    },
    [MUTATION.SET_SEASON_VIEW] (state, seasonName) {
        state.currentSeasonView = seasonName;
    },

    [MUTATION.SET_BACKUPS] (state, backups) {
        state.backups = backups.slice();
    },
    [MUTATION.SET_BACKUP_REQUEST_STATUS] (state, status) {
        state.backupRequestStatus = status
    },
    [MUTATION.SET_ADD_BACKUP_REQUEST_STATUS] (state, status) {
        state.addBackupRequestStatus = status
    },
    [MUTATION.SET_REMOVE_BACKUP_REQUEST_STATUS] (state, status) {
        state.removeBackupRequestStatus = status
    },
    [MUTATION.SET_RESTORE_BACKUP_REQUEST_STATUS] (state, status) {
        state.restoreBackupRequestStatus = status
    },
    [MUTATION.GET_BACKUP_REQUEST_STATUS] (state, status) {
        state.getBackupRequestStatus = status
    },

    [MUTATION.SET_USERS] (state, users) {
        state.users = users.slice();
    },
    [MUTATION.SET_USER_REQUEST_STATUS] (state, status) {
        state.userRequestStatus = status
    },
    [MUTATION.SET_PENDING_USERS] (state, users) {
        state.pendingUsers = users.slice();
    },
    [MUTATION.SET_PENDING_USER_REQUEST_STATUS] (state, status) {
        state.pendingUserRequestStatus = status
    },
    [MUTATION.SET_DBLOGS] (state, logs) {
        state.databaseLogs = logs.slice();
    },
    [MUTATION.SET_DBLOG_REQUEST_STATUS] (state, status) {
        state.databaseLogRequestStatus = status
    },
    [MUTATION.ADD_TMP_NOTIFICATION] (state, content) {
        const notificationsCpy = state.sessionInfo.notifications.slice();
        notificationsCpy.unshift({
            _id: `${LOCAL_NOTIFICATION_PFX}${Date.now()}`,
            description: content
        });
        state.sessionInfo.notifications = notificationsCpy;
    }
};

export function closeAllDialogsAndPopups(store: TStoreContext) {
    store.commit(MUTATION.SHOW_ADD_TEAM_DIALOG, {
        show: false, 
        team: emptyTeamDef()
    });
    store.commit(MUTATION.SHOW_ADD_SPONSOR_DIALOG, {
        show: false,
        sponsor: emptySponsorDef()
    });
    store.commit(MUTATION.SHOW_COLOR_PICKER, false);
    store.commit(MUTATION.SHOW_MAIN_MENU, false);
    store.commit(MUTATION.SHOW_MAIN_MENU_INFO, false);
    store.commit(MUTATION.SHOW_MAIN_MENU_ARCHIVE, false);
    store.commit(MUTATION.SHOW_USER_MENU, false);
    store.commit(MUTATION.SHOW_LOGIN_DIALOG, {
        show: false, username: ''
    });
    store.commit(MUTATION.SHOW_SIGNUP_DIALOG, {
        show: false, username: '', name: '', teams: [], teamCaptain: false
    });
    store.commit(MUTATION.SHOW_PREFERENCES_DIALOG, false);
    store.commit(MUTATION.SHOW_NOTIFICATIONS, false);
    store.commit(MUTATION.SHOW_SCHEDULE_FILTERS, false);
    store.commit(MUTATION.SHOW_TIMESLOT_CONFIRM_DIALOG, {
        show: false, match: emptyMatchDef(), timeslot: emptyTimeslotDef()
    });
    store.commit(MUTATION.CLEAR_POPUPS, undefined);
}

export function anyModalDialogVisible(store: TStoreContext) {
    return (
        store.state.loginDialogState.show 
        || store.state.preferencesDialogVisible
        || store.state.addTeamDialogState.show
        || store.state.addSponsorDialogState.show
        || store.state.joinTeamDialogVisible
        || store.state.signupDialogState.show
        || store.state.timeslotConfirmDialogState.show
    );
}