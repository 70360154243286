import { toTitleCase } from "./utils";

export const RESOURCE_TEMPLATE = '_template_';

export const RESOURCE_SECTION =
`<div class="section">
    <h3 class="title"><span class="title--text" contenteditable="true">Nouvelle Section</span></h3>
    <div class="content" contenteditable="true">
        <p><b>1.1</b> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris pulvinar viverra quam, et tempus justo consectetur non. Maecenas sodales dui eu neque convallis, facilisis congue augue volutpat.</p>
        <p><b>1.2</b> Quisque rhoncus dolor at orci aliquam fringilla ut blandit mauris. Suspendisse potenti. Sed elementum, sem sit amet varius mollis, odio nisi mattis elit, ac dignissim elit risus non dui :</p>
        <p style="margin-left:40px"><b>a.</b> Cras quis dictum purus;</p>
        <p style="margin-left:40px"><b>b.</b> Interdum et malesuada fames ac ante ipsum primis in faucibus. Mauris convallis tellus sed porta auctor;</p>
        <p style="margin-left:40px"><b>c.</b> Proin eu nisi ut lacus ultricies cursus sed consectetur neque.</p>
        <p><b>1.3</b> Sed tincidunt, libero non placerat lobortis, velit odio vulputate ligula, quis sagittis quam massa auctor nisi.</p>
    </div>
</div>`;

export function resourceSaveName(res: string) {
    if (res === 'Nouveau Document') res = RESOURCE_TEMPLATE;
    return res.replaceAll("  ", " ").replaceAll('\n', '').toLowerCase().split(" ").join("_");
}

export function resourceDisplayName(res: string) {
    if(res === RESOURCE_TEMPLATE) res = 'Nouveau Document';
    return toTitleCase(res.split("_").join(" "));
}

export async function toDataUrl(img: Blob): Promise<string | null>{
    let dataUrl = null;
    const loadPromise = new Promise((resolve: (img: string) => void, reject) => {
        const reader = new FileReader()
        reader.onloadend = () => resolve(reader.result as string)
        reader.onerror = reject
        reader.readAsDataURL(img);
    });
    try {
        dataUrl = await loadPromise;
    }
    catch(err){
        console.warn(err);
    }

    return dataUrl;
}

export default {}