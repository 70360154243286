
import { defineComponent } from 'vue';

interface FileReaderEventTarget extends EventTarget {
    result:string
}

interface FileReaderEvent extends Event {
    target: FileReaderEventTarget;
    getMessage():string;
}

interface HTMLInputEvent extends Event {
    target: HTMLInputElement & EventTarget
}

export default defineComponent({
    name: 'FileUpload',
    data() {
        return {
            hasFile: false
        }
    },
    props: {
        // The list of extensions WITHOUT the '.' character that is accepted
        // By this file reader
        ext: {
            type: Array as () => string[],
            required: false,
            default: ['*'] as string[]
        }
    },
    computed: {
        acceptedFileExt(): string {
            return this.ext.map(i => '.' + i).join(',');
        }
    },
    methods: {
        // This forwards the click on the button in the UI to the input
        // event that opens the browser file explorer
        forwardToInput() {
            (this.$refs as any).fileInput.click();
        },
        removeFile() {
            this.hasFile = false;
            (this.$refs.fileInput as HTMLInputElement).value = "";
            this.$emit("file-load", {fileStream: null, fileName: null});
            this.$emit("file-upload", {fileBlob: null, fileName: null});
        },
        handleChange(ev: Event) {
            const inputEv = ev as HTMLInputEvent;
            this.loadFromFile(inputEv);
            this.uploadFromFile(inputEv);
        },
        loadFromFile: function(ev: HTMLInputEvent) {
            if(!ev.target.files) return;
            const file: File = ev.target.files[0];

                // We want to emit the file-load event with a payload being the
                // contents of the file as a stream. To do so we use a
                // FileReader.

            const reader = new FileReader();

                // We attach an onload event to the reader so that when we
                // call reader.readAsText() the data string is emitted.
                // This is an asynchronous operation which is why we need to
                // attach to the onload event.

            //@ts-ignore
            reader.onload = (frev: FileReaderEvent) => {
                this.$emit("file-load", {fileStream: frev.target.result, fileName: file.name});
                this.hasFile = true;
            }
            reader.readAsText(file);
        },
        uploadFromFile: function(ev: HTMLInputEvent) {
            if(!ev.target.files) return;
            const file: File = ev.target.files[0];

                // We want to emit the file-load event with a payload being the
                // contents of the file as a stream. To do so we use a
                // FileReader.

            this.$emit("file-upload", {fileBlob: file, fileName: file.name});
            this.hasFile = true;
        }
    }
});
