
import { defineComponent } from 'vue';
import Dialog from '@/components/lib/Dialog.vue';
import TheDateFilter from '@/components/TheDateFilter.vue';
import TheFieldFilter from '@/components/TheFieldFilter.vue';
import TheTeamFilter from '@/components/TheTeamFilter.vue';
import TheDivisionFilter from '@/components/TheDivisionFilter.vue';
import { store } from '@/store/store';
import { MUTATION } from '@/store/mutations';

export default defineComponent({
    name: 'TheScheduleFilters',
    data() {
        return {
            collapsed: [true, true, true]
        }
    },
    props: {
        filterSetName: {
            type: String,
            required: true
        }
    },
    components: { 
        Dialog, TheDateFilter, TheFieldFilter, TheTeamFilter, TheDivisionFilter
    },
    computed: {
        scheduleFilterDialogVisible(): boolean {
            return store.state.scheduleFiltersDialogVisible;
        },
    },
    methods: {
        closeDialog() {
            store.commit(MUTATION.SHOW_SCHEDULE_FILTERS, false);
            store.commit(MUTATION.SET_ACTIVE_FILTER_SET, '__none__');
        },
        collapseOthers(srcIdx: number) {
            this.collapsed = this.collapsed.map((c, ix) => {
                if (ix === srcIdx) return !c;
                else return true;
            });
        }
    }
});
