import { CURRENT_SEASON, NO_POOLS, STATE, USER_ROLES, USER_ROLE_DEV } from '@/constants';
import { POPUPS } from '@/constants';
import { FieldInterface } from '@/services/fields';
import { ScheduleInterface, SeriesInterface } from '@/services/series';
import { SponsorInterface, SPONSOR_TYPES } from '@/services/sponsors';
import { TeamStats, defaultStats } from '@/services/stats';
import { TFilteredPlayersByTeam, TeamInterface, TFilteredTeamsByDivision } from '@/services/teams';
import { decodeSeriesTeam, decodeTeamDivision, defaultTeamDef, divisionLongDisplayName, encodeSeriesTeam, encodeTeamDivision, setIntersection } from '@/utils';
import { GetterTree } from 'vuex';
import { ClientUIState, emptyFilterDef, TFilterDef, TPoolDropwdownState } from './state';

export type TGetters = {
    appReady:(state: ClientUIState) => boolean,
    isLoggedIn:(state: ClientUIState) => boolean,
    isArchiveMode:(state: ClientUIState) => boolean,
    userName:(state: ClientUIState) => string,
    canEditFields:(
        state: ClientUIState,
        getters: TGetterOutput
    ) => boolean,
    canUpdateTeam:(
        state: ClientUIState,
        getters: TGetterOutput
    ) => (teamName: string) => boolean,
    isBasic:(state: ClientUIState) => boolean,
    isCaptain:(state: ClientUIState, getters: TGetterOutput) => boolean,
    isTeamCaptain:(
        state: ClientUIState,
        getters: TGetterOutput
    ) => (teamName: string) => boolean,
    teamsICaptain:(
        state: ClientUIState,
        getters: TGetterOutput
    ) => TeamInterface[],
    isAdmin: (state: ClientUIState, getters: TGetterOutput) => boolean,
    isDev: (state: ClientUIState, getters: TGetterOutput) => boolean,
    myTeams:(state: ClientUIState, getters: TGetterOutput) => TeamInterface[],
    teamsIFavorite: (
        state: ClientUIState,
        getters: TGetterOutput
    ) => TeamInterface[],
    teamInfo:(state: ClientUIState) => (teamName: string) => TeamInterface,
    teamNames:(state: ClientUIState) => string[],
    teamStats:(
        state: ClientUIState,
        getters: TGetterOutput
    ) => (teamName: string) => TeamStats,
    emptySchedule:(state: ClientUIState) => boolean
    seriesRankingProvisional: (
        state: ClientUIState
    ) => {[fullDiv: string]: boolean},
    seasonRankingProvisional: (
        state: ClientUIState,
        getters: TGetterOutput
    ) => {[fullDiv: string]: boolean},
    teamRank:(
        state: ClientUIState,
        getters: TGetterOutput
    ) => (teamName: string) => number,
    sortedTeams:(state: ClientUIState) => string[],

    teamFromDivisionDef: (
        state:ClientUIState,
        getters: TGetterOutput
    ) => (division: string, pool: string, rank: number) => TeamInterface | undefined,
    teamsInDivision:(state:ClientUIState) => (division: string, pool: string) => number,
    teamsPerDivision:(state: ClientUIState) => TFilteredTeamsByDivision,
    fieldInfo:(state: ClientUIState) => (fieldName: string) => FieldInterface,
    fieldNames:(state: ClientUIState) => string[],
    playersPerTeam:(state: ClientUIState) => TFilteredPlayersByTeam,
    sortedFields:(state: ClientUIState, getters: TGetterOutput) => string[],
    fieldExternalLinkUrl: (state: ClientUIState) => string,
    fieldExternalLinkDisplayName: (state: ClientUIState) => string,
    fieldExternalLinkVisible: (state: ClientUIState) => boolean,
    sortedSponsors:(state: ClientUIState) => SponsorInterface[],
    sortedSeasonCategories:(state: ClientUIState) => string[],
    sortedCategories:(state: ClientUIState) => string[],
    sortedDivisions:(state: ClientUIState) => string[],
    poolsInDivision:(state: ClientUIState, getters: TGetterOutput) => (division: string) => string[],
    fullSchedule:(state: ClientUIState) => ScheduleInterface[],
    filteredSchedule:(state: ClientUIState, getters: TGetterOutput) => (filterSetName: string) => ScheduleInterface[],
    filterSet:(state: ClientUIState) => (filterSetName: string) => TFilterDef,
    hasScheduleFilters:(state: ClientUIState, getters: TGetterOutput) => (filterSetName: string) => boolean,
    hasFieldFilters:(state: ClientUIState, getters: TGetterOutput) => (filterSetName: string) => boolean,
    hasDateFilters:(state: ClientUIState, getters: TGetterOutput) => (filterSetName: string) => boolean,
    hasTeamFilters:(state: ClientUIState, getters: TGetterOutput) => (filterSetName: string) => boolean,
    hasDivisionFilters:(state: ClientUIState, getters: TGetterOutput) => (filterSetName: string) => boolean,
    hasRoundFilters:(state: ClientUIState, getters: TGetterOutput) => (filterSetName: string) => boolean,
    isVisiblePopup(state: ClientUIState): (popup: POPUPS) => boolean,
    popupTarget(state: ClientUIState): (popup: POPUPS) => HTMLElement | null,
    teamSelectionDropdownID: (state: ClientUIState) => POPUPS,
    poolDropdownDivision:(state: ClientUIState) => string,

    backupListReady:(state: ClientUIState) => boolean
    archiveListReady:(state: ClientUIState) => boolean

    facebookLink:(state: ClientUIState) => string
    instagramLink:(state: ClientUIState) => string
    hasFacebook:(state: ClientUIState) => boolean
    hasInstagram:(state: ClientUIState) => boolean
    agentLibreLink:(state: ClientUIState) => string
    hasAgentLibreLink:(state: ClientUIState) => boolean

    // UIC //

    joinTeamButtonVisible:(state: ClientUIState) => boolean
    signUpButtonVisible:(state: ClientUIState) => boolean
    joinYearVisible:(state: ClientUIState) => boolean
}

type TGetterOutput = {
    [K in keyof TGetters]: ReturnType<TGetters[K]>;
  }

export const getters: GetterTree<ClientUIState, ClientUIState> & TGetters = {
    appReady(state) {
        return state.assetsStatus.status === STATE.DONE
    },
    isLoggedIn(state) {
        return state.sessionInfo.loggedIn;
    },
    isArchiveMode(state) {
        return state.currentSeasonView !== CURRENT_SEASON;
    },
    userName(state) {
        return state.sessionInfo.name;
    },
    canUpdateTeam: (state, getters) => (teamName) => {
        return getters.isTeamCaptain(teamName) || getters.isAdmin;
    },
    canEditFields: (state, getters) => {
        return getters.isAdmin;
    },
    isBasic(state) {
        return state.sessionInfo.perm.role == USER_ROLES.BASIC;
    },
    isCaptain(state, getters) {
        return state.sessionInfo.perm.role == USER_ROLES.CAPTAIN && !getters.isArchiveMode;
    },
    isTeamCaptain:(state, getters) => (teamName) => {
        return (
            (getters.isCaptain || getters.isAdmin)
            && state.sessionInfo.perm.teams.indexOf(teamName) > -1
        );
    },
    teamsICaptain(state, getters) {
        if(!getters.isCaptain) return [];

        const capt = state.sessionInfo.perm.teams.map(name => getters.teamInfo(name));
        return capt.filter(t => getters.isTeamCaptain(t.name));
    },
    isAdmin(state, getters) {
        return (
            !getters.isArchiveMode
            && (state.sessionInfo.perm.role === USER_ROLES.ADMIN || getters.isDev)
        );
    },
    isDev(state, getters) {
        return state.sessionInfo.perm.role === USER_ROLE_DEV && !getters.isArchiveMode;
    },
    teamNames(state) {
        return state.teams.map(team => team.name);
    },
    myTeams(state, getters) {
        let teams = [] as string[];
        if(!getters.isArchiveMode) {
            teams = [...state.sessionInfo.perm.teams, ...state.myTeams];
        }
        return state.teams.filter(t => teams.indexOf(t.name) > -1); // ensure team exists
    },
    teamsIFavorite(state, getters) {
        let favTeams = state.sessionInfo.loggedIn?
            state.sessionInfo.perm.teams.slice():
            state.myTeams.slice();
        if(state.sessionInfo.perm.role !== USER_ROLES.BASIC || getters.isArchiveMode) {
            // TODO admins/captains cannot have favorite teams for now!
            //      Add a property in backend to support this feature
            favTeams = [];
        }
        return favTeams.map(name => getters.teamInfo(name)).filter(n => n !== undefined);
    },
    teamInfo:(state) => (teamName) => {
        const teamsInfo = {} as {[teamName: string]: TeamInterface};
        state.teams.forEach(team => {
            teamsInfo[team.name] = team;
        });
        return teamsInfo[teamName] ?? {...defaultTeamDef(), name: teamName};
    },
    emptySchedule:(state) => {
        return state.schedule.length === 0
    },
    seriesRankingProvisional: (state) => {
        const divisions = Object.keys(state.series);
        const isFinal = {} as {[div: string]: boolean};
        divisions.forEach(div => {
            isFinal[div] = state.series[div].every(m => {
                return m.result === undefined || m.pendingApproval !== undefined;
            })
        });
        return isFinal;
    },
    seasonRankingProvisional: (state, getters) => {
        const isFinal = {} as {[div: string]: boolean};
        const teamMap = {} as {[teamName: string]: TeamInterface}; // caching
        state.schedule.forEach(m => {
            const homeTeam = teamMap[m.homeTeam] ?? getters.teamInfo(m.homeTeam);
            const awayTeam = teamMap[m.awayTeam] ?? getters.teamInfo(m.awayTeam);
            if(!teamMap[m.homeTeam]) teamMap[homeTeam.name] = homeTeam;
            if(!teamMap[m.awayTeam]) teamMap[awayTeam.name] = awayTeam;

            const matchFullDivHome = encodeTeamDivision({
                division: homeTeam.division, pool: homeTeam.pool
            });
            const matchDivHome = encodeTeamDivision({
                division: homeTeam.division, pool: ''
            });
            const matchFullDivAway = encodeTeamDivision({
                division: awayTeam.division, pool: awayTeam.pool
            });
            const matchDivAway = encodeTeamDivision({
                division: awayTeam.division, pool: ''
            });
            if(m.result === undefined || m.pendingApproval !== undefined) {
                isFinal[matchFullDivHome]   = true;
                isFinal[matchFullDivAway]   = true;
                isFinal[matchDivHome]       = true;
                isFinal[matchDivAway]       = true;
            }
        });
        return isFinal;
    },
    teamStats: (state, getters) => (teamName) => {
        const div = encodeTeamDivision(getters.teamInfo(teamName));
        const stats = state.seasonRanking[div]?.filter(r => r.team === teamName);
        if(stats && stats.length) return stats[0];
        else return defaultStats(teamName);
    },
    teamRank: (state, getters) => (teamName) => {
        const div = encodeTeamDivision(getters.teamInfo(teamName));
        const stats = state.seasonRanking[div]?.map(r => r.team);
        if(!stats || !stats.length) return state.teams.length;
        else {
            let rank = stats.indexOf(teamName);
            return rank > -1? ++rank: rank; // increase by one for cpu -> human
        }
    },
    teamFromDivisionDef: (state, getters) => (division: string, pool: string, rank: number) => {
        const div = encodeTeamDivision({division, pool});
        if(state.seasonRanking[div]) {
            return getters.teamInfo(state.seasonRanking[div][rank - 1].team);
        }
        else if(state.seriesRanking[div] && state.seriesRanking[div][rank - 1]) {
            const teamSeriesDef = decodeSeriesTeam(state.seriesRanking[div][rank - 1].team);
            return getters.teamFromDivisionDef(teamSeriesDef.division, teamSeriesDef.pool, teamSeriesDef.rank);
        }
        else return undefined;
    },
    teamsInDivision: (state) => (division: string, pool: string) => {
        const seasonNum = state.teams.filter(t => t.pool === pool && t.division === division);
        if (seasonNum.length) return seasonNum.length;

        const seriesMatch = state.series[division]?.filter(s => s.pool === pool) ?? [];
        const seriesTeams = {} as {[teamName: string]: boolean};
        seriesMatch.forEach(s => {
            seriesTeams[encodeSeriesTeam({division: s.homeDivision, pool: s.homePool, rank: s.homeRank})] = true;
            seriesTeams[encodeSeriesTeam({division: s.awayDivision, pool: s.awayPool, rank: s.awayRank})] = true;
        })
        return Object.keys(seriesTeams).length;
    },
    sortedTeams(state) {
        return state.teams
            .map(team => team.name)
            .sort((a, b) => (a > b ? 1 : -1));
    },
    teamsPerDivision(state) {
        const divisionMap = {} as TFilteredTeamsByDivision;
        state.teams.forEach(team => {
            const encodedDiv = encodeTeamDivision(team);
            if(divisionMap[encodedDiv]) divisionMap[encodedDiv].push(team);
            else divisionMap[encodedDiv] = [team];
        })
        return divisionMap;
    },
    sortedCategories(state) {
        const divisions = [
            ...state.teams
                .map(team => encodeTeamDivision(team))
                .sort((a, b) => (a > b ? 1 : -1)),
            ...Object.keys(state.series)
        ]
        return [... new Set(divisions)];
    },
    sortedSeasonCategories(state) {
        const divisions = state.teams
            .map(team => encodeTeamDivision(team))
            .sort((a, b) => (a > b ? 1 : -1));
        return [... new Set(divisions)];
    },
    sortedDivisions(state) {
        const divisions = state.teams
            .map(team => encodeTeamDivision({division: team.division, pool: ''}))
            .sort((a, b) => (a > b ? 1 : -1));
        return [... new Set(divisions)];
    },
    poolsInDivision: (state, getters) => (div) => {
        const availPools = getters.sortedSeasonCategories.map(d => {

                // Return only pool matching the division

            const {division, pool} = decodeTeamDivision(d);
            return division === div? pool: '';
        }).filter(p => p !== '');
        if(!availPools.length) {
            availPools.push(NO_POOLS);
        }
        return availPools;
    },
    playersPerTeam: (state) => {
        const teamMap = {} as TFilteredPlayersByTeam;
        state.players.forEach(p => {
            if(p.team) {
                if(teamMap[p.team]) teamMap[p.team].push(p);
                else teamMap[p.team] = [p];
            }
        })
        return teamMap;
    },
    fieldNames(state) {
        return state.fields.map(field => field.name);
    },
    fieldInfo:(state) => (fieldName) => {
        const fieldsInfo = {} as {[fieldName: string]: FieldInterface};
        state.fields.forEach(field => {
                fieldsInfo[field.name] = field;
        });
        return fieldsInfo[fieldName];
    },
    sortedFields(state, getters): string[] {
        return getters.fieldNames.sort((a, b) => (a > b ? 1 : -1));
    },
    fieldExternalLinkUrl(state): string {
        return state.pjc.app.fields.externalLink.url;
    },
    fieldExternalLinkDisplayName(state): string {
        return state.pjc.app.fields.externalLink.displayName ?? 'Lien externe';
    },
    fieldExternalLinkVisible(state): boolean {
        return state.pjc.app.fields.externalLink.url !== '';
    },
    sortedSponsors(state): SponsorInterface[] {
        const sTypes = Object.values(SPONSOR_TYPES);
        return state.sponsors.sort((a, b) => (sTypes.indexOf(a.sponsorType) > sTypes.indexOf(b.sponsorType) ? 1 : -1));
    },
    fullSchedule: (state) => {
        const series = [] as SeriesInterface[];
        Object.keys(state.series).forEach(k => series.push(...state.series[k]));
        return [...state.schedule, ...series];
    },
    filteredSchedule: (state, getters) => (filterSetName: string) => {
        const fltSet = getters.filterSet(filterSetName);
        const fullSchedule = getters.fullSchedule;
        let dateList = [] as Partial<SeriesInterface>[];
        const [dateMin, dateMax] = fltSet.dateRangeFilter;
        dateList = fullSchedule.filter(m => {
            if(!m.date) return false;

            const matchDate = new Date(m.date).getTime();
            return (
                (!dateMin || dateMin <= matchDate)
                && (!dateMax || dateMax >= matchDate)
            )
        });

        let teamList = [] as Partial<SeriesInterface>[];
        const teamNames = fltSet.teamNamesFilter;
        if(!teamNames.length) teamList = fullSchedule;
        else {
            teamList = fullSchedule.filter(m => {
                return (
                    (m.awayTeam && teamNames.indexOf(m.awayTeam) !== -1)
                    || (m.homeTeam && teamNames.indexOf(m.homeTeam) !== -1)
                )
            });
        }

        let fieldList = [] as Partial<SeriesInterface>[];
        const fieldNames =  fltSet.fieldNamesFilter;
        if(!fieldNames.length) fieldList = fullSchedule;
        else {
            fieldList = fullSchedule.filter(f => f.field && fieldNames.indexOf(f.field) !== -1);
        }

        let divisionList = [] as Partial<SeriesInterface>[];
        const divisions =  fltSet.divisionsFilter;
        if(!divisions.length) divisionList = fullSchedule;
        else {
            divisionList = fullSchedule.filter(f => {
                const homeTeam = f.homeTeam? getters.teamInfo(f.homeTeam): null
                const homeDiv = homeTeam? divisionLongDisplayName(
                    homeTeam.division, homeTeam.pool
                ): null;
                const awayTeam = f.awayTeam? getters.teamInfo(f.awayTeam): null;
                const awayDiv = awayTeam? divisionLongDisplayName(
                    awayTeam.division, awayTeam.pool
                ): null;
                return (
                    (homeDiv && divisions.indexOf(homeDiv) >= 0)
                    || (awayDiv && divisions.indexOf(awayDiv) >= 0)
                    || (f.division && divisions.indexOf(f.division) >= 0)
                )
            });
        }

        let cancelledList = [] as Partial<SeriesInterface>[];
        const cancelled =  fltSet.cancelledFilter;
        if(!cancelled) cancelledList = fullSchedule;
        else {
            cancelledList = fullSchedule.filter(f => f.result?.cancelled !== undefined );
        }

        let roundList = [] as Partial<SeriesInterface>[];
        const roundFlt = fltSet.roundFilter;
        if(!roundFlt) roundList = fullSchedule;
        else {
            roundList = fullSchedule.filter(m => m.round !== undefined && roundFlt.indexOf(m.round) >= 0);
        }

        return setIntersection(
            [teamList, fieldList, dateList, divisionList, cancelledList, roundList],
            true
        );
    },
    filterSet: (state) => (filterSetName) => {
        return state.filterSets[filterSetName] ?? emptyFilterDef();
    },
    hasScheduleFilters: (state, getters) => (filterSetName) => {
        const fltSet = getters.filterSet(filterSetName);
        return (
            fltSet.dateRangeFilter.length > 0
            || fltSet.teamNamesFilter.length > 0
            || fltSet.fieldNamesFilter.length > 0
            || fltSet.divisionsFilter.length > 0
            || fltSet.cancelledFilter
        );
    },
    hasDateFilters: (state, getters) => (filterSetName) => {
        return getters.filterSet(filterSetName).dateRangeFilter.length > 0
    },
    hasFieldFilters: (state, getters) => (filterSetName) => {
        return getters.filterSet(filterSetName).fieldNamesFilter.length > 0
    },
    hasTeamFilters: (state, getters) => (filterSetName) => {
        return getters.filterSet(filterSetName).teamNamesFilter.length > 0
    },
    hasDivisionFilters: (state, getters) => (filterSetName) => {
        return getters.filterSet(filterSetName).divisionsFilter.length > 0
    },
    hasRoundFilters: (state, getters) => (filterSetName) => {
        return getters.filterSet(filterSetName).roundFilter !== null
    },
    isVisiblePopup: (state) => (popup) => {
        return state.popups.find(p => p.id === popup) !== undefined;
    },
    popupTarget: (state) => (popup) => {
        return state.popups.find(p => p.id === popup)?.location ?? null;
    },
    teamSelectionDropdownID: (state) => {
        const idMap: {[key: number]: boolean} = {
            [POPUPS.TEAM_CAPTAIN_DROPDOWN]: true,
            [POPUPS.TEAM_FAVORITES_DROPDOWN]: true,
            [POPUPS.TEAM_SELECTION_DROPDOWN]: true,
        }
        return state.popups.find(p => idMap[p.id])?.id ?? POPUPS.TEAM_SELECTION_DROPDOWN;
    },
    poolDropdownDivision: (state) => {
        return (state.popups.find(p => p.id === POPUPS.TEAM_POOL_DROPDOWN) as TPoolDropwdownState)?.division ?? '';
    },
    backupListReady: (state) => {
        return (
            state.removeBackupRequestStatus.status !== STATE.LOADING
            && state.restoreBackupRequestStatus.status !== STATE.LOADING
            && state.getBackupRequestStatus.status !== STATE.LOADING
            && state.addBackupRequestStatus.status !== STATE.LOADING
        );
    },
    archiveListReady: (state) => {
        return (
            state.archiveRequestStatus.status !== STATE.LOADING
            && state.addArchiveRequestStatus.status !== STATE.LOADING
        );
    },

    hasFacebook: (state) => {
        return state.facebookLink !== undefined && state.facebookLink !== '';
    },
    hasInstagram: (state) => {
        return state.instagramLink !== undefined && state.instagramLink !== '';
    },
    facebookLink: (state) => {
        return state.facebookLink;
    },
    instagramLink: (state) => {
        return state.instagramLink;
    },
    hasAgentLibreLink: (state) => {
        return state.agentLibreLink !== undefined && state.agentLibreLink !== '';
    },
    agentLibreLink: (state) => {
        return state.agentLibreLink;
    },

    // UIC //

    joinTeamButtonVisible:(state: ClientUIState) => {
        return state.uic.app.pages.home.joinTeamBtn.visible;
    },
    signUpButtonVisible:(state: ClientUIState) => {
        return state.uic.app.pages.home.signUpBtn.visible;
    },
    joinYearVisible: (state: ClientUIState) => {
        return state.uic.app.pages.teams.joinYear.visible;
    },
}
