import axios from 'axios';
import { REQUEST } from './documentation';
import { SeriesInterface } from './series';
import { ldpCredentialsRequest } from './serverEngine';

export interface MatchInterface {
    _id: string;
    date: string;
    field: string;
    homeTeam: string;
    awayTeam: string;
    result?: {
        cancelled?: string;
        homeTeam?: number;
        awayTeam?: number;
    };
    pendingApproval?: {
        requestingTeam: string,
        confirmingTeam: string
    }
}

export type TAddMatchFromFile = {
    data: {
        fileName: string, fileBlob: File
    };
    opts: {
        overwrite: boolean, stopOnError: boolean
    }
};

export type AddMatchInput = Omit <MatchInterface, '_id'>;

export type MatchOutput = {
    success: boolean;
    schedule: MatchInterface[];
};


type TMatchDocument = Omit<MatchInterface, '_id'>;
type TInvalidMatchData = {invalidFields: string[], match: number}


type TScheduleAnalysisOutput = {
    matchConflicts: TMatchDocument[][],
    missingFields: string[],
    missingTeams: string[]
}

export type TImportScheduleReport = TScheduleAnalysisOutput & {
    badMatchData  : TInvalidMatchData[]
}

type addMatchFromFileOutput = {
    success: boolean
    error  : string
    report : TImportScheduleReport,
    schedule: MatchInterface[],
    insertedIds: string[]
}

export type AddMatchFromFileInput = {fileName: string, fileBlob: File};

export type SeriesOutput = {
    success: boolean
    schedule: {[division: string]: SeriesInterface[]}
}

export type EditSeriesResultInput = {
    _id: string,
    result: {
        homeTeam: number,
        awayTeam: number
    },
    homeTeam: string,
    awayTeam: string
}


export interface ResultInterface extends MatchInterface {
    _id: string;
    date: string;
    field: string;
    homeTeam: string;
    awayTeam: string;
    result: {
        cancelled?: string;
        homeTeam: number;
        awayTeam: number;
    };
}

export type EditMatchInput = {
    _id: string;
    date: string;
    field: string;
    homeTeam: string;
    awayTeam: string;
    result?: {
        cancelled?: string;
        homeTeam?: number;
        awayTeam?: number;
    };
};

export type EditMatchResultInput = {
    _id: string;
    homeTeam: number;
    awayTeam: number
};

export async function fetchSchedule(season?: string): Promise<MatchInterface[]>{
    let route = `/api/schedule`;
    if (season) {
        route += `?season=${encodeURI(season)}`;
    }
    const resp = await axios.get(route);
    return resp.data as MatchInterface[];
}

export async function addMatch(req: AddMatchInput): Promise<MatchOutput> {
    return (await ldpCredentialsRequest(`/api/schedule/add`, REQUEST.POST, req)) as MatchOutput;
}

export async function addMatchFromFile(payload: AddMatchFromFileInput, opts: {overwrite: boolean, stopOnError: boolean}): Promise<addMatchFromFileOutput> {
    const formData = new FormData();
    formData.append("files", payload.fileBlob);
    formData.append("filename", payload.fileName);

    (Object.keys(opts)).forEach(k => {
        // eslint-disable-next-line
        formData.append(k, (opts as any)[k]);
    });
    return (await ldpCredentialsRequest(`/api/schedule/import`, REQUEST.POST, formData)) as addMatchFromFileOutput;
}

export async function removeMatch(matchID: string): Promise<MatchOutput> {
    return (await ldpCredentialsRequest(`/api/schedule/remove/${matchID}`, REQUEST.DELETE)) as MatchOutput;
}

export async function approveMatchResult(matchID: string): Promise<MatchOutput> {
    return (await ldpCredentialsRequest(`/api/schedule/approveedit/${matchID}`, REQUEST.POST)) as MatchOutput;
}

export async function editMatch(match: EditMatchInput): Promise<MatchOutput> {
    return (await ldpCredentialsRequest(`/api/schedule/edit/${match._id}`, REQUEST.PUT, match)) as MatchOutput;
}

export async function editMatchResult(match: EditMatchResultInput): Promise<MatchOutput> {
    return (await ldpCredentialsRequest(`/api/schedule/requestedit/${match._id}`, REQUEST.POST, match)) as MatchOutput;
}

export async function editSeriesResult(match: EditSeriesResultInput): Promise<SeriesOutput> {
    return (await ldpCredentialsRequest(`/api/series/requestedit/${match._id}`, REQUEST.POST, match)) as SeriesOutput;
}

export async function approveSeriesResult(matchID: string): Promise<SeriesOutput> {
    return (await ldpCredentialsRequest(`/api/series/approveedit/${matchID}`, REQUEST.POST)) as SeriesOutput;
}

export async function editSeries(match: Partial<SeriesInterface>): Promise<SeriesOutput> {
    return (await ldpCredentialsRequest(`/api/series/edit/${match._id}`, REQUEST.PUT, match)) as SeriesOutput;
}

export default {};
