import {
    createStore,
    Store as VuexStore,
    CommitOptions,
    DispatchOptions
} from 'vuex'
import { ClientUIState, state } from './state'
import { MUTATION, mutations, TMutations } from './mutations'
import { ACTION, actions, TActions } from './actions'
import { getters, TGetters } from './getters'
import { getSession } from '@/services/session'
import { STATE } from '@/constants'

export const store = createStore({
  state,
  mutations,
  actions,
  getters
}) as TStore

export type TStore = Omit<
  VuexStore<ClientUIState>,
  'commit' | 'dispatch' | 'getters'
> & {
    commit<K extends keyof TMutations, P extends Parameters<TMutations[K]>[1]>(
        key: K,
        payload: P,
        options?: CommitOptions
    ): ReturnType<TMutations[K]>,
    dispatch<K extends keyof TActions, P extends Parameters<TActions[K]>[1]>(
        key: K,
        payload?: P,
        options?: DispatchOptions
    ): ReturnType<TActions[K]>,
    getters: {
        [K in keyof TGetters]: ReturnType<TGetters[K]>
    }
}

export type TStoreContext = Pick<
    TStore,
    'state' | 'commit' | 'dispatch' | 'getters'
>

export const provisionStore =  {
    install: async () => {
        store.commit(MUTATION.RESET_STORE, undefined);
        store.commit(MUTATION.SET_ASSET_STATUS, {
            status: STATE.LOADING, msg: ''
        });

        try {
            const session = await getSession();
            store.commit(MUTATION.SET_STATE, session);
            await store.dispatch(ACTION.FETCH_APP_CFG, undefined);
            store.commit(MUTATION.SET_ASSET_STATUS, {
                status: STATE.DONE, msg: ''
            });
        } catch (err) {
            store.commit(MUTATION.SET_ASSET_STATUS, {
                status: STATE.ERR, msg: err as string
            });
        }
    }
}

export default {};