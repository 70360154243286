
import { POPUPS, POPUP_TYPES } from '@/constants';
import { defineComponent }  from 'vue';

export const enum DLG_BTN_TYPE {
    PRIMARY,
    SECONDARY,
    CLOSE
}

export declare type popupBtnProp = {
    label: string,
    type: DLG_BTN_TYPE,
    clickCB: () => any
}

export default defineComponent({
    name: 'Popup',
    props: {
        headerTitle: {
            type: String,
            required: false,
            default: ''
        },
        showHeader: {
            type: Boolean,
            required: false,
            default: true
        },
        type: {
            type: Number as () => POPUP_TYPES,
            required: false,
            default: POPUP_TYPES.BUBBLE_DOWN
        }
    },
    computed: {
        isDropDown(): boolean {
            return this.type === POPUP_TYPES.DROP_DOWN;
        },
        isBubbleDown(): boolean {
            return this.type === POPUP_TYPES.BUBBLE_DOWN;
        }
    }
});
