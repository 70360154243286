import axios from 'axios';

export interface SeriesInterface {
    _id: string;
    date?: string;
    field?: string;
    division: string;
    pool: string;
    homeTeam?: string;
    awayTeam?: string;
    homeDivision: string;
    awayDivision: string;
    homePool: string;
    awayPool: string;
    homeRank: number;
    awayRank: number;
    round: number;
    result?: {
        cancelled?: string;
        homeTeam?: number;
        awayTeam?: number;
    };
    pendingApproval?: {
        requestingTeam: string,
        confirmingTeam: string
    }
}
export type ScheduleInterface = Partial<SeriesInterface> & {_id: string};
export type ScheduleInterfaceWithDate = Partial<SeriesInterface> & {_id: string, date: Date};

export async function fetchSeriesMatchup(season?: string): Promise<{[division: string]: SeriesInterface[]}>{
    let route = `/api/series`;
    if (season) {
        route += `?season=${encodeURI(season)}`;
    }
    const resp = await axios.get(route);
    return resp.data as {[division: string]: SeriesInterface[]};
}

export default {};
