
import { defineComponent } from 'vue';
import Dialog from '@/components/lib/Dialog.vue';
import TeamLabel from '@/components/TeamLabel.vue';
import { store } from '@/store/store';
import { MUTATION } from '@/store/mutations';
import { TeamInterface } from '@/services/teams';
import { ACTION } from '@/store/actions';

export default defineComponent({
    name: 'TheUserMenu',
    components: {
        Dialog, TeamLabel
    },
    computed: {
        teamsICaptain(): TeamInterface[] {
            return store.getters.teamsICaptain;
        },
        userMenuVisible(): boolean {
            return store.state.userMenuVisible;
        },
        isLoggedIn(): boolean {
            return store.getters.isLoggedIn;
        },
        isAdmin(): boolean {
            return store.getters.isAdmin;
        }
    },
    methods: {
        closeDialog() {
            store.commit(MUTATION.SHOW_USER_MENU, false);
        },
        requestLogout() {
            store.dispatch(ACTION.ACCOUNT_LOGOUT);
        },
        showPreferencesDialog() {
            this.$emit('show-prefs-dialog', true);
        },
    }
});
