
import { defineComponent } from 'vue';
import TheNavbar from '@/components/TheNavbar.vue';
import ThePreferencesDialog from '@/components/ThePreferencesDialog.vue';
import TheLoginDialog from '@/components/TheLoginDialog.vue';
import TheSignupDialog from '@/components/TheSignupDialog.vue';
import TheTeamAddDialog from '@/components/TheTeamAddDialog.vue';
import TheSponsorAddDialog from '@/components/TheSponsorAddDialog.vue';
import TheMainMenu from '@/components/TheMainMenu.vue';
import TheUserMenu from '@/components/TheUserMenu.vue';
import TheTeamSelectionDropdown from '@/components/TheTeamSelectionDropdown.vue';
import TheTeamDivisionDropdown from '@/components/TheTeamDivisionDropdown.vue';
import TheTeamPoolDropdown from '@/components/TheTeamPoolDropdown.vue';
import TheSponsorTypeDropdown from '@/components/TheSponsorTypeDropdown.vue';
import TheJoinTeamDialog from '@/components/TheJoinTeamDialog.vue';
import TheScheduleFilters from '@/components/TheScheduleFilters.vue';
import TheNotificationCenter from '@/components/TheNotificationCenter.vue';
import ResizeObserver from '@/components/lib/ResizeObserver.vue';
import { TeamInterface } from '@/services/teams';
import { ACTION } from '@/store/actions';
import { closeAllDialogsAndPopups, anyModalDialogVisible, MUTATION } from '@/store/mutations';
import { store } from '@/store/store';
import { toTitleCase } from '@/utils';
import { POPUPS, STATE } from '@/constants';
import router from './router/router';
import { POPUP_DIRECTION } from './store/state';
import { TimeslotInterface } from './services/timeslots';
import { SponsorInterface } from './services/sponsors';
import { ScheduleInterface } from './services/series';
export default defineComponent({
    name: 'App',
    components: {
        TheNavbar, TheLoginDialog, TheMainMenu, TheUserMenu, TheSignupDialog,
        ThePreferencesDialog, TheTeamSelectionDropdown, ResizeObserver,
        TheJoinTeamDialog, TheNotificationCenter, TheTeamAddDialog,
        TheTeamDivisionDropdown, TheTeamPoolDropdown,
        TheScheduleFilters, TheSponsorAddDialog, TheSponsorTypeDropdown
    },
    computed: {
        activeFilterSet(): string {
            return store.state.activeFilterSet;
        },
        isAppReady(): boolean {
            return store.getters.appReady;
        },
        isDBLoading(): boolean {
            return store.state.restoreBackupRequestStatus.status === STATE.LOADING;
        },
        loginUsername(): string {
            return store.state.loginDialogState.username
        },
        signupUsername(): string {
            return store.state.signupDialogState.username
        },
        signupName(): string {
            return store.state.signupDialogState.name
        },
        signupTeams(): string[] {
            return store.state.signupDialogState.teams
        },
        signupTeamCaptain(): boolean {
            return store.state.signupDialogState.teamCaptain
        },
        addTeam(): TeamInterface {
            return store.state.addTeamDialogState.team;
        },
        addSponsor(): SponsorInterface {
            return store.state.addSponsorDialogState.sponsor;
        },
        userMenuVisible(): boolean {
            return store.state.userMenuVisible;
        },
        notificationsVisible(): boolean {
            return store.state.notificationsVisible;
        },
        isLoggedIn(): boolean {
            return store.getters.isLoggedIn;
        },
        role(): string {
            return toTitleCase(store.state.sessionInfo.perm.role);
        },
        user(): string {
            return store.state.sessionInfo.name;
        },
        isAdmin(): boolean {
            return store.getters.isAdmin;
        },
        teamsIFavorite(): TeamInterface[] {
            return store.getters.teamsIFavorite;
        },
        teamsICaptain(): TeamInterface[] {
            return store.getters.teamsICaptain;
        },
        anyModalDialogVisible(): boolean {
            return anyModalDialogVisible(store);
        },
        mainMenuVisible(): boolean {
            return store.state.mainMenuVisible;
        },
        timeslotMatch(): ScheduleInterface {
            return store.state.timeslotConfirmDialogState.match
        },
        availableTimeslot(): TimeslotInterface {
            return store.state.timeslotConfirmDialogState.timeslot
        }
    },
    mouted() {
        // Anything fetched here will be reset when the store is provision
        // by main.ts. Don't addd anything to the store from the mounted/created
    },
    watch: {
        isAppReady() {
            store.dispatch(ACTION.FETCH_TEAMS, undefined);
            store.dispatch(ACTION.FETCH_SCHEDULE, undefined);
            store.dispatch(ACTION.FETCH_SPONSORS, undefined);
        },
        $route(to) {
            // Deals with auth login token directly in URL
            if(to.query.auth && !store.state.sessionInfo.loggedIn) {
                store.dispatch(ACTION.ACCOUNT_LOGIN, {
                    auth: to.query.auth as string
                });
            }
            switch(to.path) {
                case '/myaccount/preferences': {
                    this.togglePreferencesDialog(true);
                    break;
                }
                case '/myaccount/login': {
                    if(to.query.username !== store.state.sessionInfo.email) {
                        this.toggleLoginDialog(true, to.query.username);
                    }
                    else if(!store.getters.isLoggedIn) {
                        this.toggleLoginDialog(true, to.query.username ?? '');
                    }
                    else if (to.query.redirect && store.getters.isLoggedIn){
                        router.replace(to.query.redirect);
                    }
                    break;
                }
                case '/myaccount/register': {
                    this.toggleSignupDialog(true);
                    break;
                }
            }
        }
    },
    methods: {
        toggleLoginDialog(show: boolean, username?: string) {
            this.closeAllDialogsAndPopups();
            store.commit(MUTATION.SHOW_LOGIN_DIALOG, {
                show,
                username: username ?? ''
            });
        },
        toggleSignupDialog(show: boolean) {
            this.closeAllDialogsAndPopups();
            store.commit(MUTATION.SHOW_SIGNUP_DIALOG, {
                show,
                username: '',
                teams: store.getters.teamsIFavorite.map(t => t.name),
                teamCaptain: false,
                name: ''
            });
        },
        togglePreferencesDialog(show: boolean) {
            this.closeAllDialogsAndPopups();
            store.commit(MUTATION.SHOW_PREFERENCES_DIALOG, show);
        },
        toggleMainMenu(show?: boolean) {
            if(show === undefined) show = !this.mainMenuVisible;

            this.closeAllDialogsAndPopups();
            store.commit(MUTATION.SHOW_MAIN_MENU, show);
        },
        toggleUserMenu(show?: boolean) {
            if(show === undefined) show = !this.userMenuVisible;

            this.closeAllDialogsAndPopups();
            store.commit(MUTATION.SHOW_USER_MENU, show);
        },
        toggleJoinTeamDialog(show: boolean) {
            this.closeAllDialogsAndPopups();
            store.commit(MUTATION.SHOW_JOIN_TEAM_DIALOG, show);
        },
        toggleNotifications(show?: boolean) {
            if(show === undefined) show = !this.notificationsVisible;
            this.closeAllDialogsAndPopups();
            store.commit(MUTATION.SHOW_NOTIFICATIONS, show);
        },
        showTeamFavoritesDropdown() {
            const input = this.$refs['favorite-dropdown'] as HTMLElement;
            if (!input) return;

            store.commit(MUTATION.SHOW_POPUP_ONLY, {
                id: POPUPS.TEAM_FAVORITES_DROPDOWN, location: input, direction: POPUP_DIRECTION.DOWN
            })
        },
        showTeamCaptainDropdown() {
            const input = this.$refs['captain-dropdown'] as HTMLElement;
            if (!input) return;

            store.commit(MUTATION.SHOW_POPUP_ONLY, {
                id: POPUPS.TEAM_CAPTAIN_DROPDOWN, location: input, direction: POPUP_DIRECTION.DOWN
            })
        },
        closeAllDialogsAndPopups() {
            closeAllDialogsAndPopups(store);
        },
        handleResize(){
            store.commit(MUTATION.CLEAR_POPUPS, undefined);
            store.commit(MUTATION.WINDOW_SIZE, {
                height: window.innerHeight, width: window.innerWidth
            });
        },
        gotoAdminPage() {
            if(!this.isAdmin) return;
            router.replace(`/admin`);
        }
    }
});
