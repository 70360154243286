
import { defineComponent } from 'vue';
import TeamBadge from '@/components/TeamBadge.vue';
import { TeamInterface } from '@/services/teams';

export default defineComponent({
    name: 'TeamLabel',
    components: { TeamBadge },
    props: {
        team: {
            type: Object as () => TeamInterface, 
            required: false
        },
        alt: {
            type: String,
            required: false,
            default: ''
        },
        invert: {
            type: Boolean,
            required: false,
            default: false
        },
        favorite: {
            type: Boolean,
            required: false,
            default: false
        },
        captain: {
            type: Boolean,
            required: false,
            default: false
        },
        badge: {
            type: Boolean,
            required: false,
            default: true
        },
        teamName: {
            type: Boolean,
            required: false,
            default: true
        },
        link: {
            type: Boolean,
            required: false,
            default: true
        }
    }
});
