import { REQUEST } from './documentation';
import { ldpCredentialsRequest } from './serverEngine';

export interface LogInterface {
    _id: string;
    date: number;
    userID: string;
    db_collection: string;
    actionType: REQUEST;
    description: string;
    // eslint-disable-next-line
    originalObject: any;
    // eslint-disable-next-line
    newObject: any;
}

export async function fetchLogs(): Promise<LogInterface[]> {
    return (await ldpCredentialsRequest(`/api/logs/`, REQUEST.GET)) as LogInterface[];
}

export default {};
