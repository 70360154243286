
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'Checkbox',
    props: {
        value: {
            type: String,
            required: true
        },
        checked: {
            type: Boolean,
            required: true
        },
        inactive: {
            type: Boolean,
            required: false,
            default: false
        },
        exclude: { // determines if checkmark or X is shown in the checkbox
            type: Boolean,
            required: false,
            default: false // checkmark by default
        }
    },
    methods: {
        toggle() {
            this.$emit('toggle', !this.checked);
        }
    }
});
