import { REQUEST } from './documentation';
import { ldpCredentialsRequest } from './serverEngine';

export type FilteredTimeslotData = {
    available: TimeslotInterface[];
    pending: TimeslotInterface[];
    confirmed: TimeslotInterface[];
    booked: TimeslotInterface[];
};

export type ReservationInterface = {
    requestingTeam: string;
    confirmingTeam: string;
    matchID: string;
};

export type TimeslotInterface = {
    _id: string;
    date: string;
    field: string;
    reservation?: ReservationInterface;
    pendingApproval?: {
        requestingTeam: boolean,
        confirmingTeam: boolean
    }
};

export type TTimeslotRequestOutput = {
    success: boolean;
    timeslotSchedule: FilteredTimeslotData;
};

export type TTimeslotRequestInput = {
    timeslot: TimeslotInterface;
};

// when adding/removing timeslots
export type TimeslotDataAddInput = Omit<TimeslotInterface, '_id' | 'reservation'>;
export type TTimeslotDataOutput = {
    success: boolean;
    timeslotSchedule: FilteredTimeslotData;
};

export async function fetchTimeslots(): Promise<FilteredTimeslotData> {
    return (await ldpCredentialsRequest(`/api/timeslots/filtered`, REQUEST.GET)) as FilteredTimeslotData;
}

export async function addTimeslot(req: TimeslotDataAddInput): Promise<TTimeslotDataOutput> {
    return (await ldpCredentialsRequest(
        `/api/timeslots/add`,
        REQUEST.POST,
        req
    )) as TTimeslotDataOutput;
}

export async function removeTimeslot(timeslotID: string): Promise<TTimeslotDataOutput> {
    return (await ldpCredentialsRequest(
        `/api/timeslots/remove/${timeslotID}`,
        REQUEST.DELETE
    )) as TTimeslotDataOutput;
}

export async function sendTimeslotRequest(
    req: TTimeslotRequestInput
): Promise<TTimeslotRequestOutput> {
    return (await ldpCredentialsRequest(
        `/api/timeslots/requestreservation/${req.timeslot._id}`,
        REQUEST.POST,
        req
    )) as TTimeslotRequestOutput;
}

export async function confirmTimeslotRequest(timeslotID: string): Promise<TTimeslotDataOutput> {
    return (await ldpCredentialsRequest(
        `/api/timeslots/confirmreservation/${timeslotID}`,
        REQUEST.POST
    )) as TTimeslotRequestOutput;
}

export async function denyTimeslotRequest(timeslotID: string): Promise<TTimeslotDataOutput> {
    return (await ldpCredentialsRequest(
        `/api/timeslots/denyreservation/${timeslotID}`,
        REQUEST.POST
    )) as TTimeslotRequestOutput;
}

export async function approveTimeslotReservation(timeslot: TimeslotInterface): Promise<TTimeslotDataOutput> {
    return (await ldpCredentialsRequest(
        `/api/timeslots/approvereservation/${timeslot._id}`,
        REQUEST.POST,
        {reservation: timeslot.reservation}
    )) as TTimeslotRequestOutput;
}

export default {};
