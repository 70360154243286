
import { defineComponent } from 'vue';
import Dialog from '@/components/lib/Dialog.vue';
import TheColorPicker from './TheColorPicker.vue';
import FileUpload from './lib/FileUpload.vue';
import { defaultSponsorDef, emptySponsorDef } from '@/utils';
import { store } from '@/store/store';
import { MUTATION } from '@/store/mutations';
import { POPUPS, STATE } from '@/constants';
import { POPUP_DIRECTION } from '@/store/state';
import { ACTION } from '@/store/actions';
import { SponsorInterface } from '@/services/sponsors';
import { sponsorTypeEnumToDisplayName } from '@/utils.names';
import CSS from 'csstype';

export default defineComponent({
    name: 'TheSponsorAddDialog',
    components: {
        Dialog, FileUpload, TheColorPicker
    },
    data() {
        return {
            addSponsor: emptySponsorDef(),
            addSponsorLogo: {
                fileName: null as string | null,
                fileBlob: null as File | null
            },
            addSponsorPicture: {
                fileName: null as string | null,
                fileBlob: null as File | null
            }
        }
    },
    computed: {
        dialogVisible(): boolean {
            return store.state.addSponsorDialogState.show;
        },
        sponsor(): SponsorInterface {
            return store.state.addSponsorDialogState.sponsor;
        },
        mainBtnText(): string {
            return this.editMode? 'Sauvegarder': 'Ajouter'
        },
        editMode(): boolean {
            return this.addSponsor._id !== '';
        },
        nameMissing(): boolean {
            return this.addSponsor.name === undefined || this.addSponsor.name === '';
        },
        addOrEditLoading(): boolean {
            return (
                (!this.editMode && store.state.addSponsorRequestStatus.status === STATE.LOADING)
                || (this.editMode && store.state.editSponsorRequestStatus[this.addSponsor._id]?.status === STATE.LOADING)
            );
        },
        requestErr(): boolean {
            const reqErr = (
                (!this.editMode && store.state.addSponsorRequestStatus.status === STATE.ERR)
                || (this.editMode && store.state.editSponsorRequestStatus[this.addSponsor._id]?.status === STATE.ERR)
            );
            return reqErr
        },
        picture(): string {
            if(this.addSponsorPicture.fileName) return this.addSponsorPicture.fileName;
            else if(this.addSponsor.picture) return this.addSponsor.picture.slice(this.addSponsor.picture.lastIndexOf('/') + 1);
            else return 'No sponsor picture';
        },
        supportedLogoFileExt(): string[] {
            return ['jpg', 'png', 'svg']
        },
        supportedPictureFileExt(): string[] {
            return ['jpg', 'png', 'svg']
        },
        hasPicture(): boolean {
            return this.addSponsor.picture !== undefined
        },
        pictureUrl(): string {
            return this.addSponsor.picture ?? '';
        },
        hasLogo(): boolean {
            return this.addSponsor.logo !== undefined
        },
        LogoUrl(): string {
            return this.addSponsor.logo ?? '';
        },
        colorStyle(): CSS.Properties {
            return {'background-color': this.addSponsor.color} as CSS.Properties;
        },
        colorPickerVisible(): boolean {
            return store.state.colorPickerVisible;
        },
        sponsorTypeDisplayName(): string {
            return sponsorTypeEnumToDisplayName(this.addSponsor.sponsorType);
        }
    },
    methods: {
        closeDialog() {
            store.commit(MUTATION.SHOW_ADD_SPONSOR_DIALOG, {
                show: false,
                sponsor: emptySponsorDef()
            });
        },
        addOrEdit() {
            const newSponsor = {...this.addSponsor};
            if (this.editMode) {
                store.dispatch(ACTION.EDIT_SPONSOR, {
                    sponsor: newSponsor,
                    images: {
                        logo: this.addSponsorLogo.fileBlob,
                        picture: this.addSponsorPicture.fileBlob
                    }
                });
            }
            else {
                store.dispatch(ACTION.ADD_SPONSOR, {
                    sponsor: newSponsor,
                    images: {
                        logo: this.addSponsorLogo.fileBlob,
                        picture: this.addSponsorPicture.fileBlob
                    }
                });
            }
        },
        setLogo(payload: {fileBlob: File, fileName: string}) {
            if(!payload.fileBlob) {
                this.addSponsor.logo = undefined;
                this.addSponsorLogo = payload;
                return;
            }
            this.addSponsor.logo = window.URL.createObjectURL(payload.fileBlob)
            this.addSponsorLogo = payload;
        },
        setPicture(payload: {fileBlob: File, fileName: string}) {
            if(!payload.fileBlob) {
                this.addSponsor.picture = undefined;
                this.addSponsorPicture = payload;
                return;
            }
            this.addSponsor.picture = window.URL.createObjectURL(payload.fileBlob)
            this.addSponsorPicture = payload;
        },
        showColorPicker(show: boolean) {
            store.commit(MUTATION.SHOW_COLOR_PICKER, show);
        },
        showSponsorTypeDropdown() {
            const input = this.$refs['sponsortype-container'] as HTMLElement;
            store.commit(MUTATION.SHOW_POPUP_ONLY, {
                id: POPUPS.SPONSOR_TYPE_DROPDOWN,
                location: input,
                direction: POPUP_DIRECTION.DOWN
            });
        },
        updateColor(color: string) {
            this.addSponsor.color = color;
        },
        closeSelfCreatedPopup() {
            store.commit(MUTATION.CLEAR_POPUPS, undefined);
            store.commit(MUTATION.SHOW_COLOR_PICKER, false);
        }
    },
    mounted() {
        this.addSponsor = {...this.sponsor};
        if(!this.hasLogo) this.addSponsor.logo = defaultSponsorDef().logo;
    },
    watch: {
        dialogVisible(visible) {
            if(visible) {
                this.$nextTick(() => {
                    (this.$refs as unknown as {'dialog': HTMLElement}).dialog?.focus();
                });
            }
        },
        sponsor(newSponsor: SponsorInterface, oldSponsor: SponsorInterface) {
            for(const key in oldSponsor) {
                const prop = key as keyof SponsorInterface;
                if(oldSponsor[prop] !== newSponsor[prop]) {
                    //@ts-ignore
                    this.addSponsor[prop] = newSponsor[prop];
                }
            }
        }
    }
});
