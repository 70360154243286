
import { defineComponent } from 'vue';
import Dialog from '@/components/lib/Dialog.vue';
import { store } from '@/store/store';
import { ACTION } from '@/store/actions';
import { MUTATION } from '@/store/mutations';
import { STATE } from '@/constants';
import { toCamelCase } from '@/utils';
import { TSavePreferences } from '@/services/session';
import router from '@/router/router';
import { TActionState } from '@/store/state';

export default defineComponent({
    name: 'ThePreferencesDialog',
    components: {
        Dialog
    },
    data() {
        return {
            username: '',
            name: '',
            password: '',
            confirmPassword: '',
            passwordChangeAction: false
        };
    },
    computed: {
        saveStatus(): TActionState {
            return store.state.savePreferencesStatus;
        },
        saveReady(): boolean {
            return this.saveStatus.status === STATE.DONE;
        },
        saveLoading(): boolean {
            return this.saveStatus.status === STATE.LOADING;
        },
        saveError(): boolean {
            return this.saveStatus.status === STATE.ERR;
        },
        preferencesDialogVisible(): boolean {
            return store.state.preferencesDialogVisible;
        },
        user(): string {
            return toCamelCase(store.state.sessionInfo.name);
        },
        email(): string {
            return store.state.sessionInfo.email
        }
    },
    methods: {
        savePreferences() {
            if(this.saveLoading) return;

            if(this.passwordChangeAction && this.password === '') return;
            if(this.confirmPassword !== this.password) return;

            if(this.confirmPassword === this.password) {
                this.passwordChangeAction = false;
            }

            const updateObj = {} as TSavePreferences;
            if(this.password !== '') updateObj.password = this.password;
            if(this.username !== '') updateObj.username = this.username;
            if(this.name !== '') updateObj.name = this.name;
            store.dispatch(ACTION.SAVE_PREFERENCES, updateObj);

            this.username = '';
            this.name = '';
            this.password = '';
            this.confirmPassword = '';
        },
        closeDialog() {
            if(this.passwordChangeAction) return;
            store.commit(MUTATION.SHOW_PREFERENCES_DIALOG, false);
            if(this.$route.path === '/myaccount/preferences') {
                router.replace('/');
            }
        }
    },
    watch: {
        $route(to) {
            this.passwordChangeAction = to.query.action === 'resetpassword'
        },
        saveStatus(newStatus: TActionState, oldStatus: TActionState) {
            if(oldStatus.status === STATE.LOADING && newStatus.status === STATE.DONE) {
                this.closeDialog();
            }
        }
    },
    mounted() {
        this.name = this.user;
        this.username = this.email;
    }
});
