import { LDP_LOCAL_SESSION, USER_ROLES } from '@/constants';
import { ClientSessionState, ClientUIState, defaultSessionState, STORE_VERSION, TSessionInfo } from '@/store/state';
import axios from 'axios';
import { REQUEST } from './documentation';
import { ldpCredentialsRequest } from './serverEngine';

export type TRequestLoginInput = {
    username?: string;
    password?: string;
    auth?: string;
};

export type TRequestLoginOutput = {
    success: boolean;
    sessionInfo: TSessionInfo;
    error?: string;
};

export type TRequestLogoutOutput = {
    success: boolean;
    sessionInfo: TSessionInfo;
    error?: string;
};

export type TForgotPasswordInput = {
    email: string;
};

export type TForgotPasswordOutput = {
    success: boolean;
    error: string
};

export type TChangePasswordInput = {
    password: string
}

export type TChangePasswordOutput = {
    success: boolean;
    error: string
};

export type TSavePreferencesOutput = {
    success: boolean,
    error: string
}

export type TSavePreferences = {
    password?: string,
    username?: string,
    name?: string,
    teams?: string[]
}

export type TAccountStatusOutput = {
    approved: boolean,
    rejected: boolean,
    pending: boolean
}

export type TSuccess = {
    success: boolean;
};

export function defaultSessionInfo(): TSessionInfo {
    return {
        loggedIn: false,
        email: '',
        perm: {
            teams: [],
            role: USER_ROLES.BASIC
        },
        name: '',
        notifications: [],
        _id: ''
    };
}


function restoreLocalSession(): ClientSessionState{
    const storage = localStorage.getItem(LDP_LOCAL_SESSION);

    let session = {} as ClientSessionState;
    if(storage) {
        const localSession = JSON.parse(storage) as ClientSessionState;
        if(localSession.storeVersion === STORE_VERSION) {
            session = {...session, ...localSession} as ClientSessionState;
        }
    }
    return session;
}

// save the session state for session described by <ClientSessionState.dataDir>
export function setLocalSession (state: ClientUIState): boolean{
    const session: ClientSessionState = {
        storeVersion: state.storeVersion,
        releaseVersion: state.releaseVersion,
        myTeams: state.myTeams
    };
    try{
        localStorage.setItem(LDP_LOCAL_SESSION, JSON.stringify(session));
        return true;
    } catch (err) {
        return false;
    }
}

export async function requestPasswordReset(
    username: string
): Promise<TForgotPasswordOutput> {
    const resp = await axios.post(`/api/myaccount/resetpassword`, {username});
    return resp.data as TForgotPasswordOutput;
}

export async function requestLogout(): Promise<TRequestLogoutOutput> {
    return (await ldpCredentialsRequest(`/api/myaccount/logout`, REQUEST.POST)) as TRequestLogoutOutput;
}

export async function requestLogin(req: TRequestLoginInput): Promise<TRequestLoginOutput> {
    return (await ldpCredentialsRequest(`/api/myaccount/login`, REQUEST.POST, req)) as TRequestLoginOutput;
}

export async function sessionInfo(): Promise<TSessionInfo> {
    return (await ldpCredentialsRequest(`/api/myaccount/info`, REQUEST.GET)) as TSessionInfo;
}

export async function deleteNotification(id: string): Promise<TSuccess> {
    return (await ldpCredentialsRequest(`/api/myaccount/notification/${id}`, REQUEST.DELETE)) as TSuccess;
}


export async function getSession() :Promise<ClientUIState> {
    const session = {...defaultSessionState(), ...restoreLocalSession()};
    const sessInfo = await sessionInfo();
    session.sessionInfo = sessInfo;
    return session;
}

export async function savePreferences(req: TSavePreferences): Promise<TRequestLoginOutput> {
    return (await ldpCredentialsRequest(`/api/myaccount/edit`, REQUEST.POST, req)) as TRequestLoginOutput;
}

export async function getAccountStatus(username: string): Promise<TAccountStatusOutput> {
    const resp = await axios.get(`/api/myaccount/status/${username}`);
    return resp.data as TAccountStatusOutput;
}


export default {};
