
import { defineComponent } from 'vue';
import CheckboxFilter from './CheckboxFilter.vue';
import { store } from '@/store/store';
import { MUTATION } from '@/store/mutations';
import { FieldInterface } from '@/services/fields';

export default defineComponent({
    name: 'TheFieldFilter',
    components: {
        CheckboxFilter
    },
    props: {
        collapsed: {
            type: Boolean,
            required: false,
            default: false
        },
        filterSetName: {
            type: String,
            required: true
        }
    },
    computed: {
        sortedFields(): string[] {
            return store.getters.sortedFields;
        },
        filterValues(): string[] {
            return store.getters.filterSet(this.filterSetName).fieldNamesFilter;
        }
    },
    methods: {
        clearFilter() {
            store.commit(MUTATION.SET_FIELD_NAMES_FILTERS, {filterSet: this.filterSetName, fieldNames: []});
            store.commit(MUTATION.SET_SELECTED_MATCH_IDX, -1);
        },
        updateFilter(fields: string[]) {
            store.commit(MUTATION.SET_FIELD_NAMES_FILTERS, {filterSet: this.filterSetName, fieldNames: fields});
            store.commit(MUTATION.SET_SELECTED_MATCH_IDX, -1);
        },
        fieldInfo(fieldName: string): FieldInterface {
            return store.getters.fieldInfo(fieldName);
        },
    }
});
