
import { defineComponent } from 'vue';
import Dialog from '@/components/lib/Dialog.vue';
import Checkbox from './Checkbox.vue';
import TeamLabel from './TeamLabel.vue';
import { store } from '@/store/store';
import { ACTION } from '@/store/actions';
import { MUTATION } from '@/store/mutations';
import { STATE, USER_ROLES } from '@/constants';
import { TeamInterface } from '@/services/teams';

export default defineComponent({
    name: 'TheLoginDialog',
    components: { Dialog, Checkbox, TeamLabel },
    data() {
        return {
            signupUsername: '',
            password: '',
            passwordConfirm: '',
            signupName: '',
            formErrorMsg: '',
            signupTeams: [] as string[],
            isTeamCaptain: false
        };
    },
    props: {
        teamCaptain : {
            type: Boolean,
            required: false,
            default: false
        },
        teams: {
            type: Array as () => string[],
            required: false,
            default: [] as string[]
        },
        username: {
            type: String,
            required: false,
            default: ''
        },
        name: {
            type: String,
            required: false,
            default: ''
        }
    },
    computed: {
        signupReady(): boolean {
            return store.state.signupRequestStatus.status === STATE.DONE;
        },
        signupLoading(): boolean {
            return store.state.signupRequestStatus.status === STATE.LOADING;
        },
        signupError(): boolean {
            return store.state.signupRequestStatus.status === STATE.ERR;
        },
        signupDialogVisible(): boolean {
            return store.state.signupDialogState.show;
        },
        formError(): boolean {
            return this.formErrorMsg !== '';
        },
        errorMsg(): string {
            return this.formError? 
                this.formErrorMsg: store.state.signupRequestStatus.msg;
        },
        availableTeams(): string[] {
            return store.getters.sortedTeams.filter(t => this.teams.indexOf(t) === -1);
        }
    },
    methods: {
        fetchAccount() {
            if(this.signupLoading || this.formError || this.formIncomplete()) return;
            store.dispatch(ACTION.FETCH_ACCOUNT, {
                name: this.signupName,
                username: this.signupUsername,
                password: this.password,
                role: this.isTeamCaptain? USER_ROLES.CAPTAIN: USER_ROLES.BASIC,
                teams: this.signupTeams
            });
        },
        closeDialog() {
            this.password = '';
            this.passwordConfirm = '';
            this.formErrorMsg = '';
            store.commit(MUTATION.SHOW_SIGNUP_DIALOG, {
                show: false, username: '', name: '', teams: [], teamCaptain: false
            });
        },
        checkPasswordMatch() {
            if(this.password !== this.passwordConfirm || this.password === '') {
                this.formErrorMsg = 'Password do not match';
            }
            else this.formErrorMsg = '';
        },
        teamInfo(teamName: string): TeamInterface {
            return store.getters.teamInfo(teamName);
        },
        setTeamCaptain(isCaptain: boolean) {
            this.isTeamCaptain = isCaptain;
        },
        removeTeam(team: string) {
            this.signupTeams.splice(this.signupTeams.indexOf(team), 1);
        },
        formIncomplete(): boolean {
            if (this.signupUsername == ''){
                this.formErrorMsg = 'Courriel manquant';
            }
            else if (this.password == ''){
                this.formErrorMsg = 'Mot de passe manquant';
            }
            return this.formErrorMsg !== '';
        }
    },
    watch: {
        signupDialogVisible() {
            if(!this.signupDialogVisible) return;
            this.$nextTick(() => {
                if(this.signupUsername === '') (this.$refs.username as HTMLElement).focus();
                else (this.$refs.password as HTMLElement).focus();
            });
        },
        teamCaptain() {
            this.isTeamCaptain = this.teamCaptain;
        },
        teams() {
            this.signupTeams = this.teams.slice();
        },
        username() {
            this.signupUsername = this.username;
        },
        name() {
           this.signupName = this.name;
        }
    }
});
