
import { defineComponent } from 'vue';
import Dialog from '@/components/lib/Dialog.vue';
import { store } from '@/store/store';
import { ACTION } from '@/store/actions';
import { closeAllDialogsAndPopups, MUTATION } from '@/store/mutations';
import { STATE } from '@/constants';

export default defineComponent({
    name: 'TheLoginDialog',
    components: {
        Dialog
    },
    data() {
        return {
            loginUsername: '',
            password: ''
        };
    },
    props: {
        username: {
            type: String,
            required: false,
            default: ''
        }
    },
    computed: {
        loginReady(): boolean {
            return store.state.loginRequestStatus.status === STATE.DONE;
        },
        loginLoading(): boolean {
            return store.state.loginRequestStatus.status === STATE.LOADING;
        },
        loginError(): boolean {
            return store.state.loginRequestStatus.status === STATE.ERR;
        },
        loginErrorMsg(): string {
            return store.state.loginRequestStatus.status === STATE.ERR?
                store.state.loginRequestStatus.msg: '';
        },
        loginDialogVisible(): boolean {
            return store.state.loginDialogState.show;
        },
        forgotPassMsg(): string {
            return store.state.passwordResetStatus.msg;
        }
    },
    methods: {
        requestLogin() {
            if(this.loginLoading) return;
            store.dispatch(ACTION.ACCOUNT_LOGIN, {
                username: this.loginUsername,
                password: this.password
            });
            this.password = '';
        },
        showSignupDialog() {
            closeAllDialogsAndPopups(store);
            store.commit(MUTATION.SHOW_SIGNUP_DIALOG, {
                show: true,
                username: this.loginUsername,
                teams: store.getters.teamsIFavorite.map(t => t.name),
                teamCaptain: false,
                name: ''
            });
        },
        closeDialog() {
            this.password = '';
            store.commit(MUTATION.SHOW_LOGIN_DIALOG, {
                show: false, username: ''
            });
        },
        forgotPassword() {
            if(!this.loginUsername || this.loginUsername === '') return;
            store.dispatch(ACTION.ACCOUNT_PWD_RESET, this.loginUsername);
        }
    },
    watch: {
        loginDialogVisible() {
            if(!this.loginDialogVisible) return;
            this.$nextTick(() => {
                if(this.loginUsername === '') (this.$refs.username as HTMLElement).focus();
                else (this.$refs.password as HTMLElement).focus();
            });
        },
        username() {
            this.loginUsername = this.username;
        }
    },
    mounted() {
        this.loginUsername = this.username;
    }
});
