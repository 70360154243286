
import { defineComponent } from 'vue';
import Popup from '@/components/lib/Popup.vue';
import { store } from '@/store/store';
import { MUTATION } from '@/store/mutations';
import { SPONSOR_TYPES } from '@/services/sponsors';
import { sponsorTypeEnumToDisplayName, sponsorTypeDisplayNameToEnum } from '@/utils.names';
import { POPUPS, POPUP_TYPES } from '@/constants';
import CSS from 'csstype';

export default defineComponent({
    name: 'TheSponsorTypeDropdown',
    components: { Popup },
    data() {
        return {
            id: POPUPS.SPONSOR_TYPE_DROPDOWN,
            dropdownType: POPUP_TYPES.DROP_DOWN
        }
    },
    computed: {
        types(): string[] {
            return Object.values(SPONSOR_TYPES).map(v => sponsorTypeEnumToDisplayName(v));
        },
        target(): HTMLElement | null {
            return store.getters.popupTarget(this.id);
        },
        isVisible(): boolean {
            return store.getters.isVisiblePopup(this.id);
        },
        dropdownPosition(): CSS.Properties {
            if(!this.target) return {} as CSS.Properties;

            const pos = this.target.getBoundingClientRect();
            return {
                top: pos.y + this.target.clientHeight + 'px',
                left: pos.x + 'px',
                width: pos.width + 'px'
            } as CSS.Properties
        }
    },
    methods: {
        select(idx: number) {
            store.commit(MUTATION.UPDATE_ADD_SPONSOR_DIALOG, {
                ...store.state.addSponsorDialogState.sponsor,
                sponsorType: sponsorTypeDisplayNameToEnum(this.types[idx]),
            });
            this.close();
        },
        close() {
            store.commit(MUTATION.HIDE_POPUP, this.id);
        }
    }
});
