
import { defineComponent } from 'vue';
import { TeamInterface } from '@/services/teams';
import { isColorLight } from '@/utils';

export default defineComponent({
    name: 'TeamBadge',
    props: {
        team: {
            type: Object as () => TeamInterface,
            required: false
        },
        link: {
            type: Boolean,
            required: false,
            default: true
        },
        customText: {
            type: String,
            required: false,
            default: '?'
        }
    },
    data() {
        return {
            class: 'badge'
        };
    },
    computed: {
        formattedText(): string {
            const teamName = this.team?.name ?? '';
            return teamName === ''?
                this.customText:
                teamName.charAt(0).toUpperCase();
        },
        badgeStyle(): {color: string, 'border-color': string, 'background-color': string} {
            const color = this.team? this.team.color: '#254d6d';
            return {
                'color': color,
                'border-color': color,
                'background-color': isColorLight(color)? '#a5a5a5': '#efefef'
            };
        },
        hasCustomLogo(): boolean {
            return this.team? this.team.logo !== undefined: false;
        }
    }
});
