import { ActionTree } from 'vuex';
import { closeAllDialogsAndPopups, MUTATION } from './mutations';
import { ClientUIState } from './state';
import { TStoreContext } from './store';
import { CURRENT_SEASON, LOCAL_NOTIFICATION_PFX, STATE } from '../constants';
import {
    defaultSessionInfo,
    deleteNotification,
    requestLogin,
    requestLogout,
    requestPasswordReset,
    savePreferences,
    sessionInfo,
    setLocalSession,
    TRequestLoginInput,
    TSavePreferences
} from '@/services/session';
import {
    addMatch,
    removeMatch,
    AddMatchInput,
    fetchSchedule,
    editMatch,
    EditMatchResultInput,
    editMatchResult,
    approveMatchResult,
    EditSeriesResultInput,
    editSeriesResult,
    approveSeriesResult,
    editSeries,
    addMatchFromFile,
    TAddMatchFromFile,
    MatchInterface
} from '@/services/matches';
import {
    removeFromFavorites,TeamInterface,PlayerInterface,addPlayer,AddPlayerInput,
    removePlayer, addToTeams,reorderTeamsList,TEditTeamInput,editTeam,addTeam,
    removeTeam,TAddTeamInput,fetchTeams,fetchPlayers,
} from '@/services/teams';
import { addSponsor, editSponsor, fetchSponsors, removeSponsor, TAddSponsorInput, TEditSponsorInput } from '../services/sponsors';
import { addField, AddFieldInput, fetchFields, removeField } from '@/services/fields';
import router from '@/router/router';
import { 
    AddFreeAgentInput,
    FreeAgentInterface,
    addFreeAgent,
    removeFreeAgent,
    requestAddFreeAgent,
    TRequestAddFreeAgentInput,
} from '@/services/freeagents';
import { fetchPendingUsers, fetchUsers, fetchAccount, TRequestAccountInput } from '@/services/users';
import { sendContactUsEmail, TRequestMessageInput } from '@/services/contactus';
import { formatDate } from '@/utils';
import { fetchSeriesMatchup, SeriesInterface } from '@/services/series';
import { fetchSeasonRankings, fetchSeriesRankings } from '@/services/stats';
import {
    editGeneralResource,
    fetchGeneralResourceList,
    removeGeneralResource,
    saveGeneralResource,
    fetchLeagueDescription,
    fetchJoinTeamMessage,
    TEditResource,
    TSetResource,
    fetchGeneralResource
} from '@/services/resources';
import {
    createBackup, fetchBackups, getBackup,
    removeBackup, restoreBackup
} from '@/services/backups';
import { fetchLogs } from '@/services/logs';
import { ArchiveInterface, createArchive, editArchiveName, fetchArchives } from '@/services/archives';
import { fetchAppConfig } from '@/services/config';
import { 
    fetchTimeslots,
    TTimeslotRequestInput,
    TimeslotInterface,
    TimeslotDataAddInput,
    addTimeslot,
    removeTimeslot,
    sendTimeslotRequest,
    denyTimeslotRequest,
    confirmTimeslotRequest,
    approveTimeslotReservation
} from '@/services/timeslots';

export const enum ACTION {
    FETCH_ACCOUNT = 'fetchAccount',
    FETCH_SESSION_INFO = 'fetchSessionInfo',
    FETCH_APP_CFG = 'fetchAppCfg',
    ACCOUNT_LOGIN = 'accountLogin',
    ACCOUNT_LOGOUT = 'accountLogout',
    ACCOUNT_PWD_RESET = 'accountPwdReset',
    FETCH_SCHEDULE = 'fetchSchedule',
    FETCH_SEASON_RANKINGS = 'fetchSeasonRankings',
    FETCH_SERIES_RANKINGS = 'fetchSeriesRankings',
    FETCH_SERIES_MATCHUP = 'fetchSeriesMatchup',
    FETCH_TIMESLOTS = 'fetchTimeslots',
    SAVE_PREFERENCES = 'savePreferences',
    ADD_MATCH = 'addMatch',
    ADD_MATCH_FROM_FILE = 'addMatchFromFile',
    FETCH_FIELDS = 'fetchFields',
    ADD_FIELD = 'addField',
    REMOVE_FIELD = 'removeField',
    REMOVE_MATCH = 'removeMatch',
    APPROVE_MATCH_SCORES = 'approveMatchScores',
    APPROVE_SERIES_SCORES = 'approveSeriesScores',
    EDIT_MATCH = 'editMatch',
    EDIT_MATCH_SCORES = 'editMatchScores',
    EDIT_SERIES = 'editSeries',
    EDIT_SERIES_SCORES = 'editSeriesScores',
    ADD_TO_FAVORITE_TEAM = 'addToFavoriteTeam',
    MAIN_FAVORITE_TEAM_SELECTED = 'reorderFavoriteTeamList',
    MAIN_CAPTAIN_TEAM_SELECTED = 'reorderCaptainTeamList',
    REMOVE_FROM_FAVORITE_TEAM = 'removeFromFavoriteTeam',
    FETCH_PLAYERS = 'fetchPlayers',
    FETCH_SPONSORS = 'fetchSponsors',
    EDIT_SPONSOR = 'editSponsor',
    ADD_SPONSOR = 'addSponsor',
    REMOVE_SPONSOR = 'removeSponsor',
    ADD_PLAYER = 'addPlayer',
    REMOVE_PLAYER='removePlayer',
    ADD_FREE_AGENT = 'addFreeAgent',
    REMOVE_FREE_AGENT = 'removeFreeAgent',
    SEND_ADD_ME_EMAIL = 'sendAddMeEmail',
    SEND_EMAIL_REQUEST = 'sendEmailRequest',
    DELETE_NOTIFICATION = 'deleteNotification',
    FETCH_RESOURCE_LIST = 'fetchGeneralResourceList',
    FETCH_RESOURCE = 'fetchGeneralResource',
    SET_RESOURCE = 'setResource',
    EDIT_RESOURCE = 'editGeneralResource',
    REMOVE_RESOURCE = 'removeGeneralResource',
    FETCH_LEAGUE_DESCRIPTION = 'fetchLeagueDescription',
    FETCH_JOIN_TEAM_MESSAGE = 'fetchJoinTeamMessage',
    FETCH_BACKUPS = 'fetchBackups',
    ADD_BACKUP = 'addBackup',
    GET_BACKUP = 'getBackup',
    REMOVE_BACKUP = 'removeBackup',
    RESTORE_BACKUP = 'restoreBackup',
    FETCH_USERS = 'fetchPendingUsers',
    FETCH_PENDING_USERS = 'fetchRDatabseLogs',
    FETCH_DATABASE_LOGS = 'fetchRDatabaseLogs',
    FETCH_TEAMS = 'fetchTeams',
    EDIT_TEAM = 'editTeam',
    ADD_TEAM = 'addTeam',
    REMOVE_TEAM = 'removeTeam',
    FETCH_ARCHIVES = 'fetchArchives',
    ADD_ARCHIVE = 'addArchive',
    EDIT_ARCHIVE = 'editArchive',
    SET_SEASON_VIEW = 'setSeasonView',
    APPROVE_TIMESLOT_RESERVATION = 'approveTimeslotReservation',
    CONFIRM_TIMESLOT_RESERVATION = 'confirmTimeslotReservation',
    SEND_TIMESLOT_REQUEST = 'sendTimeslotRequest',
    DENY_TIMESLOT_REQUEST = 'denyTimeslotRequest',
    ADD_TIMESLOT = 'addTimeslot',
    REMOVE_TIMESLOT = 'removeTimeslot',
}

export type TActions = {
    [ACTION.FETCH_ACCOUNT]              (store: TStoreContext, payload: TRequestAccountInput)           : Promise<void>;
    [ACTION.FETCH_SESSION_INFO]         (store: TStoreContext)                                          : Promise<void>;
    [ACTION.FETCH_APP_CFG]              (store: TStoreContext)                                          : Promise<void>;
    [ACTION.ACCOUNT_LOGIN]              (store: TStoreContext, payload: TRequestLoginInput)             : Promise<void>;
    [ACTION.ACCOUNT_LOGOUT]             (store: TStoreContext)                                          : Promise<void>;
    [ACTION.ACCOUNT_PWD_RESET]          (store: TStoreContext, username: string)                        : Promise<void>;
    [ACTION.SAVE_PREFERENCES]           (store: TStoreContext, payload: TSavePreferences)               : Promise<void>;
    [ACTION.ADD_TO_FAVORITE_TEAM]       (store: TStoreContext, team: TeamInterface)                     : Promise<void>;
    [ACTION.MAIN_FAVORITE_TEAM_SELECTED](store: TStoreContext, newTeam: TeamInterface)                  : Promise<void>;
    [ACTION.MAIN_CAPTAIN_TEAM_SELECTED] (store: TStoreContext, newTeam: TeamInterface)                  : Promise<void>;
    [ACTION.REMOVE_FROM_FAVORITE_TEAM]  (store: TStoreContext, team: TeamInterface)                     : Promise<void>;

    [ACTION.FETCH_TEAMS]                (store: TStoreContext)                                          : Promise<void>;
    [ACTION.EDIT_TEAM]                  (store: TStoreContext, payload: TEditTeamInput)                 : Promise<void>
    [ACTION.ADD_TEAM]                   (store: TStoreContext, payload: TAddTeamInput)                  : Promise<void>
    [ACTION.REMOVE_TEAM]                (store: TStoreContext, id: string)                              : Promise<void>

    [ACTION.FETCH_SEASON_RANKINGS]      (store: TStoreContext)                                          : Promise<void>;
    [ACTION.FETCH_SERIES_RANKINGS]      (store: TStoreContext)                                          : Promise<void>;
    [ACTION.FETCH_SERIES_MATCHUP]       (store: TStoreContext)                                          : Promise<void>;
    [ACTION.APPROVE_SERIES_SCORES]      (store: TStoreContext, matchID: string)                         : Promise<void>;
    
    [ACTION.EDIT_SERIES_SCORES]         (store: TStoreContext, payload: EditSeriesResultInput)          : Promise<void>;
    [ACTION.EDIT_SERIES]                (store: TStoreContext, payload: Partial<SeriesInterface>)       : Promise<void>;

    [ACTION.FETCH_TIMESLOTS]            (store: TStoreContext): Promise<void>;
    [ACTION.SEND_TIMESLOT_REQUEST]      (store: TStoreContext, payload: TTimeslotRequestInput)          : Promise<void>;
    [ACTION.DENY_TIMESLOT_REQUEST]      (store: TStoreContext, timeslot: TimeslotInterface)             : Promise<void>;
    [ACTION.ADD_TIMESLOT]               (store: TStoreContext, timeslot: TimeslotDataAddInput)          : Promise<void>;
    [ACTION.REMOVE_TIMESLOT]            (store: TStoreContext, timeslotID: string)                      : Promise<void>;
    [ACTION.APPROVE_TIMESLOT_RESERVATION](store: TStoreContext, timeslot: TimeslotInterface)            : Promise<void>;
    [ACTION.CONFIRM_TIMESLOT_RESERVATION](store: TStoreContext, timeslot: TimeslotInterface)            : Promise<void>;


    [ACTION.FETCH_SCHEDULE]             (store: TStoreContext): Promise<void>;
    [ACTION.ADD_MATCH]                  (store: TStoreContext, payload: AddMatchInput)                  : Promise<void>;
    [ACTION.ADD_MATCH_FROM_FILE]        (store: TStoreContext, payload: TAddMatchFromFile)              : Promise<void>;
    [ACTION.REMOVE_MATCH]               (store: TStoreContext, matchID: string)                         : Promise<void>;
    [ACTION.APPROVE_MATCH_SCORES]       (store: TStoreContext, matchID: string)                         : Promise<void>;
    [ACTION.EDIT_MATCH]                 (store: TStoreContext, payload: MatchInterface)                 : Promise<void>;
    [ACTION.EDIT_MATCH_SCORES]          (store: TStoreContext, payload: EditMatchResultInput)           : Promise<void>;
    
    [ACTION.FETCH_PLAYERS]              (store: TStoreContext)                                          : Promise<void>;
    [ACTION.ADD_PLAYER]                 (store: TStoreContext, player: AddPlayerInput)                  : Promise<void>;
    [ACTION.REMOVE_PLAYER]              (store: TStoreContext, player: PlayerInterface)                 : Promise<void>;

    [ACTION.FETCH_SPONSORS]             (store: TStoreContext)                                          : Promise<void>;
    [ACTION.EDIT_SPONSOR]               (store: TStoreContext, payload: TEditSponsorInput)              : Promise<void>;
    [ACTION.ADD_SPONSOR]                (store: TStoreContext, payload: TAddSponsorInput)               : Promise<void>;
    [ACTION.REMOVE_SPONSOR]             (store: TStoreContext, id: string)                              : Promise<void>;

    [ACTION.FETCH_FIELDS]               (store: TStoreContext)                                          : Promise<void>;
    [ACTION.REMOVE_FIELD]               (store: TStoreContext, fieldID: string)                         : Promise<void>;
    [ACTION.ADD_FIELD]                  (store: TStoreContext, payload: AddFieldInput)                  : Promise<void>;

    [ACTION.ADD_FREE_AGENT]             (store: TStoreContext, agent: AddFreeAgentInput)                : Promise<void>;
    [ACTION.REMOVE_FREE_AGENT]          (store: TStoreContext, agent: FreeAgentInterface)               : Promise<void>;

    [ACTION.SEND_ADD_ME_EMAIL]          (store: TStoreContext, emailData: TRequestAddFreeAgentInput)    : Promise<void>;
    [ACTION.SEND_EMAIL_REQUEST]         (store: TStoreContext, emailData: TRequestMessageInput)         : Promise<void>;
    [ACTION.DELETE_NOTIFICATION]        (store: TStoreContext, notificationID: string)                  : Promise<void>;

    [ACTION.FETCH_RESOURCE_LIST]        (store: TStoreContext)                                          : Promise<void>
    [ACTION.FETCH_RESOURCE]             (store: TStoreContext, resourceName: string)                    : Promise<string>
    [ACTION.SET_RESOURCE]               (store: TStoreContext, payload: TSetResource)                   : Promise<void>
    [ACTION.EDIT_RESOURCE]              (store: TStoreContext, payload: TEditResource)                  : Promise<void>
    [ACTION.REMOVE_RESOURCE]            (store: TStoreContext, resourceName: string)                    : Promise<void>

    [ACTION.FETCH_JOIN_TEAM_MESSAGE]    (store: TStoreContext)                                          : Promise<void>
    [ACTION.FETCH_LEAGUE_DESCRIPTION]   (store: TStoreContext)                                          : Promise<void>
    [ACTION.FETCH_USERS]                (store: TStoreContext)                                          : Promise<void>
    [ACTION.FETCH_PENDING_USERS]        (store: TStoreContext)                                          : Promise<void>
    [ACTION.FETCH_DATABASE_LOGS]        (store: TStoreContext)                                          : Promise<void>
    [ACTION.FETCH_BACKUPS]              (store: TStoreContext)                                          : Promise<void>
    [ACTION.GET_BACKUP]                 (store: TStoreContext, name: string)                            : Promise<void>
    [ACTION.ADD_BACKUP]                 (store: TStoreContext)                                          : Promise<void>
    [ACTION.REMOVE_BACKUP]              (store: TStoreContext, name: string)                            : Promise<void>
    [ACTION.RESTORE_BACKUP]             (store: TStoreContext, name: string)                            : Promise<void>
    [ACTION.FETCH_ARCHIVES]             (store: TStoreContext)                                          : Promise<void>
    [ACTION.ADD_ARCHIVE]                (store: TStoreContext)                                          : Promise<void>
    [ACTION.EDIT_ARCHIVE]               (store: TStoreContext, archive: ArchiveInterface)               : Promise<void>
    [ACTION.SET_SEASON_VIEW]            (store: TStoreContext, seasonName: string)                      : Promise<void>
};

export const actions: ActionTree<ClientUIState, ClientUIState> & TActions = {
    [ACTION.FETCH_ACCOUNT]: async function(store, payload) {
        const {username, password, name, role, teams} = payload;
        store.commit(MUTATION.SET_SIGNUP_STATUS, {
            status: STATE.LOADING,
            msg: ''
        });
        
        try {
            const resp = await fetchAccount({username, password, name, role, teams});
            if (resp.success) {
                store.commit(MUTATION.SET_SIGNUP_STATUS, {
                    status: STATE.DONE,
                    msg: ''}
                );
                store.commit(MUTATION.SHOW_SIGNUP_DIALOG, {
                    show: false, name: '', username: '', teamCaptain: false, teams: []
                });
            }
            else {
                store.commit(MUTATION.SET_SIGNUP_STATUS, {
                    status: STATE.ERR,
                    msg: resp.error ?? ''
                });
            }
        }
        catch (err) {
            console.error(`ERROR while requesting new account: ${err}`);
            store.commit(MUTATION.SET_SIGNUP_STATUS, {
                status: STATE.ERR,
                msg: err as string
            });
        }
    },
    [ACTION.FETCH_SESSION_INFO]: async function(store) {
        try {
            const resp = await sessionInfo();
            store.commit(MUTATION.SET_SESSION_INFO, resp);
        }
        catch (err) {
            console.error(`ERROR while syncing session data: ${err}`);
        }

    },
    [ACTION.FETCH_APP_CFG]: async function(store) {
        try {
            const {cfg} = await fetchAppConfig();
            store.commit(MUTATION.SET_APP_UIC, cfg.uic);
            store.commit(MUTATION.SET_APP_PJC, cfg.pjc);
        } catch (err) {
            console.warn('Could not fetch App configuration');
            console.error(err);
        }
    },

    [ACTION.ACCOUNT_LOGIN]: async function(store, payload) {
        const {username, password, auth} = payload;
        store.commit(MUTATION.SET_LOGIN_STATUS, {
            status: STATE.LOADING,
            msg: ''
        });
        
        try {
            const resp = await requestLogin({username, password, auth});
            if (resp.success) {
                store.commit(MUTATION.SET_SESSION_INFO, resp.sessionInfo);
                store.commit(MUTATION.SET_LOGIN_STATUS, {
                    status: STATE.DONE,
                    msg: ''}
                );
                store.commit(MUTATION.SHOW_LOGIN_DIALOG, {
                    show: false, username: ''
                });

                const redirect = router.currentRoute.value.query.redirect as string;
                if(redirect) {
                    await router.replace(redirect);
                    location.reload();
                }
            }
            else {
                store.commit(MUTATION.SET_LOGIN_STATUS, {
                    status: STATE.ERR,
                    msg: resp.error ?? ''
                });
            }
        }
        catch (err) {
            console.error(`ERROR while login: ${err}`);
            store.commit(MUTATION.SET_LOGIN_STATUS, {
                status: STATE.ERR,
                msg: err as string
            });
        }
        
    },
    [ACTION.ACCOUNT_LOGOUT]: async function(store){
        store.commit(MUTATION.SET_LOGIN_STATUS, {
            status: STATE.LOADING, msg: ''}
        );
        try {
            const resp = await requestLogout();
            if (resp.success) {
                store.commit(MUTATION.SET_LOGIN_STATUS, {
                    status: STATE.DONE,
                    msg: ''
                });
            }
            else {
                store.commit(MUTATION.SET_LOGIN_STATUS, {
                    status: STATE.ERR,
                    msg: resp.error ?? ''
                });
            }
            store.commit(MUTATION.SET_SESSION_INFO, resp.sessionInfo);
        }
        catch (err){
            store.commit(MUTATION.SET_LOGIN_STATUS, {
                status: STATE.ERR,
                msg: err as string
            });
            store.commit(MUTATION.SET_SESSION_INFO, defaultSessionInfo());
        }

        // Those are private!!! reset on logout!
        store.commit(MUTATION.SET_TIMESLOTS, {
            booked: [], available: [], confirmed: [], pending: []
        });
        store.commit(MUTATION.SET_TIMESLOTS_REQUEST_STATUS, {
            msg: '', status: STATE.STALE
        });
        // Those are private!!! reset on logout!
        store.commit(MUTATION.SET_BACKUPS, []);
        store.commit(MUTATION.SET_BACKUP_REQUEST_STATUS, {
            msg: '', status: STATE.STALE
        });
        store.commit(MUTATION.SET_DBLOG_REQUEST_STATUS, {
            msg: '', status: STATE.STALE
        });
        store.commit(MUTATION.SET_PENDING_USER_REQUEST_STATUS, {
            msg: '', status: STATE.STALE
        });
        store.commit(MUTATION.SET_USER_REQUEST_STATUS, {
            msg: '', status: STATE.STALE
        });
        store.commit(MUTATION.SET_ARCHIVE_REQUEST_STATUS, {
            msg: '', status: STATE.STALE
        });
        store.commit(MUTATION.SET_DBLOGS, [])
        store.commit(MUTATION.SET_PENDING_USERS, [])
        store.commit(MUTATION.SET_USERS, []);
        router.replace(`/`);
    },
    [ACTION.ACCOUNT_PWD_RESET]: async function(store, username){
        store.commit(MUTATION.SET_PASSWORD_RESET_STATUS, {
            status: STATE.LOADING, msg: ''}
        );
        const resp = await requestPasswordReset(username);
        if (resp.success) {
            store.commit(MUTATION.SET_PASSWORD_RESET_STATUS, {
                status: STATE.DONE,
                msg: 'A login link was sent to your email address'
            });
        }
        else {
            store.commit(MUTATION.SET_PASSWORD_RESET_STATUS, {
                status: STATE.ERR,
                msg: resp.error ?? ''
            });
        }
    },
    [ACTION.FETCH_TEAMS]: async function (store) {
        store.commit(MUTATION.SET_TEAMS_REQUEST_STATUS, {
            status: STATE.LOADING, msg: ''
        });

        try {
            const season = store.state.currentSeasonView === CURRENT_SEASON? undefined: store.state.currentSeasonView
            const teams = await fetchTeams(season);
            store.commit(MUTATION.SET_TEAMS, teams);
            store.commit(MUTATION.SET_TEAMS_REQUEST_STATUS, {
                status: STATE.DONE, msg: ''
            });
        } catch (err) {
            store.commit(MUTATION.SET_TEAMS_REQUEST_STATUS, {
                status: STATE.ERR, msg: 'Could not load Team Info'
            });
            throw err;
        }
    },
    [ACTION.FETCH_SCHEDULE]: async function(store) {
        const series = store.dispatch(ACTION.FETCH_SERIES_MATCHUP);
        store.commit(MUTATION.SET_SCHEDULE_REQUEST_STATUS, {
           status: STATE.LOADING, msg: ''
        });
        try {
            const season = store.state.currentSeasonView === CURRENT_SEASON? undefined: store.state.currentSeasonView
            const resp = await fetchSchedule(season);
            store.commit(MUTATION.SET_SCHEDULE, resp);
            store.commit(MUTATION.SET_SCHEDULE_REQUEST_STATUS, {
                status: STATE.DONE, msg: ''
            });
        } catch(err) {
            store.commit(MUTATION.SET_SCHEDULE_REQUEST_STATUS, {
               status: STATE.ERR, msg: err as string
            });
        }
        await series;
    },
    [ACTION.FETCH_SEASON_RANKINGS]: async function(store) {
        store.commit(MUTATION.SET_SEASON_RANKINGS_REQUEST_STATUS, {
           status: STATE.LOADING, msg: ''
        });
        try {
            const season = store.state.currentSeasonView === CURRENT_SEASON? undefined: store.state.currentSeasonView
            const resp = await fetchSeasonRankings(season);
            store.commit(MUTATION.SET_SEASON_RANKINGS, resp);
            store.commit(MUTATION.SET_SEASON_RANKINGS_REQUEST_STATUS, {
                status: STATE.DONE, msg: ''
            });
        } catch(err) {
            store.commit(MUTATION.SET_SEASON_RANKINGS_REQUEST_STATUS, {
               status: STATE.ERR, msg: err as string
            });
        }
    },
    [ACTION.FETCH_SERIES_RANKINGS]: async function(store) {
        store.commit(MUTATION.SET_SERIES_RANKINGS_REQUEST_STATUS, {
           status: STATE.LOADING, msg: ''
        });
        try {
            const season = store.state.currentSeasonView === CURRENT_SEASON? undefined: store.state.currentSeasonView
            const resp = await fetchSeriesRankings(season);
            store.commit(MUTATION.SET_SERIES_RANKINGS, resp);
            store.commit(MUTATION.SET_SERIES_RANKINGS_REQUEST_STATUS, {
                status: STATE.DONE, msg: ''
            });
        } catch(err) {
            store.commit(MUTATION.SET_SERIES_RANKINGS_REQUEST_STATUS, {
               status: STATE.ERR, msg: err as string
            });
        }
    },
    [ACTION.FETCH_TIMESLOTS]: async function(store) {
        store.commit(MUTATION.SET_TIMESLOTS_REQUEST_STATUS, {
            status: STATE.LOADING, msg: ''
         });
         try {
             const resp = await fetchTimeslots();
             store.commit(MUTATION.SET_TIMESLOTS, resp);
             store.commit(MUTATION.SET_TIMESLOTS_REQUEST_STATUS, {
                 status: STATE.DONE, msg: ''
             });
         } catch(err) {
             store.commit(MUTATION.SET_TIMESLOTS_REQUEST_STATUS, {
                status: STATE.ERR, msg: err as string
             });
         }
    },
    [ACTION.FETCH_SERIES_MATCHUP]: async function(store) {
        store.commit(MUTATION.SET_SERIES_REQUEST_STATUS, {
           status: STATE.LOADING, msg: ''
        });
        try {
            const season = store.state.currentSeasonView === CURRENT_SEASON? undefined: store.state.currentSeasonView
            const matchups = await fetchSeriesMatchup(season);
            await store.dispatch(ACTION.FETCH_SEASON_RANKINGS, undefined);
            await store.dispatch(ACTION.FETCH_SERIES_RANKINGS, undefined);
            store.commit(MUTATION.SET_SERIES_MATCHUP, matchups);
            store.commit(MUTATION.SET_SERIES_REQUEST_STATUS, {
                status: STATE.DONE, msg: ''
            });
        } catch(err) {
            store.commit(MUTATION.SET_SERIES_REQUEST_STATUS, {
               status: STATE.ERR, msg: err as string
            });
        }
    },
    [ACTION.FETCH_FIELDS]: async function(store) {
        store.commit(MUTATION.SET_FIELDS_REQUEST_STATUS, {
           status: STATE.LOADING, msg: ''
        });
        try {
            const season = store.state.currentSeasonView === CURRENT_SEASON? undefined: store.state.currentSeasonView
            const resp = await fetchFields(season);
            store.commit(MUTATION.SET_FIELDS, resp);
            store.commit(MUTATION.SET_FIELDS_REQUEST_STATUS, {
                status: STATE.DONE, msg: ''
            });
        } catch(err) {
            store.commit(MUTATION.SET_FIELDS_REQUEST_STATUS, {
               status: STATE.ERR, msg: err as string
            });
        }
    },
    [ACTION.FETCH_PLAYERS]: async function(store) {
        store.commit(MUTATION.SET_PLAYERS_REQUEST_STATUS, {
           status: STATE.LOADING, msg: ''
        });
        try {
            const season = store.state.currentSeasonView === CURRENT_SEASON? undefined: store.state.currentSeasonView
            const resp = await fetchPlayers(season);
            store.commit(MUTATION.SET_PLAYERS, resp);
            store.commit(MUTATION.SET_PLAYERS_REQUEST_STATUS, {
                status: STATE.DONE, msg: ''
            });
        } catch(err) {
            store.commit(MUTATION.SET_PLAYERS_REQUEST_STATUS, {
               status: STATE.ERR, msg: err as string
            });
        }
    },
    [ACTION.FETCH_SPONSORS]: async function(store) {
        store.commit(MUTATION.SET_SPONSORS_REQUEST_STATUS, {
           status: STATE.LOADING, msg: ''
        });
        try {
            const {sponsors} = await fetchSponsors();
            store.commit(MUTATION.SET_SPONSORS, sponsors);
            store.commit(MUTATION.SET_SPONSORS_REQUEST_STATUS, {
                status: STATE.DONE, msg: ''
            });
        } catch(err) {
            store.commit(MUTATION.SET_SPONSORS_REQUEST_STATUS, {
               status: STATE.ERR, msg: err as string
            });
        }
    },
    [ACTION.REMOVE_SPONSOR]: async function(store, id) {
        store.commit(MUTATION.REMOVE_SPONSOR_REQUEST_STATUS, {
            status: STATE.LOADING, msg: '', id, 
        });
        try {
            const resp = await removeSponsor(id);
            if(!resp.success) throw resp.error;

            store.commit(MUTATION.REMOVE_SPONSOR_REQUEST_STATUS, {
                id, status: STATE.DONE, msg: ''
            });
            store.commit(MUTATION.REMOVE_SPONSOR, id);
            
        } catch (err) {
            store.commit(MUTATION.REMOVE_SPONSOR_REQUEST_STATUS, {
                id, status: STATE.ERR, msg: 'Could not remove sponsor'
            });
        }
    },
    [ACTION.EDIT_SPONSOR]: async function(store, payload) {
        store.commit(MUTATION.EDIT_SPONSOR_REQUEST_STATUS, {
            status: STATE.LOADING, msg: '', id: payload.sponsor._id, 
        });
        try {
            const resp = await editSponsor(payload);
            if(!resp.success) throw resp.error;

            store.commit(MUTATION.EDIT_SPONSOR_REQUEST_STATUS, {
                id: payload.sponsor._id, status: STATE.DONE, msg: ''
            });

            store.commit(MUTATION.SET_SPONSOR, resp.sponsor);

            closeAllDialogsAndPopups(store);
        } catch (err) {
            store.commit(MUTATION.EDIT_SPONSOR_REQUEST_STATUS, {
                id: payload.sponsor._id, status: STATE.ERR, msg: 'Could not edit sponsor'
            });
        }
    },
    [ACTION.ADD_SPONSOR]: async function(store, payload) {
        store.commit(MUTATION.ADD_SPONSOR_REQUEST_STATUS, {
            status: STATE.LOADING, msg: ''
        });
        try {
            const resp = await addSponsor(payload);
            if(!resp.success || !resp.sponsor) throw resp.error;

            store.commit(MUTATION.ADD_SPONSOR_REQUEST_STATUS, {
                status: STATE.DONE, msg: ''
            });

            store.commit(MUTATION.ADD_SPONSOR, resp.sponsor);
            closeAllDialogsAndPopups(store);
        } catch (err) {
            store.commit(MUTATION.ADD_SPONSOR_REQUEST_STATUS, {
                status: STATE.ERR, msg: 'Could not add sponsor'
            });
        }
    },

    [ACTION.SAVE_PREFERENCES]: async function(store, payload){
        store.commit(MUTATION.SAVE_PREFERENCES_STATUS, {
            status: STATE.LOADING, msg: ''
        });
        const resp = await savePreferences(payload);
        if(resp.success){
            store.commit(MUTATION.SET_SESSION_INFO, resp.sessionInfo);
            store.commit(MUTATION.SAVE_PREFERENCES_STATUS, {
                status: STATE.DONE, msg: ''
            });
        }
        else {
            store.commit(MUTATION.SAVE_PREFERENCES_STATUS, {
                status: STATE.ERR, msg: resp.error ?? ''
            })
        }
    },
    [ACTION.ADD_MATCH]: async function(store, payload){
        store.commit(MUTATION.SET_ADD_MATCH_STATUS, {
            status: STATE.LOADING, msg: ''}
        );
        const resp = await addMatch(payload);
        if (resp.success) {
            store.commit(MUTATION.SET_SCHEDULE, resp.schedule);
            store.commit(MUTATION.SET_ADD_MATCH_STATUS, {
                status: STATE.DONE,
                msg: ''
            });

            // if user is on schedule page, replace URL, else, do nothing
            if(router.currentRoute.value.fullPath.indexOf('/schedule') >= 0) {
                await router.replace(
                    `/schedule#${formatDate(payload.date)}`
                );
            }
        }
        else {
            store.commit(MUTATION.SET_ADD_MATCH_STATUS, {
                status: STATE.ERR,
                msg: 'Le match n\'a pas pu être ajouté'
            });
        }
    },
    [ACTION.ADD_MATCH_FROM_FILE]: async function(store, payload){
        store.commit(MUTATION.SET_ADD_MATCH_STATUS, {
            status: STATE.LOADING, msg: ''}
        );
        const resp = await addMatchFromFile(payload.data, payload.opts);
        store.commit(MUTATION.SET_ADD_MATCH_REPORT, resp.report);
        if (resp.success) {
            store.commit(MUTATION.SET_SCHEDULE, resp.schedule);
            store.commit(MUTATION.SET_ADD_MATCH_STATUS, {
                status: STATE.DONE,
                msg: ''
            });

            if(store.state.timeslotsRequestStatus.status === STATE.DONE) {
                store.dispatch(ACTION.FETCH_TIMESLOTS);
            }

            // if user is on schedule page, replace URL, else, do nothing
            if(router.currentRoute.value.fullPath.indexOf('/schedule') >= 0) {
                await router.replace(`/schedule`);
            }
        }
        else {
            store.commit(MUTATION.SET_ADD_MATCH_STATUS, {
                status: STATE.ERR,
                msg: `Erreurs lors de l'import des matchs provenant du fichier ${payload.data.fileName}`
            });
        }
    },
    [ACTION.ADD_FIELD]: async function(store, payload){
        store.commit(MUTATION.SET_ADD_FIELD_STATUS, {
            status: STATE.LOADING, msg: ''}
        );
        const resp = await addField(payload);
        if (resp.success) {
            const fields = resp.data;
            store.commit(MUTATION.SET_FIELDS, fields);
            store.commit(MUTATION.SET_ADD_FIELD_STATUS, {
                status: STATE.DONE,
                msg: ''
            });
        }
        else {
            store.commit(MUTATION.SET_ADD_FIELD_STATUS, {
                status: STATE.ERR,
                msg: `Failed to add field ${payload}`
            });
        }
    },
    [ACTION.REMOVE_FIELD]: async function(store, fieldID){
        store.commit(MUTATION.SET_REMOVE_FIELD_STATUS, {
            status: STATE.LOADING, id: fieldID, msg: ''}
        );
        try {
            const resp = await removeField(fieldID);
            if (resp.success) {
                const fields = resp.data;
                store.commit(MUTATION.SET_FIELDS, fields);
                store.commit(MUTATION.SET_REMOVE_FIELD_STATUS, {
                    status: STATE.DONE,
                    id: fieldID,
                    msg: ''
                });
            }
            else {
                store.commit(MUTATION.SET_REMOVE_FIELD_STATUS, {
                    status: STATE.ERR,
                    id: fieldID,
                    msg: `Failed to remove field ${fieldID}`
                });
            }
        } catch (err) {
            store.commit(MUTATION.SET_REMOVE_FIELD_STATUS, {
                status: STATE.ERR,
                id: fieldID,
                msg: `Failed to remove field`
            });
        }
    },
    [ACTION.REMOVE_MATCH]: async function(store, matchID){
        store.commit(MUTATION.SET_REMOVE_MATCH_STATUS, {
            status: STATE.LOADING, msg: '', id: matchID
        });
        const resp = await removeMatch(matchID);
        if (resp.success) {
            const schedule = resp.schedule;
            store.commit(MUTATION.SET_SCHEDULE, schedule);
            store.dispatch(ACTION.FETCH_TIMESLOTS);
            store.commit(MUTATION.SET_REMOVE_MATCH_STATUS, {
                status: STATE.DONE, msg: '', id: matchID
            });
        }
        else {
            store.commit(MUTATION.SET_REMOVE_MATCH_STATUS, {
                status: STATE.ERR, msg: `Failed to remove match ${matchID}`, id: matchID
            });
        }
    },
    [ACTION.APPROVE_MATCH_SCORES]: async function(store, matchID){
        store.commit(MUTATION.SET_EDIT_MATCH_STATUS, {
            status: STATE.LOADING, msg: '', id: matchID
        });
        const resp = await approveMatchResult(matchID);
        if (resp.success) {
            store.commit(MUTATION.SET_SCHEDULE, resp.schedule);
            store.commit(MUTATION.SET_EDIT_MATCH_STATUS, {
                status: STATE.DONE,
                msg: '',
                id: matchID
            });
        }
        else {
            store.commit(MUTATION.SET_EDIT_MATCH_STATUS, {
                status: STATE.ERR,
                msg: `Failed to approve match ${matchID}`,
                id: matchID
            });
        }
    },
    [ACTION.APPROVE_SERIES_SCORES]: async function(store, matchID){
        store.commit(MUTATION.SET_EDIT_SERIES_STATUS, {
            status: STATE.LOADING, msg: '', id: matchID
        });
        const resp = await approveSeriesResult(matchID);
        if (resp.success) {
            store.commit(MUTATION.SET_SERIES_MATCHUP, resp.schedule);

            store.dispatch(ACTION.FETCH_SEASON_RANKINGS, undefined);
            store.dispatch(ACTION.FETCH_SERIES_RANKINGS, undefined);

            store.commit(MUTATION.SET_EDIT_SERIES_STATUS, {
                status: STATE.DONE,
                msg: '',
                id: matchID
            });
        }
        else {
            store.commit(MUTATION.SET_EDIT_SERIES_STATUS, {
                status: STATE.ERR,
                msg: `Failed to approve match ${matchID}`,
                id: matchID
            });
        }
    },
    [ACTION.EDIT_MATCH]: async function(store, match){
        store.commit(MUTATION.SET_EDIT_MATCH_STATUS, {
            status: STATE.LOADING, msg: '', id: match._id
        });
        const resp = await editMatch(match);
        if (resp.success) {
            store.commit(MUTATION.SET_SCHEDULE, resp.schedule);
            store.dispatch(ACTION.FETCH_TIMESLOTS);
            store.commit(MUTATION.SET_EDIT_MATCH_STATUS, {
                status: STATE.DONE,
                msg: '',
                id: match._id
            });
        }
        else {
            store.commit(MUTATION.SET_EDIT_MATCH_STATUS, {
                status: STATE.ERR,
                msg: `Failed to edit match ${match._id}`,
                id: match._id
            });
        }
    },
    [ACTION.EDIT_SERIES]: async function(store, match){
        if(!match._id) throw 'Invalid match ID';
        store.commit(MUTATION.SET_EDIT_SERIES_STATUS, {
            status: STATE.LOADING, msg: '', id: match._id
        });
        const resp = await editSeries(match);
        if (resp.success) {
            store.commit(MUTATION.SET_SERIES_MATCHUP, resp.schedule);

            store.dispatch(ACTION.FETCH_SEASON_RANKINGS, undefined);
            store.dispatch(ACTION.FETCH_SERIES_RANKINGS, undefined);
            store.commit(MUTATION.SET_EDIT_SERIES_STATUS, {
                status: STATE.DONE,
                msg: '',
                id: match._id
            });
        }
        else {
            store.commit(MUTATION.SET_EDIT_SERIES_STATUS, {
                status: STATE.ERR,
                msg: `Failed to edit match ${match._id}`,
                id: match._id
            });
        }
    },
    [ACTION.EDIT_MATCH_SCORES]: async function(store, scores){
        store.commit(MUTATION.SET_EDIT_MATCH_STATUS, {
            status: STATE.LOADING, msg: '', id: scores._id
        });
        const resp = await editMatchResult(scores);
        if (resp.success) {
            store.commit(MUTATION.SET_SCHEDULE, resp.schedule);
            store.commit(MUTATION.SET_EDIT_MATCH_STATUS, {
                status: STATE.DONE,
                msg: '',
                id: scores._id
            });
        }
        else {
            store.commit(MUTATION.SET_EDIT_MATCH_STATUS, {
                status: STATE.ERR,
                msg: `Failed to edit match ${scores._id}`,
                id: scores._id
            });
        }
    },
    [ACTION.EDIT_SERIES_SCORES]: async function(store, payload){
        store.commit(MUTATION.SET_EDIT_SERIES_STATUS, {
            status: STATE.LOADING, msg: '', id: payload._id
        });
        const resp = await editSeriesResult(payload);
        if (resp.success) {
            store.commit(MUTATION.SET_SERIES_MATCHUP, resp.schedule);

            store.dispatch(ACTION.FETCH_SEASON_RANKINGS, undefined);
            store.dispatch(ACTION.FETCH_SERIES_RANKINGS, undefined);

            store.commit(MUTATION.SET_EDIT_SERIES_STATUS, {
                status: STATE.DONE,
                msg: '',
                id: payload._id
            });
        }
        else {
            store.commit(MUTATION.SET_EDIT_SERIES_STATUS, {
                status: STATE.ERR,
                msg: `Failed to edit match ${payload._id}`,
                id: payload._id
            });
        }
    },
    [ACTION.ADD_TO_FAVORITE_TEAM]: async function(store, team){
        store.commit(MUTATION.SET_ADD_TO_FAVORITE_TEAM_STATUS, {
            status: STATE.LOADING, msg: ''}
        );
        try {
            if(store.state.sessionInfo.loggedIn){
                const resp = await addToTeams(team);
                store.commit(MUTATION.SET_SESSION_INFO, resp.sessionInfo);
                if (resp.success) {
                    store.commit(MUTATION.SET_ADD_TO_FAVORITE_TEAM_STATUS, {
                        status: STATE.DONE,
                        msg: ''
                    });
                }
                else throw `Failed to add team ${team.name} to favorites`;
            }
            else {
                store.commit(MUTATION.ADD_TO_FAVORITE_TEAM, [team]);
                store.commit(MUTATION.SET_ADD_TO_FAVORITE_TEAM_STATUS, {
                    status: STATE.DONE,
                    msg: ''
                });
                setLocalSession(store.state);
            }
        } catch (err) {
            store.commit(MUTATION.SET_ADD_TO_FAVORITE_TEAM_STATUS, {
                status: STATE.ERR,
                msg: err as string
            });
        }
    },
    [ACTION.MAIN_FAVORITE_TEAM_SELECTED]: async function(store, teamSelected){
        const list =  store.getters.teamsIFavorite.slice();
        const idx = list.findIndex(value => value.name === teamSelected.name);
        try {
            if(idx === -1) {
                throw `Team not found in the list of favorite teams ${teamSelected.name}`;
            }
            list.unshift(...list.splice(idx, 1));
            if(store.state.sessionInfo.loggedIn){
                const resp = await reorderTeamsList(list);
                store.commit(MUTATION.SET_SESSION_INFO, resp.sessionInfo);
                if (!resp.success) {
                    throw `Failed to reorder favorite team list`;
                }
            }
            else {
                store.commit(MUTATION.SET_FAVORITE_TEAMS, list);
                setLocalSession(store.state);
            }
            await router.replace(`/teams/${encodeURI(teamSelected.name)}`);
        } catch (err) {
            console.error(err);
        }
    },
    [ACTION.MAIN_CAPTAIN_TEAM_SELECTED]: async function(store, teamSelected){
        const list =  store.getters.teamsICaptain.slice();
        const idx = list.findIndex(value => value.name === teamSelected.name);
        try {
            if(idx === -1) {
                throw `Team not found in the list of teams you captain ${teamSelected.name}`;
            }
            list.unshift(...list.splice(idx, 1));
            if(store.state.sessionInfo.loggedIn){
                const resp = await reorderTeamsList(list);
                store.commit(MUTATION.SET_SESSION_INFO, resp.sessionInfo);
                if (!resp.success) {
                    throw `Failed to reorder favorite team list`;
                }
            }
            else {
                store.commit(MUTATION.SET_CAPTAIN_TEAMS, list);
                setLocalSession(store.state);
            }
            await router.replace(`/teams/${encodeURI(teamSelected.name)}`);
        } catch (err) {
            console.error(err);
        }
    },
    [ACTION.REMOVE_FROM_FAVORITE_TEAM]: async function(store, team){
        store.commit(MUTATION.SET_ADD_TO_FAVORITE_TEAM_STATUS, {
            status: STATE.LOADING, msg: ''}
        );
        try {
            if(store.state.sessionInfo.loggedIn){
                const resp = await removeFromFavorites(team);
                store.commit(MUTATION.SET_SESSION_INFO, resp.sessionInfo);
                if (resp.success) {
                    store.commit(MUTATION.SET_ADD_TO_FAVORITE_TEAM_STATUS, {
                        status: STATE.DONE,
                        msg: ''
                    });
                }
                else throw `Failed to add team ${team.name} to favorites`;
            }
            else {
                store.commit(MUTATION.REMOVE_FROM_FAVORITE_TEAM, [team]);
                store.commit(MUTATION.SET_ADD_TO_FAVORITE_TEAM_STATUS, {
                    status: STATE.DONE,
                    msg: ''
                });
                setLocalSession(store.state);
            }
        } catch (err) {
            store.commit(MUTATION.SET_ADD_TO_FAVORITE_TEAM_STATUS, {
                status: STATE.ERR,
                msg: err as string
            });
        }
    },
    [ACTION.ADD_PLAYER]: async function(store, newPlayer){
        store.commit(MUTATION.SET_ADD_PLAYER_STATUS, {
            status: STATE.LOADING, msg: ''}
        );
        const resp = await addPlayer(newPlayer);

        if (resp.success) {
            store.commit(MUTATION.SET_PLAYERS, resp.data);
            store.commit(MUTATION.SET_ADD_PLAYER_STATUS, {
                status: STATE.DONE,
                msg: ''
            });
        }
        else {
            store.commit(MUTATION.SET_ADD_PLAYER_STATUS, {
                status: STATE.ERR,
                msg: `Failed to add player ${newPlayer.name}`
            });
        }
    },
    [ACTION.REMOVE_PLAYER]: async function(store, oldPlayer){
        store.commit(MUTATION.SET_REMOVE_PLAYER_STATUS, {
            status: STATE.LOADING, msg: ''}
        );
        const resp = await removePlayer(oldPlayer._id);
        if (resp.success) {
            store.commit(MUTATION.SET_PLAYERS, resp.data);
            store.commit(MUTATION.SET_REMOVE_PLAYER_STATUS, {
                status: STATE.DONE,
                msg: ''
            });
        }
        else {
            store.commit(MUTATION.SET_REMOVE_PLAYER_STATUS, {
                status: STATE.ERR,
                msg: `Failed to remove player ${oldPlayer._id}`
            });
        }
    },
    [ACTION.ADD_FREE_AGENT]: async function(store, newAgent){
        store.commit(MUTATION.SET_ADD_PLAYER_STATUS, {
            status: STATE.LOADING, msg: ''}
        );
        const resp = await addFreeAgent(newAgent);

        if (resp.success) {
            store.commit(MUTATION.SET_FREE_AGENTS, resp.data);
            store.commit(MUTATION.SET_ADD_PLAYER_STATUS, {
                status: STATE.DONE,
                msg: 'Free agent was added'
            });
        }
        else {
            store.commit(MUTATION.SET_ADD_PLAYER_STATUS, {
                status: STATE.ERR,
                msg: `Failed to add free agent ${newAgent.name}`
            });
        }
    },
    [ACTION.REMOVE_FREE_AGENT]: async function(store, oldAgent){
        store.commit(MUTATION.SET_REMOVE_PLAYER_STATUS, {
            status: STATE.LOADING, msg: ''}
        );
        const resp = await removeFreeAgent(oldAgent._id);
        if (resp.success) {
            store.commit(MUTATION.SET_FREE_AGENTS, resp.data);
            store.commit(MUTATION.SET_REMOVE_PLAYER_STATUS, {
                status: STATE.DONE,
                msg: 'Free agent was removed'
            });
        }
        else {
            store.commit(MUTATION.SET_REMOVE_PLAYER_STATUS, {
                status: STATE.ERR,
                msg: `Failed to remove free agent ${oldAgent._id}`
            });
        }
    },
    [ACTION.SEND_ADD_ME_EMAIL]: async function(store, emailData) {
        store.commit(MUTATION.SET_ADD_ME_EMAIL_STATUS, {
            status: STATE.LOADING, msg: ''}
        );
        const resp = await requestAddFreeAgent(emailData);

        if (resp.success) {
            store.commit(MUTATION.SET_ADD_ME_EMAIL_STATUS, {
                status: STATE.DONE,
                msg: 'Free agent was added'
            });
        }
        else {
            store.commit(MUTATION.SET_ADD_ME_EMAIL_STATUS, {
                status: STATE.ERR,
                msg: `Failed to send email for free agent ${emailData.name}`
            });
        }
    },
    [ACTION.SEND_EMAIL_REQUEST]: async function(store, emailData) {
        store.commit(MUTATION.SET_EMAIL_REQUEST_STATUS, {
            status: STATE.LOADING, msg: ''}
        );
        const resp = await sendContactUsEmail(emailData);

        if (resp.success) {
            store.commit(MUTATION.SET_EMAIL_REQUEST_STATUS, {
                status: STATE.DONE,
                msg: 'Email was sent'
            });
        }
        else {
            store.commit(MUTATION.SET_EMAIL_REQUEST_STATUS, {
                status: STATE.ERR,
                msg: `Failed to send email from ${emailData.name} (${emailData.email})`
            });
        }
    },
    [ACTION.CONFIRM_TIMESLOT_RESERVATION]: async function(store, timeslot) {
        store.commit(MUTATION.SET_TIMESLOT_RESERVATION_REQUEST_STATUS, {
            status: STATE.LOADING, msg: '', id: timeslot._id
        });

        const resp = await confirmTimeslotRequest(timeslot._id);

        if (resp.success) {
            store.commit(MUTATION.SET_TIMESLOTS, resp.timeslotSchedule);
            store.commit(MUTATION.SET_TIMESLOT_RESERVATION_REQUEST_STATUS, {
                status: STATE.DONE,
                id: timeslot._id,
                msg: 'Timeslot was confirmed by captain, admin is to approve',
            });
        }
        else {
             store.commit(MUTATION.SET_TIMESLOT_RESERVATION_REQUEST_STATUS, {
                status: STATE.ERR,
                id: timeslot._id,
                msg: `Failed to confirm timeslot request for match (${formatDate(timeslot.date)}, ${timeslot.field})`
            });
        }

    },
    [ACTION.APPROVE_TIMESLOT_RESERVATION]: async function (store, timeslot) {        
        store.commit(MUTATION.SET_TIMESLOT_RESERVATION_REQUEST_STATUS, {
            status: STATE.LOADING, msg: '', id: timeslot._id
        });

        const resp = await approveTimeslotReservation(timeslot);

        if (resp.success) {
            store.commit(MUTATION.SET_TIMESLOTS, resp.timeslotSchedule);
            store.commit(MUTATION.SET_TIMESLOT_RESERVATION_REQUEST_STATUS, {
                status: STATE.DONE,
                id: timeslot._id,
                msg: 'Match was rescheduled, timeslot is now booked',
            });
        }
        else {
             store.commit(MUTATION.SET_TIMESLOT_RESERVATION_REQUEST_STATUS, {
                status: STATE.ERR,
                id: timeslot._id,
                msg: `Failed to reschedule match (${formatDate(timeslot.date)}, ${timeslot.field})`
            });
        }
    },
    [ACTION.SEND_TIMESLOT_REQUEST]: async function(store, payload) {
        store.commit(MUTATION.SET_TIMESLOT_RESERVATION_REQUEST_STATUS, {
            status: STATE.LOADING, msg: '', id: payload.timeslot._id}
        );
        const resp = await sendTimeslotRequest(payload);
        if (resp.success) {
            store.commit(MUTATION.SET_TIMESLOTS, resp.timeslotSchedule);

            store.commit(MUTATION.SET_TIMESLOT_RESERVATION_REQUEST_STATUS, {
                status: STATE.DONE,
                id: payload.timeslot._id,
                msg: 'Email was sent'
            });
        } 
        else {
            store.commit(MUTATION.SET_TIMESLOT_RESERVATION_REQUEST_STATUS, {
                status: STATE.ERR,
                id: payload.timeslot._id,
                msg: `Failed to send email from ${
                    payload.timeslot.reservation?.requestingTeam
                } to reschedule the ${
                payload.timeslot.reservation?.requestingTeam
                } vs ${
                payload.timeslot.reservation?.confirmingTeam
                } match`
            });
        }

    },
    [ACTION.DENY_TIMESLOT_REQUEST]: async function(store, timeslot) {
        store.commit(MUTATION.SET_TIMESLOT_RESERVATION_REQUEST_STATUS, {
            status: STATE.LOADING, msg: '', id: timeslot._id}
        );
        
        try {
            const resp = await denyTimeslotRequest(timeslot._id);
            if (resp.success) {
                store.commit(MUTATION.SET_TIMESLOTS, resp.timeslotSchedule);
                store.commit(MUTATION.SET_TIMESLOT_RESERVATION_REQUEST_STATUS, {
                    status: STATE.DONE,
                    id: timeslot._id,
                    msg: 'Email was sent'
                });
            }
            else {
                throw `Failed to deny reservation request (${formatDate(timeslot.date)}, ${timeslot.field})`
            }
        } catch(err) {
            store.commit(MUTATION.SET_TIMESLOT_RESERVATION_REQUEST_STATUS, {
                status: STATE.ERR,
                id: timeslot._id,
                msg: typeof err === 'string'? err: 'Unknown Error'
            });
            console.error(err);
        }
    },
    [ACTION.ADD_TIMESLOT]: async function(store, newTimeslot){
        store.commit(MUTATION.SET_ADD_TIMESLOT_STATUS, {
            status: STATE.LOADING, msg: ''}
        );
        const resp = await addTimeslot(newTimeslot);

        if (resp.success) {
            store.commit(MUTATION.SET_TIMESLOTS, resp.timeslotSchedule);
            store.commit(MUTATION.SET_ADD_TIMESLOT_STATUS, {
                status: STATE.DONE,
                msg: 'Timeslot was added'
            });
        }
        else {
            store.commit(MUTATION.SET_ADD_TIMESLOT_STATUS, {
                status: STATE.ERR,
                msg: `Failed to add timeslot on ${newTimeslot.date}`
            });
        }
    },
    [ACTION.REMOVE_TIMESLOT]: async function(store, timeslotID){
        store.commit(MUTATION.SET_REMOVE_TIMESLOT_STATUS, {
            status: STATE.LOADING, id: timeslotID, msg: ''}
        );
        const resp = await removeTimeslot(timeslotID);
        if (resp.success) {
            store.commit(MUTATION.SET_TIMESLOTS, resp.timeslotSchedule);
            store.commit(MUTATION.SET_REMOVE_TIMESLOT_STATUS, {
                status: STATE.DONE,
                id: timeslotID,
                msg: 'Free agent was removed'
            });
        }
        else {
            store.commit(MUTATION.SET_REMOVE_TIMESLOT_STATUS, {
                status: STATE.ERR,
                id: timeslotID,
                msg: `Failed to remove timeslot (${timeslotID})`
            });
        }
    },
    [ACTION.DELETE_NOTIFICATION]: async function(store, notificationID) {
        store.commit(MUTATION.DELETE_NOTIFICATION, notificationID);
        if(notificationID.startsWith(LOCAL_NOTIFICATION_PFX)) return;
        await deleteNotification(notificationID);
    },

    [ACTION.SET_RESOURCE]: async function(store, payload) {
        try {
            const saveResp = await saveGeneralResource(payload.title, {data: payload.content});
            if(saveResp.error !== '') {
                throw saveResp.error;
            }
            if(router.currentRoute.value.path.indexOf('/resources/') !== -1){
                router.replace(`/resources/${payload.title}`);
            }
        } catch (err) {
            store.commit(MUTATION.ADD_TMP_NOTIFICATION,
                (err as unknown as string).toString()
            );
        }
        try {
            const list = await fetchGeneralResourceList();
            store.commit(MUTATION.SET_RESOURCE_LIST, list);
            store.commit(MUTATION.SET_RESOURCE_LIST_REQUEST_STATUS, {
                status: STATE.DONE, msg: ''
            });
        } catch (err) {
            store.commit(MUTATION.SET_RESOURCE_LIST_REQUEST_STATUS, {
                status: STATE.ERR, msg: 'Could not retrieve resource list'
            });
        }
    },
    [ACTION.EDIT_RESOURCE]: async function(store, payload) {
        try {
            const saveResp = await editGeneralResource(payload.resource, {newName: payload.title, data: payload.content});
            if(saveResp.error !== '') {
                throw saveResp.error;
            }
            if(router.currentRoute.value.path.indexOf(`/resources/${payload.resource}`) !== -1){
                router.replace(`/resources/${payload.title}`);
            }
        } catch (err) {
            store.commit(MUTATION.ADD_TMP_NOTIFICATION,
                (err as unknown as string).toString()
            );
        }
        try {
            const list = await fetchGeneralResourceList();
            store.commit(MUTATION.SET_RESOURCE_LIST, list);
            store.commit(MUTATION.SET_RESOURCE_LIST_REQUEST_STATUS, {
                status: STATE.DONE, msg: ''
            });
        } catch (err) {
            store.commit(MUTATION.SET_RESOURCE_LIST_REQUEST_STATUS, {
                status: STATE.ERR, msg: 'Could not retrieve resource list'
            });
        }
    },
    [ACTION.REMOVE_RESOURCE]: async function(store, resourceName) {
        try {
            const resp = await removeGeneralResource(resourceName);
            if(resp.error !== '') {
                throw resp.error;
            }
            if(router.currentRoute.value.path.endsWith(`/resources/${resourceName}`)){
                router.replace(`/`); // move to home page
            }
        } catch (err) {
            store.commit(MUTATION.ADD_TMP_NOTIFICATION,
                (err as unknown as string).toString()
            );
        }
        try {
            const list = await fetchGeneralResourceList();
            store.commit(MUTATION.SET_RESOURCE_LIST, list);
            store.commit(MUTATION.SET_RESOURCE_LIST_REQUEST_STATUS, {
                status: STATE.DONE, msg: ''
            });
        } catch (err) {
            store.commit(MUTATION.SET_RESOURCE_LIST_REQUEST_STATUS, {
                status: STATE.ERR, msg: 'Could not retrieve resource list'
            });
        }
    },


    [ACTION.FETCH_RESOURCE_LIST]: async function(store) {
        store.commit(MUTATION.SET_RESOURCE_LIST_REQUEST_STATUS, {
            status: STATE.LOADING, msg: ''
        });
        try {
            const list = await fetchGeneralResourceList();
            store.commit(MUTATION.SET_RESOURCE_LIST, list);
            store.commit(MUTATION.SET_RESOURCE_LIST_REQUEST_STATUS, {
                status: STATE.DONE, msg: ''
            });
        } catch (err) {
            store.commit(MUTATION.SET_RESOURCE_LIST_REQUEST_STATUS, {
                status: STATE.ERR, msg: 'Could not retrieve resource list'
            });
        }
    },
    [ACTION.FETCH_RESOURCE]: async function(store, resourceName: string) {
        store.commit(MUTATION.SET_RESOURCE_REQUEST_STATUS, {
            resource: resourceName, status: {status: STATE.LOADING, msg: ''}
        });
        try {
            const resource = await fetchGeneralResource(resourceName);
            store.commit(MUTATION.SET_RESOURCE_REQUEST_STATUS, {
                resource: resourceName, status: {status: STATE.DONE, msg: ''}
            });

            return resource;
        } catch (err) {
            store.commit(MUTATION.SET_RESOURCE_REQUEST_STATUS, {
                resource: resourceName, status: {status: STATE.ERR, msg: `Could not retrieve rousrce <${resourceName}>`}
            });
            return '';
        }
    },

    [ACTION.FETCH_LEAGUE_DESCRIPTION]: async function(store) {
        store.commit(MUTATION.SET_LEAGUE_DESCRIPTION_REQUEST_STATUS, {
            status: STATE.LOADING, msg: ''
        });
        try {
            const descr = await fetchLeagueDescription();
            store.commit(MUTATION.SET_LEAGUE_DESCRIPTION, {
                short: descr.short, long: descr.long
            });
            store.commit(MUTATION.SET_LEAGUE_DESCRIPTION_REQUEST_STATUS, {
                status: STATE.DONE, msg: ''
            });
        } catch (err) {
            store.commit(MUTATION.SET_LEAGUE_DESCRIPTION_REQUEST_STATUS, {
                status: STATE.ERR, msg: 'Could not retrieve league description'
            });
        }
    },

    [ACTION.FETCH_JOIN_TEAM_MESSAGE]: async function(store) {
        store.commit(MUTATION.SET_JOIN_TEAM_MESSAGE_REQUEST_STATUS, {
            status: STATE.LOADING, msg: ''
        });
        try {
            const joinTeamMsg = await fetchJoinTeamMessage();
            store.commit(MUTATION.SET_JOIN_TEAM_MESSAGE, joinTeamMsg);
            store.commit(MUTATION.SET_JOIN_TEAM_MESSAGE_REQUEST_STATUS, {
                status: STATE.DONE, msg: ''
            });
        } catch (err) {
            store.commit(MUTATION.SET_JOIN_TEAM_MESSAGE_REQUEST_STATUS, {
                status: STATE.ERR, msg: 'Could not retrieve join team message'
            });
        }
    },

    [ACTION.FETCH_ARCHIVES]: async function(store) {
        store.commit(MUTATION.SET_ARCHIVE_REQUEST_STATUS, {
            status: STATE.LOADING, msg: ''
        });
        try {
            const list = await fetchArchives();
            store.commit(MUTATION.SET_ARCHIVES, list);
            store.commit(MUTATION.SET_ARCHIVE_REQUEST_STATUS, {
                status: STATE.DONE, msg: ''
            });
        } catch (err) {
            store.commit(MUTATION.SET_ARCHIVE_REQUEST_STATUS, {
                status: STATE.ERR, msg: 'Could not retrieve archives'
            });
        }
    },
    [ACTION.ADD_ARCHIVE]: async function (store) {
        store.commit(MUTATION.SET_ADD_ARCHIVE_REQUEST_STATUS, {
            msg: '', status: STATE.LOADING
        });
        try {
            const list = await createArchive();
            if(!list.success) {
                store.commit(MUTATION.SET_ADD_ARCHIVE_REQUEST_STATUS, {
                    status: STATE.ERR, msg: list.error
                });
            }
            else {
                store.commit(MUTATION.SET_ARCHIVES, list.archives);
                store.commit(MUTATION.SET_ADD_ARCHIVE_REQUEST_STATUS, {
                    status: STATE.DONE, msg: ''
                });
            }
            if(list.seriesUpdated) {
                await store.dispatch(ACTION.FETCH_SERIES_MATCHUP, undefined);
            }
            if(list.matchUpdated) {
                await store.dispatch(ACTION.FETCH_SCHEDULE, undefined);
            }

            // If timeslots were requested, request again the new ones!

            if(list.timeslotUpdated && store.state.timeslotsRequestStatus.status !== STATE.STALE) {
                await store.dispatch(ACTION.FETCH_TIMESLOTS, undefined);
            }
        } catch (err) {
            store.commit(MUTATION.SET_ADD_ARCHIVE_REQUEST_STATUS, {
                status: STATE.ERR, msg: 'Could not archive current season'
            });
        }
    },
    [ACTION.EDIT_ARCHIVE]: async function(store, archive: ArchiveInterface){
        store.commit(MUTATION.SET_ARCHIVE_REQUEST_STATUS, {
            msg: '', status: STATE.LOADING
        });
        try {
            const updatedArchiveList = await editArchiveName(archive) as ArchiveInterface[];
            store.commit(MUTATION.SET_ARCHIVES, updatedArchiveList);
        } catch (err) {
            store.commit(MUTATION.SET_ARCHIVE_REQUEST_STATUS, {
                msg: 'Unable to edit archive', status: STATE.ERR
            }); 
        }
        store.commit(MUTATION.SET_ARCHIVE_REQUEST_STATUS, {
            msg: '', status: STATE.DONE
        });
    },
    [ACTION.SET_SEASON_VIEW]: async function (store, seasonName: string) {
        store.commit(MUTATION.SET_SEASON_VIEW, seasonName);

        if(store.state.fieldsRequestStatus.status !== STATE.STALE) {
            store.dispatch(ACTION.FETCH_FIELDS, undefined);
        }
        if(store.state.playersRequestStatus.status !== STATE.STALE) {
            store.dispatch(ACTION.FETCH_PLAYERS, undefined);
        }
        if(store.state.teamsRequestStatus.status !== STATE.STALE) {
            store.dispatch(ACTION.FETCH_TEAMS, undefined);
        }
        if(store.state.scheduleRequestStatus.status !== STATE.STALE) {
            store.dispatch(ACTION.FETCH_SCHEDULE, undefined);
        }
        if(store.state.seriesRequestStatus.status !== STATE.STALE) {
            store.dispatch(ACTION.FETCH_SERIES_MATCHUP, undefined);
        }
        if(store.state.seriesRankingRequestStatus.status !== STATE.STALE) {
            store.dispatch(ACTION.FETCH_SERIES_RANKINGS, undefined);
        }
        if(store.state.seasonRankingRequestStatus.status !== STATE.STALE) {
            store.dispatch(ACTION.FETCH_SEASON_RANKINGS, undefined);
        }
    },

    [ACTION.FETCH_BACKUPS]: async function(store) {
        store.commit(MUTATION.SET_BACKUP_REQUEST_STATUS, {
            status: STATE.LOADING, msg: ''
        });
        try {
            const list = await fetchBackups();
            store.commit(MUTATION.SET_BACKUPS, list);
            store.commit(MUTATION.SET_BACKUP_REQUEST_STATUS, {
                status: STATE.DONE, msg: ''
            });
        } catch (err) {
            store.commit(MUTATION.SET_BACKUP_REQUEST_STATUS, {
                status: STATE.ERR, msg: 'Could not retrieve backups'
            });
        }
    },
    [ACTION.GET_BACKUP]: async function(store, name) {
        store.commit(MUTATION.GET_BACKUP_REQUEST_STATUS, {
            status: STATE.LOADING, msg: ''
        });
        try {
            await getBackup(name);
            store.commit(MUTATION.GET_BACKUP_REQUEST_STATUS, {
                status: STATE.DONE, msg: ''
            });
        } catch (err) {
            store.commit(MUTATION.GET_BACKUP_REQUEST_STATUS, {
                status: STATE.ERR, msg: 'Could not download backup'
            });
        }
    },
    [ACTION.ADD_BACKUP]: async function (store) {
        store.commit(MUTATION.SET_ADD_BACKUP_REQUEST_STATUS, {
            msg: '', status: STATE.LOADING
        });
        try {
            const list = await createBackup();
            store.commit(MUTATION.SET_BACKUPS, list);
            store.commit(MUTATION.SET_ADD_BACKUP_REQUEST_STATUS, {
                status: STATE.DONE, msg: ''
            });
        } catch (err) {
            store.commit(MUTATION.SET_ADD_BACKUP_REQUEST_STATUS, {
                status: STATE.ERR, msg: err as unknown as string
            });
        }
        
    },
    [ACTION.REMOVE_BACKUP]: async function (store, name) {
        store.commit(MUTATION.SET_REMOVE_BACKUP_REQUEST_STATUS, {
            msg: '', status: STATE.LOADING
        });
        try {
            const list = await removeBackup(name);
            store.commit(MUTATION.SET_BACKUPS, list);
            store.commit(MUTATION.SET_REMOVE_BACKUP_REQUEST_STATUS, {
                status: STATE.DONE, msg: ''
            });
        } catch (err) {
            store.commit(MUTATION.SET_REMOVE_BACKUP_REQUEST_STATUS, {
                status: STATE.ERR, msg: 'Could not delete backup'
            });
        }
    },
    [ACTION.RESTORE_BACKUP]: async function (store, name) {
        store.commit(MUTATION.SET_RESTORE_BACKUP_REQUEST_STATUS, {
            msg: '', status: STATE.LOADING
        });
        try {
            const list = await restoreBackup(name);
            store.commit(MUTATION.SET_BACKUPS, list);
            store.commit(MUTATION.SET_RESTORE_BACKUP_REQUEST_STATUS, {
                status: STATE.DONE, msg: ''
            });
            window.location.reload();
        } catch (err) {
            store.commit(MUTATION.SET_RESTORE_BACKUP_REQUEST_STATUS, {
                status: STATE.ERR, msg: 'Could not restore backup'
            });
        }
    },

    [ACTION.FETCH_USERS]: async function(store) {
        store.commit(MUTATION.SET_USER_REQUEST_STATUS, {
            status: STATE.LOADING, msg: ''
        });
        try {
            const list = await fetchUsers();
            store.commit(MUTATION.SET_USERS, list);
            store.commit(MUTATION.SET_USER_REQUEST_STATUS, {
                status: STATE.DONE, msg: ''
            });
        } catch (err) {
            store.commit(MUTATION.SET_USER_REQUEST_STATUS, {
                status: STATE.ERR, msg: 'Could not retrieve users'
            });
        }
    },
    [ACTION.FETCH_PENDING_USERS]: async function(store) {
        store.commit(MUTATION.SET_PENDING_USER_REQUEST_STATUS, {
            status: STATE.LOADING, msg: ''
        });
        try {
            const list = await fetchPendingUsers();
            store.commit(MUTATION.SET_PENDING_USERS, list);
            store.commit(MUTATION.SET_PENDING_USER_REQUEST_STATUS, {
                status: STATE.DONE, msg: ''
            });
        } catch (err) {
            store.commit(MUTATION.SET_PENDING_USER_REQUEST_STATUS, {
                status: STATE.ERR, msg: 'Could not retrieve account requests'
            });
        }
    },
    [ACTION.FETCH_DATABASE_LOGS]: async function(store) {
        store.commit(MUTATION.SET_DBLOG_REQUEST_STATUS, {
            status: STATE.LOADING, msg: ''
        });
        try {
            const list = await fetchLogs();
            store.commit(MUTATION.SET_DBLOGS, list);
            store.commit(MUTATION.SET_DBLOG_REQUEST_STATUS, {
                status: STATE.DONE, msg: ''
            });
        } catch (err) {
            store.commit(MUTATION.SET_DBLOG_REQUEST_STATUS, {
                status: STATE.ERR, msg: 'Could not retrieve server logs'
            });
        }
    },

    [ACTION.REMOVE_TEAM]: async function(store, id) {
        store.commit(MUTATION.REMOVE_TEAM_REQUEST_STATUS, {
            status: STATE.LOADING, msg: '', id, 
        });
        try {
            const resp = await removeTeam(id);
            if(!resp.success) throw resp.error;

            store.commit(MUTATION.REMOVE_TEAM_REQUEST_STATUS, {
                id, status: STATE.DONE, msg: ''
            });
            const oldTeam = store.state.teams.find(t => t._id === id);
            if(oldTeam && router.currentRoute.value.path.endsWith(encodeURI(oldTeam.name))){
                await router.replace('/teams');
            }
            
            store.commit(MUTATION.REMOVE_TEAM, id);
            if(resp.players) store.commit(MUTATION.SET_PLAYERS, resp.players);
            if(resp.users) store.commit(MUTATION.SET_USERS, resp.users);
            if(resp.pendingUsers) store.commit(MUTATION.SET_PENDING_USERS, resp.pendingUsers);
            if(resp.schedule) store.commit(MUTATION.SET_SCHEDULE, resp.schedule);
            if(resp.freeAgents) store.commit(MUTATION.SET_FREE_AGENTS, resp.freeAgents);
            if(resp.timeslots) store.commit(MUTATION.SET_TIMESLOTS, resp.timeslots);
            if(resp.series) {
                store.commit(MUTATION.SET_SERIES_MATCHUP, {series: resp.series});

                store.dispatch(ACTION.FETCH_SEASON_RANKINGS, undefined);
                store.dispatch(ACTION.FETCH_SERIES_RANKINGS, undefined);
            }
            
        } catch (err) {
            store.commit(MUTATION.REMOVE_TEAM_REQUEST_STATUS, {
                id, status: STATE.ERR, msg: 'Could not remove team'
            });
        }
    },
    [ACTION.EDIT_TEAM]: async function(store, payload) {
        store.commit(MUTATION.EDIT_TEAM_REQUEST_STATUS, {
            status: STATE.LOADING, msg: '', id: payload.team._id, 
        });
        try {
            const resp = await editTeam(payload);
            if(!resp.success) throw resp.error;

            store.commit(MUTATION.EDIT_TEAM_REQUEST_STATUS, {
                id: payload.team._id, status: STATE.DONE, msg: ''
            });
            const oldTeam = store.state.teams.find(t => t._id === payload.team._id);

            store.commit(MUTATION.SET_TEAM, payload.team);
            if(oldTeam && router.currentRoute.value.path.endsWith(encodeURI(oldTeam.name))){
                await router.replace(`/teams/${encodeURI(payload.team.name)}`);
            }

            if(resp.players) store.commit(MUTATION.SET_PLAYERS, resp.players);
            if(resp.users) store.commit(MUTATION.SET_USERS, resp.users);
            if(resp.pendingUsers) store.commit(MUTATION.SET_PENDING_USERS, resp.pendingUsers);
            if(resp.schedule) store.commit(MUTATION.SET_SCHEDULE, resp.schedule);
            if(resp.freeAgents) store.commit(MUTATION.SET_FREE_AGENTS, resp.freeAgents);
            if(resp.timeslots) store.commit(MUTATION.SET_TIMESLOTS, resp.timeslots);
            if(resp.series) {
                store.commit(MUTATION.SET_SERIES_MATCHUP, {series: resp.series});

                store.dispatch(ACTION.FETCH_SEASON_RANKINGS, undefined);
                store.dispatch(ACTION.FETCH_SERIES_RANKINGS, undefined);
            }

            closeAllDialogsAndPopups(store);
            
        } catch (err) {
            store.commit(MUTATION.EDIT_TEAM_REQUEST_STATUS, {
                id: payload.team._id, status: STATE.ERR, msg: 'Could not edit team'
            });
        }
    },
    [ACTION.ADD_TEAM]: async function(store, payload) {
        store.commit(MUTATION.ADD_TEAM_REQUEST_STATUS, {
            status: STATE.LOADING, msg: ''
        });
        try {
            const resp = await addTeam(payload);
            if(!resp.success || !resp.team) throw resp.error;

            store.commit(MUTATION.ADD_TEAM_REQUEST_STATUS, {
                status: STATE.DONE, msg: ''
            });

            store.commit(MUTATION.ADD_TEAM, resp.team);
            closeAllDialogsAndPopups(store);
        } catch (err) {
            store.commit(MUTATION.ADD_TEAM_REQUEST_STATUS, {
                status: STATE.ERR, msg: 'Could not add team'
            });
        }
    }
};
