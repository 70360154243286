import axios from 'axios';
import { FIELD_TYPE_VALUES } from '@/constants';
import { ldpCredentialsRequest } from './serverEngine';
import { REQUEST } from './documentation';

export interface FieldInterface {
    _id: string;
    name: string;
    coordinates: [number, number];
    type: FIELD_TYPE_VALUES,
    city: string,
    country: string,
    state: string
}

export type AddFieldInput = Omit <FieldInterface, '_id'>;
export type FieldOutput = {
    success: boolean;
    data: FieldInterface[];
};

export async function addField(req: AddFieldInput): Promise<FieldOutput> {
    return (await ldpCredentialsRequest(`/api/fields/add`, REQUEST.POST, req)) as FieldOutput;
}

export async function removeField(fieldID: string): Promise<FieldOutput> {
    return (await ldpCredentialsRequest(`/api/fields/remove/${fieldID}`, REQUEST.DELETE)) as FieldOutput;
}

export async function fetchFields(season?: string): Promise<FieldInterface[]> {
    let route = '/api/fields';
    if (season) {
        route += `?season=${encodeURI(season)}`;
    }
    const resp = await axios.get(route);
    return resp.data as FieldInterface[];
}

export async function fetchField(id: string, season?: string): Promise<FieldInterface> {
    let route = `/api/fields/${id}`;
    if (season) {
        route += `?season=${encodeURI(season)}`;
    }
    const resp = await axios.get(route);
    return resp.data as FieldInterface;
}

export async function fetchGoogleApiKey(): Promise<string> {
    return (await ldpCredentialsRequest(`/api/keys/google`, REQUEST.GET)) as string;
}

export default {};
