import { REQUEST } from "./documentation";
import axios, { AxiosError } from 'axios';
import router from "@/router/router";
import { store } from "@/store/store";

// eslint-disable-next-line
export async function ldpCredentialsRequest(service: string, type: REQUEST, req: any = {}): Promise<any> {
    try {
        if(type === REQUEST.GET) {
            const resp =await axios.get(service, {withCredentials: true, params: req});
            return resp.data;
        }
        else if(type === REQUEST.POST){
            const resp = await axios.post(service, req, {withCredentials: true});
            return resp.data;
        }
        else if(type === REQUEST.DELETE){
            const resp = await axios.delete(service, {withCredentials: true, params: req});
            return resp.data;
        }
        else if(type === REQUEST.PUT){
            const resp = await axios.put(service, req, {withCredentials: true});
            return resp.data;
        }
        throw `Invalid request type ${type}`;
    } catch (err) {
        const httpcode = (err as AxiosError)?.response?.status;
        console.error(err);
        if(httpcode === 401) {// bad credentials
            const to = router.currentRoute.value.fullPath;
            window.location.href = `/myaccount/login?username=${store.state.sessionInfo.email}&redirect=${to}`;
        }
        else throw err; //any other errors
    }
}