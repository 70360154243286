
import { defineComponent } from 'vue';
import CheckboxFilter from './CheckboxFilter.vue';
import Datepicker from 'vue3-datepicker';
import { store } from '@/store/store';
import { MUTATION } from '@/store/mutations';
import { CHECKBOX_TYPE } from '@/constants';
import { DATE_FILTERS } from '@/utils';

export default defineComponent({
    name: 'TheDateFilter',
    components: {
        Datepicker, CheckboxFilter
    },
    data() {
        return {
            startDateFilter: new Date(),
            endDateFilter: new Date(),
            RADIO: CHECKBOX_TYPE.RADIO
        }
    },
    props: {
        filterSetName: {
            type: String,
            required: true
        }
    },
    computed: {
        hasFilterApplied(): boolean {
            return store.getters.filterSet(this.filterSetName).dateRangeFilter.length > 0;
        },
        hasCustomFilterApplied(): boolean {
            return this.hasFilterApplied && store.getters.filterSet(this.filterSetName).dateRangeFilter[2] === 1;
        },
        filterValues(): string[] {
            if(this.hasFilterApplied) {
                return [DATE_FILTERS[store.getters.filterSet(this.filterSetName).dateRangeFilter[2]].displayName];
            }
            else return [];
        },
        dates(): string[] {
            return DATE_FILTERS.map(d => d.displayName);
        }
    },
    methods: {
        clearFilter() {
            store.commit(MUTATION.SET_DATE_RANGE_FILTERS, {filterSet: this.filterSetName, dateRange: []});
            store.commit(MUTATION.SET_SELECTED_MATCH_IDX, -1);
        },
        updateFilter(dates: number[]) {
            store.commit(MUTATION.SET_DATE_RANGE_FILTERS, {
                filterSet: this.filterSetName, dateRange: dates
            });
            store.commit(MUTATION.SET_SELECTED_MATCH_IDX, -1);
        },
        updateFilterFromDispName(dates: string[]) {
            if(!dates.length) {
                this.clearFilter();
                return;
            }
            DATE_FILTERS.forEach((d, ix) => {
                if(d.displayName === dates[0]) {
                    store.commit(MUTATION.SET_DATE_RANGE_FILTERS, {
                        filterSet: this.filterSetName,
                        dateRange: [
                            d.dateMin.getTime(),
                            d.dateMax.getTime(),
                            ix
                        ]
                    });
                    store.commit(MUTATION.SET_SELECTED_MATCH_IDX, -1);
                    return;
                }
            });
        }
    },
    mounted() {
        if(this.hasFilterApplied){
            this.startDateFilter = new Date(store.getters.filterSet(this.filterSetName).dateRangeFilter[0]);
            this.endDateFilter = new Date(store.getters.filterSet(this.filterSetName).dateRangeFilter[1]);
        }
    },
    watch: {
        startDateFilter(val: Date) {
            this.updateFilter([val.getTime(), this.endDateFilter.getTime(), 1]);
        },
        endDateFilter(val: Date) {
            this.updateFilter([this.startDateFilter.getTime(), val.getTime(), 1]);
        }
    }
});
