
import { defineComponent } from 'vue';
import Dialog from '@/components/lib/Dialog.vue';
import TeamLabel from '@/components/TeamLabel.vue';
import { store } from '@/store/store';
import { MUTATION } from '@/store/mutations';
import { TeamInterface } from '@/services/teams';
import { STATE } from '@/constants';
import { ACTION } from '@/store/actions';
import { resourceDisplayName, RESOURCE_TEMPLATE } from '@/utils.resources';
import { ArchiveInterface } from '@/services/archives';

export default defineComponent({
    name: 'TheMainMenu',
    components: { Dialog, TeamLabel },
    data() {
        return {
            RESOURCE_TEMPLATE: RESOURCE_TEMPLATE,
            editArchiveMode: '',
            editArchiveName: '',
        }
    },
    computed: {
        isAdmin(): boolean {
            return store.getters.isAdmin;
        },
        canViewRainouts(): boolean {
            return store.getters.isAdmin || store.getters.isCaptain;
        },
        canViewDocumentation(): boolean {
            return store.getters.isDev;
        },
        canEditSponsors(): boolean {
            return store.getters.isAdmin;
        },
        teamsIFavorite(): TeamInterface[] {
            return store.getters.teamsIFavorite;
        },
        teamsICaptain(): TeamInterface[] {
            return store.getters.teamsICaptain;
        },
        hasSponsors(): boolean {
            return store.state.sponsors.length > 0;
        },
        mainMenuVisible(): boolean {
            return store.state.mainMenuVisible;
        },
        mainMenuInfoVisible(): boolean {
            return store.state.mainMenuInfoVisible;
        },
        resourcesLoading(): boolean {
            return store.state.resourceListRequestStatus.status === STATE.LOADING
        },
        resources(): string[] {
            return store.state.resourceList;
        },
        mainMenuArchiveVisible(): boolean {
            return store.state.mainMenuArchiveVisible;
        },
        archivesLoading(): boolean {
            return store.state.archiveRequestStatus.status === STATE.LOADING
        },
        archives(): ArchiveInterface[] {
            return store.state.archives;
        },
        hasArchives(): boolean {
            return store.state.archives.length > 0;
        },
        hasInfo(): boolean{
            return store.state.resourceList.length > 0;
        }
    },
    methods: {
        closeDialog() {
            this.cancelArchiveEdits();
            store.commit(MUTATION.SHOW_MAIN_MENU, false);
        },
        toggleMainMenuInfo(show?: boolean) {
            if(show === undefined) show = !store.state.mainMenuInfoVisible;
            store.commit(MUTATION.SHOW_MAIN_MENU_INFO, show);
        },
        prettyResource(resource: string) {
            return resourceDisplayName(resource)
        },
        toggleMainMenuArchive(show?: boolean) {
            if(show === undefined) show = !store.state.mainMenuArchiveVisible;
            store.commit(MUTATION.SHOW_MAIN_MENU_ARCHIVE, show);
        },
        viewArchive(archiveName: string) {
            store.dispatch(ACTION.SET_SEASON_VIEW, archiveName);
        },
        cancelArchiveEdits() {
            this.editArchiveMode = '';
        },
        isEditingArchive(archiveName: string): boolean {
            return  this.editArchiveMode === archiveName;
        },
        saveArchiveEdits(archive: ArchiveInterface) {
            const updateObj = {
                name: this.editArchiveName,
                startDate: archive.startDate,
                stopDate: archive.stopDate } as ArchiveInterface

            store.dispatch(ACTION.EDIT_ARCHIVE, updateObj);
            this.editArchiveMode = '';
            this.editArchiveName = '';
        },
        editArchive(archive: ArchiveInterface) {
            this.editArchiveMode = archive.name;
            this.editArchiveName = archive.name;
        }
    },
    mounted() {
        if(store.state.resourceListRequestStatus.status === STATE.STALE) {
            store.dispatch(ACTION.FETCH_RESOURCE_LIST, undefined);
        }
        if(store.state.archiveRequestStatus.status === STATE.STALE) {
            store.dispatch(ACTION.FETCH_ARCHIVES, undefined);
        }
    }
});
