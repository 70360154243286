
import { defineComponent } from 'vue';
import Dialog from '@/components/lib/Dialog.vue';
import TheColorPicker from './TheColorPicker.vue';
import TeamLabel from './TeamLabel.vue';
import FileUpload from './lib/FileUpload.vue';
import { emptyTeamDef } from '@/utils';
import { store } from '@/store/store';
import { MUTATION } from '@/store/mutations';
import { TeamInterface } from '@/services/teams';
import { NO_POOLS, POPUPS, STATE } from '@/constants';
import { POPUP_DIRECTION } from '@/store/state';
import { ACTION } from '@/store/actions';
import CSS from 'csstype';

export default defineComponent({
    name: 'TheTeamAddDialog',
    components: {
        Dialog, TeamLabel, FileUpload, TheColorPicker
    },
    data() {
        return {
            addTeam: emptyTeamDef(),
            addTeamLogo: {
                fileName: null as string | null,
                fileBlob: null as File | null
            },
            addTeamPicture: {
                fileName: null as string | null,
                fileBlob: null as File | null
            }
        }
    },
    computed: {
        addTeamDialogVisible(): boolean {
            return store.state.addTeamDialogState.show;
        },
        team(): TeamInterface {
            return store.state.addTeamDialogState.team;
        },
        teamName(): string {
            return this.addTeam.name;
        },
        teamJoin(): number {
            return this.addTeam.join;
        },
        mainBtnText(): string {
            return this.editMode? 'Sauvegarder': 'Ajouter'
        },
        editMode(): boolean {
            return this.addTeam._id !== '';
        },
        teamDivisionNotEmpty(): boolean {
            return this.addTeam.division !== undefined && this.addTeam.division !== '';
        },
        addOrEditLoading(): boolean {
            return (
                (!this.editMode && store.state.addTeamRequestStatus.status === STATE.LOADING)
                || (this.editMode && store.state.editTeamRequestStatus[this.addTeam._id]?.status === STATE.LOADING)
            );
        },
        requestErr(): boolean {
            return (
                (!this.editMode && store.state.addTeamRequestStatus.status === STATE.ERR)
                || (this.editMode && store.state.editTeamRequestStatus[this.addTeam._id]?.status === STATE.ERR)
            );
        },
        teamPicture(): string {
            if(this.addTeamPicture.fileName) return this.addTeamPicture.fileName;
            else if(this.addTeam.picture) return this.addTeam.picture.slice(this.addTeam.picture.lastIndexOf('/') + 1);
            else return 'No team picture';
        },
        supportedLogoFileExt(): string[] {
            return ['jpg', 'png', 'svg']
        },
        supportedPictureFileExt(): string[] {
            return ['jpg', 'png', 'svg']
        },
        hasTeamPicture(): boolean {
            return this.addTeam.picture !== undefined
        },
        teamPictureUrl(): string {
            return this.addTeam.picture ?? '';
        },
        teamColorStyle(): CSS.Properties {
            return {
                'background-color': this.addTeam.color
            } as CSS.Properties;
        },
        colorPickerVisible(): boolean {
            return store.state.colorPickerVisible;
        },
        hasDivisions(): boolean {
            return store.getters.sortedSeasonCategories.length > 0
        },
        joinYearVisible(): boolean {
            return store.getters.joinYearVisible;
        }
    },
    methods: {
        closeDialog() {
            store.commit(MUTATION.SHOW_ADD_TEAM_DIALOG, {
                show: false,
                team: emptyTeamDef()
            });
        },
        addOrEdit() {
            if (!this.teamDivisionNotEmpty) return;

            const newTeam = {...this.addTeam};
            if(newTeam.pool === NO_POOLS) {
                newTeam.pool = '';
            }

            if (this.editMode) {
                store.dispatch(ACTION.EDIT_TEAM, {
                    team: newTeam,
                    images: {
                        logo: this.addTeamLogo.fileBlob,
                        picture: this.addTeamPicture.fileBlob
                    }
                });
            }
            else {
                store.dispatch(ACTION.ADD_TEAM, {
                    team: newTeam,
                    images: {
                        logo: this.addTeamLogo.fileBlob,
                        picture: this.addTeamPicture.fileBlob
                    }
                });
            }
        },
        setTeamLogo(payload: {fileBlob: File, fileName: string}) {
            if(!payload.fileBlob) {
                this.addTeam.logo = undefined;
                this.addTeamLogo = payload;
                return;
            }
            this.addTeam.logo = window.URL.createObjectURL(payload.fileBlob)
            this.addTeamLogo = payload;
        },
        setTeamPicture(payload: {fileBlob: File, fileName: string}) {
            if(!payload.fileBlob) {
                this.addTeam.picture = undefined;
                this.addTeamPicture = payload;
                return;
            }
            this.addTeam.picture = window.URL.createObjectURL(payload.fileBlob)
            this.addTeamPicture = payload;
        },
        showColorPicker(show: boolean) {
            store.commit(MUTATION.SHOW_COLOR_PICKER, show);
        },
        showTeamPoolDropdown() {
            const input = this.$refs['teampool-container'] as HTMLElement;
            store.commit(MUTATION.SHOW_POPUP_ONLY, {
                id: POPUPS.TEAM_POOL_DROPDOWN,
                division: this.addTeam.division,
                location: input,
                direction: POPUP_DIRECTION.DOWN
            });
        },
        showTeamDivisionDropdown() {
            const input = this.$refs['teamdivision-container'] as HTMLElement;
            store.commit(MUTATION.SHOW_POPUP_ONLY, {
                id: POPUPS.TEAM_DIVISION_DROPDOWN,
                location: input,
                direction: POPUP_DIRECTION.DOWN
            });
        },
        updateTeamColor(color: string) {
            this.addTeam.color = color;
        },
        closeSelfCreatedPopup() {
            store.commit(MUTATION.CLEAR_POPUPS, undefined);
            store.commit(MUTATION.SHOW_COLOR_PICKER, false);
        }
    },
    mounted() {
        this.addTeam = {...this.team};
    },
    watch: {
        addTeamDialogVisible(visible) {
            if(visible) {
                this.$nextTick(() => {
                    (this.$refs as unknown as {'dialog': HTMLElement}).dialog?.focus();
                });
            }
        },
        team(newTeam: TeamInterface, oldTeam: TeamInterface) {
            for(const key in oldTeam) {
                const prop = key as keyof TeamInterface;
                if(oldTeam[prop] !== newTeam[prop]) {
                    //@ts-ignore
                    this.addTeam[prop] = newTeam[prop];
                }
            }

                // When the team division changes, ensure, the pool is valid
                // else reset it to the first valid pool.

            if(store.getters.poolsInDivision(this.addTeam.division).indexOf(this.addTeam.pool) === -1) {
                this.addTeam.pool = store.getters.poolsInDivision(this.addTeam.division)[0];
            }
        }
    }
});
