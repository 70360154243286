
import { defineComponent } from 'vue'
import BaseFilter from '@/components/BaseFilter.vue';
import Checkbox from '@/components/Checkbox.vue';
import { CHECKBOX_TYPE } from '@/constants';

export default defineComponent ({
    name: 'CheckboxFilter',
    components: {
        BaseFilter, Checkbox
    },
    props: {
        categories: {
            type: Array as () => string[],
            required: true
        },
        selValues: {
            type: Array as () => string[],
            required: true
        },
        title: {
            type: String,
            required: false,
            default: ''
        },
        help: {
            type: String,
            required: false,
            default: ''
        },
        type: {
            type: Number as () => CHECKBOX_TYPE,
            required: false,
            default: CHECKBOX_TYPE.CHECKBOX
        },
        invertible: {
            type: Boolean,
            required: false,
            default: true
        },
        collapsed: {
            type: Boolean,
            required: false,
            default: false
        },
        collapsible: {
            type: Boolean,
            required: false,
            default: true
        },
    },
    computed: {
        isFiltering(): boolean {
            return this.procSelValues.length < this.categories.length;
        },
        procSelValues(): string[] {
            return !this.selValues.length? this.categories: this.selValues;
        }
    },
    methods: {
        toggle(idx: number, isToggled: boolean){
            let newSelValues = this.selValues.slice();
            const toggledValue = this.categories[idx];
        
            switch(this.type) {
                case CHECKBOX_TYPE.RADIO: {
                    if(newSelValues.indexOf(toggledValue) > -1) newSelValues = [];
                    else newSelValues = [toggledValue];
                    break;
                }
                case CHECKBOX_TYPE.CHECKBOX:
                default: {
                    if(!this.selValues.length) isToggled = true;
                    const toggledValueIdx = newSelValues.indexOf(toggledValue);

                        // Edit the selected list based on isToggled

                    if(isToggled && toggledValueIdx === -1){
                        newSelValues.push(toggledValue);
                    } else if(!isToggled && toggledValueIdx !== -1){
                        newSelValues.splice(toggledValueIdx, 1);
                    }
                }
            }

            this.$emit('updated', newSelValues);
        },
        isChecked(val: string): boolean {
            return this.procSelValues.indexOf(val) >= 0;
        },
        resetFilter() {
            this.$emit('updated', []);
        },
        invertSelection() {
            const newSelValues = this.categories.filter(d => {
                return (this.selValues).indexOf(d) === -1;
            });
            this.$emit('updated', newSelValues);
        }
    }
})
