
import { defineComponent } from 'vue';
import Dialog from '@/components/lib/Dialog.vue';
import { store } from '@/store/store';
import { MUTATION } from '@/store/mutations';
import { TNotification } from '@/store/state';
import { ACTION } from '@/store/actions';

export default defineComponent({
    name: 'TheNotificationCenter',
    components: { Dialog },
    computed: {
        notificationsVisible(): boolean {
            return store.state.notificationsVisible;
        },
        notifications(): TNotification[] {
            return store.state.sessionInfo.notifications;
        }
    },
    methods: {
        closeDialog() {
            store.commit(MUTATION.SHOW_NOTIFICATIONS, false);
        },
        notificationVisited(id: string) {
            store.dispatch(ACTION.DELETE_NOTIFICATION, id);
        }
    }
});
