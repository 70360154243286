import axios from 'axios';
import { REQUEST } from './documentation';
import { ldpCredentialsRequest } from './serverEngine';

export type FreeAgentInterface = {
    _id: string
    name: string,
    gender: string,
    knownteams: string[],
    cell?: string,
    email?: string,
    info: string
}


export type AddFreeAgentInput = Omit <FreeAgentInterface, '_id'>;
export type TFreeAgentOutput = {
    success: boolean;
    data: FreeAgentInterface[];
}

export type TRequestAddFreeAgentInput = {
    name: string,
    gender: string,
    cell?: string,
    email?: string,
    info: string
}

export type TRequestAddFreeAgentOutput = {
    success: boolean,
    data: TRequestAddFreeAgentInput,
}

export async function fetchFreeAgents(): Promise<FreeAgentInterface[]> {
    const resp = await axios.get(`/api/freeagents`);
    const freeAgents = resp.data.players as FreeAgentInterface[];

    freeAgents.forEach(a => a.knownteams = a.knownteams ?? []); // ensure array
    return freeAgents;
}


export async function addFreeAgent(req: AddFreeAgentInput): Promise<TFreeAgentOutput> {
    return (await ldpCredentialsRequest(`/api/freeagents/add`, REQUEST.PUT, req)) as TFreeAgentOutput;
}

export async function removeFreeAgent(playerID: string): Promise<TFreeAgentOutput> {
    return (await ldpCredentialsRequest(`/api/freeagents/remove/${playerID}`, REQUEST.DELETE)) as TFreeAgentOutput;
}

export async function requestAddFreeAgent(req: TRequestAddFreeAgentInput) {
    const resp = await axios.post(`/api/freeagents/requestadd`, req);
    return resp.data as TRequestAddFreeAgentOutput;
}

export default {};
