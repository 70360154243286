import { MatchInterface } from "./services/matches";
import { ScheduleInterface, ScheduleInterfaceWithDate } from "./services/series";
import { SponsorInterface, SPONSOR_TYPES } from "./services/sponsors";
import { TeamInterface } from "./services/teams";
import { TimeslotInterface } from "./services/timeslots";
import { randomTeamName, defaultTeamLogoURL, defaultPartnerLogoURL } from "./utils.names";

export interface CustomWindow extends Window{
    updateGoogleScriptState?: () => void
    gm_authFailure?: () => void
    // eslint-disable-next-line
    google?: any
}

export const DAYS: {[k: number]: string} = {
    0: 'Dimanche',
    1: 'Lundi',
    2: 'Mardi',
    3: 'Mercredi',
    4: 'Jeudi',
    5: 'Vendredi',
    6: 'Samedi'
}

export const MONTHS: {[k: number]: string} = {
    0: 'Jan.',
    1: 'Fév.',
    2: 'Mars',
    3: 'Avril',
    4: 'Mai',
    5: 'Juin',
    6: 'Juillet',
    7: 'Aout',
    8: 'Sept.',
    9: 'Oct.',
    10: 'Nov.',
    11: 'Déc.'
}

export function todayStart(): Date {
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    return date;
}
export function todayEnd(): Date {
    const date = new Date();
    date.setHours(23, 59, 59, 999);
    return date;
}
export function thisWeekStart(): Date {
    const date = todayStart();
    date.setDate(date.getDate() - date.getDay());
    return date;
}
export function thisWeekEnd(): Date {
    const date = todayEnd();
    date.setDate(date.getDate() + (7 - date.getDay()) - 1);
    return date;
}
export function nextWeekStart(): Date {
    const date = todayStart();
    date.setDate(date.getDate() + (7 - date.getDay()));
    return date;
}
export function nextWeekEnd(): Date {
    const date = nextWeekStart();
    date.setDate(date.getDate() + 6);
    return date;
}
export function thisMonthStart(): Date {
    const date = todayStart();
    date.setDate(0);
    return date;
}
export function thisMonthEnd(): Date {
    const date = new Date();
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    lastDay.setHours(23, 59, 59, 999);
    return lastDay;
}
export function thisYearStart(): Date {
    const date = new Date();
    date.setMonth(0);
    date.setDate(1);
    date.setHours(0, 0, 0, 0);
    return date;
}
export function thisYearEnd(): Date {
    const date = new Date();
    date.setMonth(11);
    date.setDate(10);
    date.setHours(23, 59, 59, 999);
    return date;
}

const enum DATE_FILTERS_ENUM {
    TODAY = 'Aujourd\'hui',
    CUSTOM = 'Choisir les dates'
}

export const DATE_FILTERS = [{
    displayName: DATE_FILTERS_ENUM.TODAY,
    dateMin: todayStart(),
    dateMax: todayEnd()
}, {
    displayName: DATE_FILTERS_ENUM.CUSTOM,
    dateMin: thisYearStart(),
    dateMax: thisYearEnd()
}];

export function noTimezoneDate(date: Date): Date {
    return new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000)
}

export function timezoneDate(date: Date): Date {
    return new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000)
}


export function formatDate(dateStr: string) {
    const d = noTimezoneDate(new Date(dateStr));
    const year = d.getFullYear();
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
}

export function formatDateNoYear(dateStr: string) {
    const d = noTimezoneDate(new Date(dateStr));
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [month, day].join('-');
}

export function formatDateDayOfWeek(dateStr: string) {
    const d = noTimezoneDate(new Date(dateStr));
    const month = d.getMonth() ;
    const day = d.getDay();
    const _d = d.getDate();

    return `${DAYS[day]} ${(_d).toString()} ${MONTHS[month].toLowerCase()}`;
}
export function formatTime(dateStr: string) {
    const d = noTimezoneDate(new Date(dateStr));
    const hours = d.getHours();
    const mins = d.getMinutes() === 0 ? '00' : d.getMinutes();

    return [hours, mins].join(':');
}

export function availableTime(interval: number) {
    const x = interval; //minutes interval
    const times = []; // time array
    let startTime = 0;

    //loop to increment the time and push results in array
    for (let i = 0; startTime < 24 * 60; i++) {
        const hh = Math.floor(startTime / 60); // 0-24 format
        times[i] =
            `${(hh % 24).toString().slice(-2)}:`+
            `${("0" + (startTime % 60)).slice(-2)}:00`;
        startTime = startTime + x;
    }

    return times;
}

export function toTitleCase (str: string) {
    return str.charAt(0).toUpperCase() + str.substr(1).toLowerCase();
}

export function toCamelCase (str: string) {
    return str.replace(/\w\S*/g, function(txt: string){
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
}

/**
 * Calculate the intersection A /\ B (set of elements present in all input
 * sets).
 * NOTE: preserves the order of the elements in the first smallest set.
 * NOTE: nested array in JavaScript can freeze browser.
 * @param {Array<*>} sets - array of sets to intersect (arrays or objects)
 * @param {Boolean} heavy - true to make it work with objects (stringifies)
 * @returns {Array<*>} intersection of the two sets
 */
/*eslint-disable */
export function setIntersection(sets: any[], heavy = false) {
    // Return empty intersection on invalid input.

    if (!Array.isArray(sets) || sets.some(s => !Array.isArray(s))) {
        return [];
    }

    // Return the unique values when passed a single one.

    if (sets.length === 1) return [...new Set(sets[0])];

    // Sort sets in increased order of length;

    const lOrdSets = sets.sort((a, b) => a.length - b.length);

    // Extract first as base (shortest, contains at least all common).

    const lBase = lOrdSets.splice(0, 1)[0];

    // Flagging functions and other helpers.

    let lVals: { [k: string]: [number, number] } = {};

    const add = heavy
        ? (v: any, i: number) => (lVals[JSON.stringify(v)] = [i, 0])
        : (v: any, i: number) => (lVals[v] = [i, 0]);

    const lHeavyCheck = (v: any) => {
        v = JSON.stringify(v);
        if (v in lVals) lVals[v][1] += 1;
    };
    const lLightCheck = (v: any) => {
        if (v in lVals) lVals[v][1] += 1;
    };
    const check = heavy ? lHeavyCheck : lLightCheck;

    const clean = () => {
        lVals = Object.keys(lVals).reduce((o, v) => {
            if (lVals[v][1]) {
                o[v] = lVals[v];
                o[v][1] = 0;
            }
            return o;
        }, {} as { [k: string]: [number, number] });
    };

    // Start by adding all values of base set.

    lBase.forEach(add);

    // For each remaining set, in order, check common.

    lOrdSets.forEach(s => {
        s.forEach(check);
        clean();
    });

    // Extract original values from base (mitigate stringification).

    let lRes = Object.keys(lVals).map(k => lBase[lVals[k][0]]);

    // Convert back to numbers if appropriate (TODO make this optional?)

    if (
        !heavy &&
        lBase.every((v: any) => v == null || !Number.isNaN(Number(v)))
    ) {
        lRes = lRes.map(v => (v != null ? parseFloat(v) : v));
    }

    return lRes;
}
/*eslint-enable */

export function filterScheduleByDates(
    schedule: MatchInterface[],
    dateStart: Date,
    dateEnd: Date
): MatchInterface[] {

    const dateMin = dateStart.getTime();
    const dateMax = dateEnd.getTime();
    return schedule.filter(m => {
        const matchDate = new Date(m.date).getTime();
        return ( dateMin <= matchDate && dateMax >= matchDate );
    });
}

export function splitScheduleByDate(schedule: ScheduleInterface[]): {[day: string]: ScheduleInterfaceWithDate[]} {
    const split:{[dateStr: string]: ScheduleInterfaceWithDate[]} = {};
    schedule.forEach(m => {
        if(m.date === undefined) return;
        const d = m as ScheduleInterfaceWithDate;
        if(split[formatDate(d.date)]) split[formatDate(d.date)].push(d);
        else split[formatDate(d.date)] = [d];
    })
    return split;
}

export function splitScheduleByWeek(schedule: ScheduleInterface[]): ScheduleInterface[][] {
    const blocks = {} as {[key: number]: ScheduleInterface[]};

    const noDates = [] as ScheduleInterface[];
    schedule.forEach(m => {

            // Create a date and resets its date to closest Sunday to
            // delimitate begining of the week

        const mDate = m.date;
        if(!mDate) {
            noDates.push(m);
            return
        }
        const date = noTimezoneDate(new Date(mDate));
        date.setHours(0, 0, 0, 0);
        const mondaysDiff = (date.getDay() === 0? 7: date.getDay()) + 1;
        date.setDate(date.getDate() - mondaysDiff);

        if(blocks[date.getTime()]) blocks[date.getTime()].push(m);
        else blocks[date.getTime()] = [m];
    });

    const sortedKeys = Object.keys(blocks).sort((time1, time2) => {
        if (parseInt(time1) > parseInt(time2)) return 1;
        else if (parseInt(time1) < parseInt(time2)) return -1;
        else return 0;
    }).map(k => parseInt(k));

    if(noDates.length) {
        const newWeek = sortedKeys[sortedKeys.length - 1] + (7*24*60*60*1000);
        sortedKeys.push(newWeek);
        blocks[newWeek] = noDates;
    }

    return sortedKeys.map(k => {
        return blocks[k].sort((match1, match2) => {
            if(!match1.date || !match2.date) return 0;

            const d1 = new Date(match1.date).getTime();
            const d2 = new Date(match2.date).getTime();
            if (d1 > d2) return 1;
            else if (d1 <d2) return -1;
            else return 0;
        });
    })
}

export function isColorLight(hexColor: string) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexColor);
    const {r, g, b} = result?
        {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
        }:
        {r: 0, g: 0, b: 0};

    // Equation @ http://alienryderflex.com/hsp.html
    const hsp = Math.sqrt(
        0.299 * (r * r) +
        0.587 * (g * g) +
        0.114 * (b * b)
    );

    // Using the HSP value, determine whether the color is light or dark
    return hsp > 145? true: false;
}

export function validateEmail(email: string): boolean {

    if(!email || email === '') return false;
    const emailParts = email.split('@');
    const account = emailParts[0];
    const address = emailParts[1];

    if(emailParts.length !== 2) return false
    if(account.length > 64) return false
    else if(address.length > 255) return false

    const domainParts = address.split('.');
    if (domainParts.some(function (part) {
        return part.length > 63;
    })) return false;

    const emailRegExp = /^[-!#$%&'*+0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;

    return (emailRegExp.test(email))? true: false;

}

export function divisionLongDisplayName(division: string, pool: string) {
    return  `${division}${(pool === "" || !pool)? "": ' - Section ' + pool}`;
}

const encodingChar = "|";
export function encodeSeriesTeam(team: {division: string, pool: string, rank: number}) {
    return [team.division, team.pool, team.rank].join(encodingChar);
}

export function decodeSeriesTeam(team: string) {
    const [division, pool, rank] = team.split(encodingChar);
    return {division, pool, rank: parseInt(rank)};
}

export function encodeTeamDivision(team: {division: string, pool: string}) {
    return [team.division, team.pool].join(encodingChar);
}

export function decodeTeamDivision(team: string) {
    const [division, pool] = team.split(encodingChar);
    return {division, pool};
}

export function emptyTeamDef(): TeamInterface {
    return {
        _id: '',
        join: 0,
        name: '',
        logo: undefined,
        picture: undefined,
        division: '',
        pool: '',
        color: ''
    };
}

export function emptySponsorDef(): SponsorInterface {
    return {
        _id: '',
        sponsorType: '',
        websiteUrl: '',
        description: '',
        affiliateTeams: [],
        telephone: '',
        contactEmail: '',
        name: '',
        logo: undefined,
        picture: undefined,
        color: ''
    }
}

export function defaultSponsorDef() {
   return {
        _id: '',
        sponsorType: SPONSOR_TYPES.GOLD,
        websiteUrl: '',
        description: '',
        affiliateTeams: [],
        telephone: '',
        contactEmail: '',
        name: 'Commanditaire',
        logo: defaultPartnerLogoURL(),
        picture: undefined,
        color: '#5D3512'
    };
}

export function defaultTeamDef(): TeamInterface {

    return {
        _id: '',
        join: 0,
        name: randomTeamName(),
        logo: defaultTeamLogoURL(),
        picture: undefined,
        division: '',
        pool: '',
        color: ''
    };
}

export function emptyMatchDef(): MatchInterface {
    return {
        _id: '',
        homeTeam: '',
        awayTeam: '',
        date: (new Date()).toISOString(),
        field: ''
    };
}

export function emptyTimeslotDef(): TimeslotInterface {
    return {
        _id: '',
        date: (new Date()).toISOString(),
        field: ''
    };
}

export function cleanDomFromGoogleMaps() {
    delete (window as unknown as CustomWindow).updateGoogleScriptState;
    delete (window as unknown as CustomWindow).google;

    document.querySelectorAll("script").forEach((script) => {
        if (script.src.includes("googleapis")) script.remove()
    });
}