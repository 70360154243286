
import { defineComponent } from 'vue';
import CheckboxFilter from './CheckboxFilter.vue';
import { store } from '@/store/store';
import { MUTATION } from '@/store/mutations';
import { TeamInterface } from '@/services/teams';

export default defineComponent({
    name: 'TheTeamFilter',
    components: {
        CheckboxFilter
    },
    props: {
        collapsed: {
            type: Boolean,
            required: false,
            default: false
        },
        filterSetName: {
            type: String,
            required: true
        }
    },
    computed: {
        hasFilterApplied(): boolean {
            return store.getters.hasScheduleFilters(this.filterSetName);
        },
        sortedTeams(): string[] {
            return store.getters.sortedTeams;
        },
        filterValues(): string[] {
            return store.getters.filterSet(this.filterSetName).teamNamesFilter;
        }
    },
    methods: {
        clearFilter() {
            store.commit(MUTATION.SET_TEAM_NAMES_FILTERS, {filterSet: this.filterSetName, teamNames: []});
            store.commit(MUTATION.SET_SELECTED_MATCH_IDX, -1);
        },
        updateFilter(teams: string[]) {
            store.commit(MUTATION.SET_TEAM_NAMES_FILTERS, {filterSet: this.filterSetName, teamNames: teams});
            store.commit(MUTATION.SET_SELECTED_MATCH_IDX, -1);
        },
        teamInfo(teamName: string): TeamInterface {
            return store.getters.teamInfo(teamName);
        },
    }
});
