
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'BaseFilter',
    props: {
        name: {
            type: String,
            default: '',
            required: false
        },
        help: {
            type: String,
            default: '',
            required: false
        },
        filtering: {
            type: Boolean,
            default: false,
            required: false
        },
        collapsed: {
            type: Boolean,
            default: false,
            required: false
        },
        collapsible: {
            type: Boolean,
            required: false,
            default: true
        },
        invertible: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    data () {
        return {
            isFilterCollapsed: false
        }
    },
    computed: {
        hasHelp(): boolean {
            return this.help !== '';
        },
        hasTitle(): boolean {
            return this.name !== '';
        }
    },
    methods: {
        invertSelection() {
            this.$emit('flt-selection-inverted');
        },
        toggleFilter() {
            this.isFilterCollapsed = !this.isFilterCollapsed;
            this.$emit('flt-collapse', this.isFilterCollapsed);
        }
    },
    mounted() {
        this.isFilterCollapsed = this.collapsed;
    },
    watch: {
        collapsed() {
            this.isFilterCollapsed = this.collapsed;
        }
    }
})
