import { noTimezoneDate } from '@/utils';
import axios from 'axios';
import { REQUEST } from './documentation';
import { ldpCredentialsRequest } from './serverEngine';

export type BackupListOutput = {
    backups: {
        name: string[],
        date: string[],
        deletable: boolean[]
    }
};

export type BackupCreateOutput = BackupListOutput & {
    success: boolean
}

export type BackupRemoveOutput = BackupListOutput & {
    success: boolean
}

export type BackupRestoreOutput = BackupListOutput & {
    success: boolean
}
export interface BackupInterface {
    name: string,
    date: Date,
    deletable: boolean
}

export async function fetchBackups(): Promise<BackupInterface[]> {
    const resp = (await axios.get(`/api/backups`)).data as BackupListOutput;
    return resp.backups.name.map((n, ix) => {
        return {
            name: n,
            date: noTimezoneDate(new Date(resp.backups.date[ix])),
            deletable: resp.backups.deletable[ix]
        }
    });
}

export async function createBackup(): Promise<BackupInterface[]> {
    const resp = (await ldpCredentialsRequest(`/api/backups/add`, REQUEST.POST)) as BackupCreateOutput;
    if(!resp.success) throw 'Could not create backup';

    const bkp = resp.backups.name.map((n, ix) => {
        return {
            name: n,
            date: noTimezoneDate(new Date(resp.backups.date[ix])),
            deletable: resp.backups.deletable[ix]
        }
    });
    return bkp;
}

export async function getBackup(name: string): Promise<void> {
    try {
        const response = await axios.get(`/api/backups/${name}`);
        const url = window.URL.createObjectURL(new Blob([response.data],
            { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download',
            response.headers["content-disposition"].split("filename=")[1]);
        document.body.appendChild(link);
        link.click();
        document.removeChild(link);
    } catch(err) {
        throw 'Cannot download backup';
    }
}

export async function removeBackup(name: string): Promise<BackupInterface[]> {
    const resp = (await ldpCredentialsRequest(`/api/backups/remove/${name}`, REQUEST.DELETE)) as BackupRemoveOutput;
    if(!resp.success) throw 'Could not delete backup';

    const bkp = resp.backups.name.map((n, ix) => {
        return {
            name: n,
            date: noTimezoneDate(new Date(resp.backups.date[ix])),
            deletable: resp.backups.deletable[ix]
        }
    });
    return bkp;
}

export async function restoreBackup(name: string): Promise<BackupInterface[]> {
    const resp = (await ldpCredentialsRequest(`/api/backups/restore/${name}`, REQUEST.POST)) as BackupRestoreOutput;
    if(!resp.success) throw 'Could not restore backup';
    const bkp = resp.backups.name.map((n, ix) => {
        return {
            name: n,
            date: noTimezoneDate(new Date(resp.backups.date[ix])),
            deletable: resp.backups.deletable[ix]
        }
    });
    return bkp;
}


export default {};